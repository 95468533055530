<template>
    <div>
        <Header></Header>
        <br/>
        <div class="mblog" v-loading="loading">
            <el-tooltip content="返回上一页" placement="bottom" effect="light">
                <div class="idea" @click="goBack()">
                    <div class="blue">
                    </div>
                    <div class="yellow">
                    </div>
                    <div class="red">
                    </div>
                </div>
            </el-tooltip>

            <div class="titleMsg">
                <!--<img :src="blog.image">-->
            </div>
            <h1 align="center">{{blog.title}}</h1>
            <div class="message">
                <div class="myAuthor <!--el-icon-user-solid-->" @click="detail()">
                    <!--头像信息-->
                    <el-avatar :size="40" :src="blog.image"></el-avatar>
                    <div class="userName">作者:{{blog.userName}}</div>
                </div>
                <div class="mytime el-icon-watch-1">
                    发布时间:{{blog.createdTime}}
                </div>
                <div class="myViews el-icon-view">
                    浏览量:{{blog.pageView}}
                </div>

                <div class="myButton">
                    <div class="editbutton">
                        <router-link :to="{name:'blogEdit', params:{blogId: blog.id}}" v-show="editorFilter">
                            <el-button class="el-icon-edit" size="mini" type="danger"></el-button>
                        </router-link>
                    </div>
                    <div class="editbutton">
                        <router-link :to="{name:'blogEdit'}" v-show="editorFilter">
                            &nbsp;<el-button class="el-icon-plus" size="mini" type="primary"></el-button>
                        </router-link>
                    </div>
                    <div class="editbutton">
                        <el-button type="success" size="mini" icon="el-icon-share" @click="share"></el-button>
                    </div>
                    <div class="editbutton">
                        <el-button type="warning" size="mini" icon="el-icon-printer" v-print="'#printTest'"
                                   @click="setTitle"></el-button>
                    </div>
                </div>
            </div>

            <el-divider></el-divider>
            <div></div>
            <!--<mavon-editor
                    style="width: 250px;height: 600px"
                    :navigation = "prop.navigation"
                    :subfield="prop.subfield  = false"
                    :defaultOpen="prop.defaultOpen"
                    :toolbarsFlag="prop.toolbarsFlag = false"
                    :editable="prop.editable"
                    :scrollStyle="prop.scrollStyle"
                    :value="blog.content"
            ></mavon-editor>-->
            <div class="detailInfo" @click="getImg($event)">
                <!--解析文本内容-->
                <!--<div class="markdown-body"  v-html="blog.content" id="printTest"></div>-->
<!--                <div v-html="blog.content" v-highlight id="vhtml"></div>-->
                <mavon-editor
                        class="markdown-body vhtml"
                        v-html="blog.content"
                        :subfield="prop.subfield"
                        :defaultOpen="prop.defaultOpen"
                        :toolbarsFlag="prop.toolbarsFlag"
                        :editable="prop.editable"
                        :scrollStyle="prop.scrollStyle"
                        v-highlight
                        id="printTest"
                ></mavon-editor>
                <!--:navigation = "prop.navigation"-->
                <div class="more" v-show="showMore">
                    <el-button @click="moreInfo()" align="center" type="success"><i class="el-icon-caret-bottom"></i>查看更多
                    </el-button>
                </div>
            </div>

            <div @click="goNext(item.id)" class="bottomInfo" v-for="(item, index) in hello" key="index" v-show="isExistShareId">
                <!--<a :href="'/blogDetail?shareId=' + item.id" >--><div class="bottomHello">{{index == 0?"上一篇：":"下一篇："}}{{item.title}}</div>
            </div>
        </div>
        <!--<el-button @click="getPdf()">下载</el-button>-->
        <!--<el-dialog title="分享" :visible.sync="dialogVisible" width="45%" :before-close="handleClose">
            <div class="share"> <            <div @click="goNext(item.id)" class="bottomInfo" v-for="(item, index) in hello" key = "index">
el-img :src="imrUrl" /></div>
            <el-button @click="dialogVisible = false">取 消</el-button>
        </el-dialog>-->
        <!--<el-button @click="getPdf()">下载</el-button>-->
        <el-dialog
                title="分享"
                :visible.sync="dialogVisible"
                width="350px"
        >
            <!--:before-close="handleClose"-->
            <div class="share"><img :src="imrUrl"/></div>
            <el-form>
                <el-form-item label="链接">
                    <el-input v-model="shareUrl" placeholder="可复制内容" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <!--<span slot="footer" class="dialog-footer">-->
            <div class="shareButton">
                <el-button type="warning" @click="dialogVisible = false">取 消</el-button>
                <el-button type="success" @click="copyLink()"><i class="el-icon-share"></i>复制链接</el-button>
            </div>
            <!--</span>-->
        </el-dialog>

        <div class="demo-image__preview" v-show="false">
            <el-image
                    style="width: 100px; height: 100px"
                    :src="url"
                    id="hello"
                    :preview-src-list="srcList">
            </el-image>
        </div>
        <!--评分-->
        <div class="grade">
            评分：
            <el-rate
                    v-model="value"
                    show-text>
            </el-rate>
        </div>

        <!--<Reply></Reply>-->
        <LeaveInfo></LeaveInfo>
      <Footer></Footer>
    </div>

</template>

<script>
    import {mapState} from "vuex";
    import Header from "../components/Header";
    import "github-markdown-css"
    import Footer from "../components/Footer";
    import MyLeave from "./MyLeave";
    import Reply from "../components/Reply";
    import PdfUtil from "../util/PdfUtil.js";
    import QRCode from 'qrcode';
    const plus = window.plus;
    import Highlight from "../util/highlight";
    import LeaveInfo from "../components/LeaveInfo"

    export default {
        inject: ['reload'], //注入
        dialogVisible: false,
        name: "BlogDetail",
        components: {Reply, MyLeave, Footer, Header, LeaveInfo},
        data() {
            return {
                hello: {},
                shareUrl: '',
                isExistShareId: true,
                dialogVisible: false,
                showClose: false,
                show: false,
                imrUrl: "",
                users: {},
                value: 4,
                editorFilter: false,
                loading: true,
                blog: {
                    tempInfo: '',
                    pageView: 1,
                    id: 0,
                    title: '',
                    content: '',
                    createdTime: '',
                    updatedUser: '',
                    type: false,
                },
                showMore: false,
                useId: null,
                allInfo: {},
                ownBlog: false,
                url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
                srcList: [
                    ""
                ]
            }
        },
        methods: {
            getImg(e) {
                // ImagePreview([item]);
                console.log(e.target.currentSrc)
                if (e.target.nodeName === "IMG") {
                    // ImagePreview([e.target.currentSrc]);
                    this.srcList[0] = e.target.currentSrc
                    document.getElementById("hello").click();
                }
            },
            /*设置打印标题*/
            setTitle() {
                localStorage.setItem("printlnTitle", this.blog.title)
            },
            copyLink() {
                this.copy(this.shareUrl)
            },
            /*复制内容到剪贴板*/
            copy(data) {
                let url = data
                let oInput = document.createElement('input')
                oInput.value = url
                document.body.appendChild(oInput)
                oInput.select() // 选择对象
                document.execCommand("Copy") // 执行浏览器复制命令
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
                oInput.remove()
            },
            /*分享二维码*/
            share() {
                const _this = this;
                this.show = true;
                let url = null;
                if (location.href.search("shareId") != -1) {
                    url = location.href ;//获取当前地址栏的地址
                } else {
                    url = location.href + '?shareId=' + localStorage.getItem("BLOGID");//获取当前地址栏的地址
                }

                this.shareUrl = url
                // console.log("hello"+url);
                QRCode.toDataURL(url)
                    .then(tpian => {
                        console.log(tpian);
                        _this.imrUrl = tpian;
                    })
                    .catch(err => {
                        console.error(err);
                    });
                _this.dialogVisible = true
            },
            /*handleClose(done) {
                this.$confirm('确认取消分享？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },*/
            javaBlogs(data) {
                let id = this.$route.query.shareId;
                if (id != null && id != '') {
                    this.useId = id;
                    localStorage.setItem("BLOGID", this.useId)
                } else {
                    this.useId = localStorage.getItem("BLOGID")
                }
                const _this = this
                this.$axios.post("/javaBlogs/query_content_user", {id: this.useId}).then(res => {
                    const blog = res.data.data[0]
                    _this.blog = blog
                    /*console.log(blog)*/
                    _this.blog.id = blog.id
                    _this.blog.title = blog.title
                    _this.blog.createdTime = blog.createdTime
                    _this.blog.updatedUser = blog.updatedUser
                    _this.blog.pageView = blog.pageView;

                    const MardownIt = require("markdown-it")
                    let md = new MardownIt()
                    let result = md.render(blog.content)
                    result = result.replaceAll("&lt;mark&gt;", "<mark>").replaceAll("&lt;/mark&gt;", "</mark>")
                        .replaceAll("&lt;==", "<strong style='padding: 0px 5px;background-image: linear-gradient(to right, rgba(206,67,99,0.89), #6346de);font-size: 18px;color: #ffffff;border-radius: 3px;'>").replaceAll("==&gt;", "</strong>")
                        .replaceAll("&lt;&lt;=", "<strong style='padding: 0px 2px;background-color: #e79c1e;font-size: 18px;color: rgba(255,255,255,0.93);border-radius: 3px;box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);'>").replaceAll("=&gt;&gt;", "</strong>")
                        .replaceAll("&lt;br&gt;", "<br>").replaceAll("&lt;hr&gt;", "<hr>")
                        .replaceAll("&lt;em&gt;", "<em>").replaceAll("&lt;em&gt;", "</em>")
                        .replaceAll("&lt;sub&gt;", "<sub>").replaceAll("&lt;em&gt;", "</sub>")
                        .replaceAll("&lt;sup&gt;", "<sup>").replaceAll("&lt;sup&gt;", "</sup>")
                        .replaceAll("&lt;s&gt;", "<s>").replaceAll("&lt;s&gt;", "</s>")
                        .replaceAll("<p>++", "<ins style='font-size: 20px;color: #e59a93;border-radius: 5px;'>").replaceAll("++</p>", "</ins>")
                        .replaceAll("&lt;aaa&gt;", "<div style='background-color: #f59e8f;font-size: 20px;color: #204250;border-radius: 5px;'>").replaceAll("&lt;/aaa&gt;", "</div>")
                        .replaceAll("&lt;++", "<ins>").replaceAll("++&gt;", "</ins>").replaceAll("++<br>", "</ins>")
                        /*.replaceAll("<img", "<img @click=\"imgDetao($event)\" ")*/
                    _this.loading = false
                    _this.blog.content = blog.content
                    _this.blog.content = result

                   /* console.log(result)*/

                    if (blog.content.length > 5000) {
                        _this.tempInfo = blog.content
                        _this.blog.content = blog.content.substring(0, 5000) + "..." + "<div style='background-color: #344682;color: #fde591;font-size: 18px;border-radius: 5px;'>内容过长，已隐藏部分内容......</div>";
                        _this.showMore = true

                    }

                    //_this.ownBlog = (blog.user_id == sessionStorage.getItem("id") )
                    // _this.blog.content = marked(blog.content||'',{
                    //     sanitize: true
                    // })

                })
            },
            moreInfo() {
                this.blog.content = this.tempInfo
                this.showMore = false
            },
            detail() {
                localStorage.setItem("leaveUserId", localStorage.getItem("CUURENTSERID"))
                this.$router.push({path: "/userInfo"})
            },
            goBack() {
                this.$router.back()
            },
            //获取当前用户信息
            user() {
                const _this = this;
                this.$axios.post("/userDetail/userInfoById", {id: localStorage.getItem("CUURENTSERID")}).then(function (res) {
                    _this.users = res.data.data
                    window.scrollTo(0, 0)
                })
            },
            getPdf() {
                PdfUtil.downloadPDF(document.querySelector('.markdown-body'), "Arien文章")
            },
            /*查询上一章和下一章*/
            queryNext() {
                const _this = this;
                this.$axios.post("/javaBlogs/getLastAndNext", {id: localStorage.getItem("BLOGID")}).then(function (res) {
                    _this.hello = res.data.data
                    window.scrollTo(0, 0)
                })
            },
            goNext(value) {
                localStorage.setItem("CUURENTSERID", value)
                localStorage.setItem("BLOGID", value)
                this.reload();
                this.queryNext()
                this.$router.push({path: "/blogDetail"})
            }
        },
        computed: {
            ...mapState({
                blobId: (state) => state.blogId,
            }),
            prop() {
                let data = {
                    subfield: false,// 单双栏模式
                    defaultOpen: 'preview',//edit： 默认展示编辑区域 ， preview： 默认展示预览区域
                    editable: false,
                    toolbarsFlag: false,
                    scrollStyle: true,
                    // 导航目录
                    navigation: true
                }
                return data
            }
        },
        mounted() {
            Highlight(Highlight)
        },
        created() {
            /*this.tete()*/
            /*this.currentInfo(localStorage.getItem("BLOGID"))*/
            // this.user()
            this.queryNext()
            if (localStorage.getItem("CUURENTSERID") == localStorage.getItem("id") || localStorage.getItem("roleId") == 1) {
                this.editorFilter = true;
            }
            let id = this.$route.query.shareId;
            if (id != null && id != '') {
                this.isExistShareId = false
            }

        },
        watch: {
            blobId: {
                immediate: true,
                handler(value) {
                    this.javaBlogs(value)
                    window.scrollTo(0, 0)
                }
            }
        }
    }
</script>

<style scoped>

    >>>.el-image-viewer__img {
        width: 150%;
        height: 150%;
    }

    .mblog {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        width: 60%;
        min-width: 860px;
        /*min-height: 800px;*/
        height: auto;
        padding: 15px 15px;
        margin: 100px auto;
        border-radius: 15px;
    }

    .idea {
        width: 120px;
        height: 30px;
    }
    /*上下*/
    .bottomInfo {
        width: 95%;
        height: auto;
        background-image: linear-gradient(to right, #4b95ec, #f380d6);
        margin: 5px auto;
        min-height: 36px;
        font-size: 20px;
        font-weight: bold;
        color: #e6fffa;
        line-height: 30px;
        padding: 8px;
        border-radius: 8px;
    }

    .bottomInfo:hover {
        background-image: linear-gradient(to right, #e094b0, #fff3a7);
    }

    a {
        color: #fffd90;
    }

    .blue {
        width: 30px;
        height: 30px;
        float: left;
        border-radius: 100px;
        background-color: #3eb4a6;
    }

    .yellow {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 10px;
        border-radius: 100px;
        background-color: #eeda5d;
    }

    .red {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        float: left;
        border-radius: 100px;
        background-color: #de505e;
    }

    /*.titleMsg {
        width: 100%;
        height: 200px;
    }*/


    .message {
        width: 100%;
        height: 50px;
    }

    .mblog > h1 {
        color: #3d9393;
    }

    .mytime {
        color: #ffffff;
        /*background-color: #64ee9b;*/
        border-radius: 5px;
        padding: 4px 8px;
        line-height: 18px;
        font-weight: bold;
        font-family: 华光综艺_CNKI;
        width: 280px;
        height: 20px;
        float: left;
        text-align: center;
        margin: 18px auto;
        /*border-radius: 8px;*/
        background-image: linear-gradient(to right, #3db8e8, #592ef3);
    }

    .myAuthor {
        width: auto;
        height: 20px;
        float: left;
        text-align: center;
        margin-top: 10px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 8px;
        font-weight: normal;
        line-height: 24px;
        float: left;
    }

    .myViews {
        color: #ffffff;
        background-image: linear-gradient(to right, #e8d13d, #39ba86);
        border-radius: 5px;
        padding: 5px 8px;
        line-height: 18px;
        float: left;
        margin: 19px 8px;
        font-weight: bold;
    }

    .userName {
        float: right;
        margin: 8px 2px;
        font-weight: bold;
        font-family: 华光综艺_CNKI;
        border-radius: 5px;
        padding: 2px 8px;
        color: #ffffff;
        /*background-color: #eedd64;*/
        background-image: linear-gradient(to right, #e8783b, #ef2a2a);
    }

    .editbutton {
        margin-top: 15px;
        margin-left: 10px;
        float: right;
    }

    .shareButton {
        width: auto;
        height: 20px;
        margin-left: 57px;
    }

    .detailInfo {
        margin: 60px auto;
        width: 100%;
        height: auto;
        border-radius: 15px;
    }

    >>>pre {
        position: relative;
        margin-bottom: 24px;
        background: #FFF;
        overflow: hidden;
        font-size: 16px;
        color: #ff8de1;
    }


    >>> .markdown-body {
        box-sizing: border-box;
        min-width: 200px;
        width: 100%;
        max-width: 1000px;
        margin: 0 auto;
        padding: 5px;
        /*首行缩进两个字符*/
        /*text-indent: 2em;*/
        z-index: auto;
        /*font-family: 华文仿宋;*/
        font-weight: bold;
        box-shadow: none !important;
        font-size: 17px;
        /*color: rgba(0, 0, 0, 0.72);*/
    }

    >>> .markdown-body h2 {
        padding-bottom: .3em;
        font-size: 1.5em;
        border-bottom: 1px solid #eaecef;
        color: #32857d;
    }

    .markdown-body h3 {
        font-size: 1.25em;
        color: #4c1786;
    }

    >>> .markdown-body p {
        margin-top: 0;
        margin-bottom: 10px;
        color: #2e6479;
    }


    >>> .markdown-body h3 {
        font-size: 1.25em;
        color: #619c4e;
    }

    >>> .hljs-attribute {
        color: #66c1c1;
        font-weight: bold;
    }

    >>>.hljs-symbol, .hljs-bullet {
        color: #ff4b8a;
    }


    /*代码块*/
    >>> .hljs {
        display: block;
        overflow-x: auto;
        padding: 1px 0px 0px 15px;
        color: #bd74ee;
        background: #27291e;
        border: 0px solid #27291e;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        font-size: 14px;
        line-height: 19px;
        font-family: 方正综艺_GBK;
    }

    》》》.markdown-body pre {
        padding: 6px;
        overflow: auto;
        font-size: 35%;
        line-height: 1.45;
        background-color: #212528;
        border-radius: 5px;
    }

    >>>.pre-numbering li {
        list-style: none;
        /*font-family: Menlo, monospace;*/
        color: #f3cc95;
        font-weight: bold;
    }

    >>>.markdown-body li+li {
        margin-top: .145em;
    }


    element.style {
        box-shadow: none;
    }

    >>>  .highlight pre, .markdown-body pre {
        padding: 6px;
        overflow: hidden !important;
        font-size: 8%;
        height: auto;
        line-height: 1.45;
        background-color: #f6f8fa;
        border-radius: 5px;
        font-family: 方正综艺_GBK;
        font-size: 15px;
        font-weight: bold;
        line-height: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.9) !important;
        color: #ff8de1;
    }

    >>>.pre-numbering {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        padding: 10px 2px 10px 0;
        border-right: 2px solid #f1f579;
        background-color: #27291e;
        text-align: right;
        font-size: 14px;
        line-height: 17px;
        border-radius: 5px;
    }

    >>>.hljs-attr {
        color: #e55e4c;
    }

    >>> .markdown-body h1 {
        padding-bottom: .3em;
        font-size: 2em;
        border-bottom: 1px solid #b264d2;
        color: #571887;
    }

    >>> .style {
        background-color: rgba(255, 246, 246, 0);
    }

    >>> .markdown-body pre {
        padding: 9px;
        overflow: hidden !important;
        font-size: 1%;
        line-height: 1.35;
        background-color: #27291e !important;
        border-radius: 5px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.9) !important;
        color: #ff8de1;
    }

    >>> .hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr {
        color: #70b723;
    }


    >>> .hljs-string, .hljs-doctag {
        color: #78cebc;
    }

    >>> .lang- {
        color: #45c096;
        font-weight: bold;
        font-size: 14px;
    }


    >>> .hljs-keyword, .hljs-selector-tag, .hljs-subst {
        color: #95e54c;
        font-weight: bold;
    }

    >>> .hljs-comment, .hljs-quote {
        color: #ffa76d;
        font-style: italic;
    }

    >>> .hljs-title, .hljs-section, .hljs-selector-id {
        color: #7a82e9;
        font-weight: bold;
    }

    >>>.hljs-type {
        color: #f3f186;
        font-weight: bold;
    }

    >>>.hljs-class {
        color: #f3f186;
        font-weight: bold;
    }


    >>>.hljs-title {
        color: #5c80c7;
        font-weight: bold;
    }

    /*String*/
    >>> .hljs-string, .hljs-doctag {
        color: #11ddc1;
    }

    >>> .markdown-body img {
        useCORS: true;
        pointer-events: fill;
        background-size: 100%;
        background-repeat: no-repeat;
        display: block;
        margin: 10px auto;
        /*width: auto;*/
        width: auto;
        max-width: 100%;
        /*padding: 0 10%;*/
        object-fit: cover;
        max-height: 550px;
        border-radius: 6px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    >>> .v-note-wrapper .v-note-panel .v-note-show .v-show-content, .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
        width: 100%;
        height: 100%;
        padding: 8px 2px 15px 2px;
        overflow-y: auto;
        box-sizing: border-box;
        overflow-x: hidden;
        z-index: 900;
    }

    .share {
        width: 300px;
        height: 300px;
        margin: 0 auto;
    }

    .share > img {
        width: 100%;
        height: 100%;
    }

    img {
        pointer-events: fill;
    }

    >>>.el-menu-item [class^=el-icon-] {
        margin-right: 5px;
        width: 25px;
        text-align: center;
        font-size: 25px;
        vertical-align: middle;
    }


    @media screen and (max-width: 767px) {
        .markdown-body {
            /* text-indent: 2em;*/
            padding: 15px;
        }
    }

    a {
        text-decoration: none;
    }

    .router-link-active {
        text-decoration: none;
    }

    .grade {
        width: 300px;
        margin: 30px auto;
    }


    .more {
        width: 90px;
        margin: 10px auto;
    }

    @media screen and (max-width: 867px) {

        .mblog {
            min-width: 96%;
        }

        .message {
            height: auto;
        }

        >>>.pre-numbering {
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            padding: 13px 2px 10px 0;
            border-right: 2px solid #f1f579;
            background-color: #27291e;
            text-align: right;
            font-size: 14px;
            line-height: 16px;
            border-radius: 5px;
        }

        /*代码块*/
        >>> .hljs {
            display: block;
            overflow-x: auto;
            padding: 1px 0px 0px 15px;
            color: #bd74ee;
            background: #27291e;
            border: 0px solid #27291e;
            border-radius: 8px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            font-size: 13px;
            line-height: 18px;
            font-family: 方正综艺_GBK;
        }

        >>>.hljs-type, .hljs-class .hljs-title {
            color: #64f2fa;
            font-weight: bold;
        }


        /*>>>  .highlight pre, .markdown-body pre {
            padding: 6px;
            overflow: auto;
            font-size: 8%;
            height: auto;
            line-height: 1.45;
            background-color: #f6f8fa;
            border-radius: 5px;
            font-family: 方正综艺_GBK;
            font-size: 16px;
            font-weight: bold;
        }*/

        .mblog {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            width: 96%;
            /*min-height: 800px;*/
            height: auto;
            padding: 15px 1px;
            margin: 100px auto;
            border-radius: 15px;

        }

        .idea {
            width: 120px;
            height: 30px;
        }

        .blue {
            width: 20px;
            height: 20px;
            float: left;
            border-radius: 100px;
            background-color: #3eb4a6;
        }

        .yellow {
            width: 20px;
            height: 20px;
            float: left;
            margin-left: 10px;
            border-radius: 100px;
            background-color: #eeda5d;
        }

        .red {
            width: 20px;
            height: 20px;
            margin-left: 10px;
            float: left;
            border-radius: 100px;
            background-color: #de505e;
        }

        >>> .markdown-body img {
            background-size: 100%;
            background-repeat: no-repeat;
            display: block;
            margin: 10px auto;
            width: auto;
            width: 100%;
            /*padding: 0 10%;*/
            max-height: 600px;
            border-radius: 6px;
            /*border: 2px solid #fff1bc;*/
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }

        .detailInfo {
            margin: 150px auto;
            width: 100%;
            height: auto;
            border-radius: 15px;
        }


        .mytime {
            /*background: #f7f470;*/
            width: auto;
            height: 20px;
            /*float: left;*/
            padding: 3px 3px;
            text-align: left;
            margin-top: 19px;
            margin-bottom: 3px;
            border-radius: 5px;
            font-weight: bold;
            line-height: 19px;
        }

        .myAuthor {
            width: auto;
            height: 20px;
            float: left;
            text-align: center;
            margin-top: 10px;
            margin-left: 0px;
            margin-right: 10px;
            border-radius: 8px;
            font-weight: normal;
            line-height: 25px;
            float: left;
        }

        .myViews {
            float: left;
            margin: 19px 5px 0px;
            line-height: 15px;
            text-align: left;
        }

        .userName {
            float: right;
            margin: 8px auto;
            font-weight: bold;
            font-family: 华光综艺_CNKI;
            border-radius: 5px;
            padding: 1px 2px;
        }

        .markdown-body {
            box-sizing: border-box;
            min-width: 200px;
            max-width: 98%;
            margin: 20px auto;
            padding: 3px;
            /*首行缩进两个字符*/
            /* text-indent: 2em;*/
        }

    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){
        .mblog {
            min-width: 600px;
        }
    }
</style>