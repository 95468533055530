<template>
    <div id="wrapper">
        <Header></Header>
        <div class="userInfo" v-loading = "loading">
            <div class="header_top">
                <GoBack></GoBack>
            <div class="avatar">
                <div class="myAvatar">
                    <el-button type="success" size="mini"
                               @click="dialogVisible2 = true">更换头像</el-button>
                <div class="el-icon-loading">
                    <el-avatar :size="80" :src="users.image"></el-avatar>
                </div>
                </div>
            </div>
            <div class="title">
                <h1 style="color: #ffffff;text-align: center">个人资料</h1>
            </div>
            </div>
            <div class="information">

                <div class="data">
                    <span>用户名：{{users.userName}}</span>
                    <el-divider></el-divider>
                    <span>昵称：{{users.nickname}}</span>
                    <el-divider></el-divider>
                    <span>邮件：{{users.email}}</span>
                    <el-divider></el-divider>
                    <span>电话：{{users.phone}}</span>
                    <el-divider></el-divider>
                    <span>专业：{{users.profession}}</span>
                    <el-divider></el-divider>
                    <span>简介：{{users.introduction}}</span>
                    <el-divider></el-divider>
                    <el-button size="max" type="primary" @click="update(users)"><i class="el-icon-edit"></i>修改资料</el-button>
                    <el-button size="max" type="success" @click="blogEdit()"><i class="el-icon-edit-outline"></i>写文章</el-button>
                </div>
            </div>
        </div>
        <el-dialog title="上传头像"
                   :visible.sync="dialogVisible2"
                   width="300px">
            <el-form :model="form">
                <el-form-item :label-width="formLabelWidth"
                              ref="uploadElement">
                    <el-upload ref="upload"
                               action="#"
                               accept="image/png,image/gif,image/jpg,image/jpeg"
                               list-type="picture-card"
                               :limit=limitNum
                               :auto-upload="false"
                               :on-exceed="handleExceed"
                               :before-upload="handleBeforeUpload"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-change="imgChange"
                               :class="{hide:hideUpload}">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%"
                             :src="dialogImageUrl"
                             alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item>
                    <el-button size="small"
                               type="primary"
                               @click="uploadFile">立即上传</el-button>
                    <el-button size="small"
                               @click="tocancel">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>


        <!--修改详细资料-->
        <el-dialog title="更新" :visible.sync="dialogFormVisible" width="300px">
            <el-form :model="users">
                <el-form-item label="昵称" :label-width="formLabelWidth" prop="permission" >
                    <el-input v-model="users.nickname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户邮箱" :label-width="formLabelWidth" prop="permissionSign">
                    <el-input v-model="users.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="电话" :label-width="formLabelWidth" prop="permissionSign">
                    <el-input v-model="users.phone" placeholder="" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="专业" :label-width="formLabelWidth" prop="permissionSign">
                    <el-input v-model="users.profession" placeholder="" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="简介" :label-width="formLabelWidth" prop="permissionSign">
                    <el-input v-model="users.introduction" placeholder="填写你的个人简介" autocomplete="off"></el-input>
                </el-form-item>
                <!--<div class="selectTest">
                    <el-form-item label="是否隐藏">
                        <el-select v-model="users.isHide" :label-width="formLabelWidth"  placeholder="修改账号状态">
                            <el-option label="是" value="1"></el-option>
                            <el-option label="否" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                </div>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('form')">确认更新</el-button>
                <el-button @click="dialogFormVisible = false">取 消</el-button>
            </div>
        </el-dialog>


    </div>
</template>

<script>
    import Header from "./Header";
    import GoBack from "./GoBack";
    export default {
        inject: ["reload"],
        components: {GoBack, Header},
        data () {
            return {
                hideUpload: false,
                dialogImageUrl: '',
                dialogVisible: false,//图片预览弹窗
                formLabelWidth: '80px',
                limitNum: 1,
                dialogFormVisible: false,
                loading: true,
                form: {},
                isHide: 0,
                users:{
                    isHide:0,
                    userId:''
                },
                dialogVisible2: false,//弹窗
                languageList:[
                    {
                        value:'是',
                        key:1
                    },
                    {
                        value:'否',
                        key:0
                    }
                ],
            }
        },
        methods: {
            // 上传文件之前的钩子
            handleBeforeUpload (file) {
                if (!(file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
                    this.$notify.warning({
                        title: '警告',
                        message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
                    })
                }
                let size = file.size / 1024 / 1024 / 2
                if (size > 2) {
                    this.$notify.warning({
                        title: '警告',
                        message: '图片大小必须小于2M'
                    })
                }
                const _this = this;
                let fd = new FormData();//通过form数据格式来传
                fd.append("picFile", file); //传文件
                this.$axios.post("/account/up", fd, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }}
                ).then((data) => {
                    localStorage.setItem("avatar", data.data)
                    this.$notify.success({
                        title: '上传成功',
                        message: '头像已经修改了'
                    })
                    this.dialogVisible2 = false
                    _this.reload();
                })
            },
            // 文件超出个数限制时的钩子
            handleExceed (files, fileList) {

            },
            // 文件列表移除文件时的钩子
            handleRemove (file, fileList) {
                this.hideUpload = fileList.length >= this.limitNum;

            },
            // 点击文件列表中已上传的文件时的钩子
            handlePictureCardPreview (file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            uploadFile () {
                this.$refs.upload.submit()

            },
            imgChange (files, fileList) {
                this.hideUpload = fileList.length >= this.limitNum;
                if (fileList) {
                    this.$refs.uploadElement.clearValidate();
                }
            },
            tocancel () {
                this.dialogVisible2 = false
            },
            //编辑文章
            blogEdit(){
                this.$router.push({path:"/blogEdit"})
            },
            //获取单个用户信息
            user(){
                const _this = this;
                this.$axios.post("/userDetail/userInfoById", {id:localStorage.getItem("id")}).then(function (res) {
                    //console.log(res)
                    _this.users = res.data.data
                    if (res.data.data.isHide == 1){
                        _this.users.email = '**该用户隐藏了信息**'
                        _this.users.phone = '**该用户隐藏了信息**'
                        _this.users.profession = '**该用户隐藏了信息**'
                    }
                    _this.loading = false
                })
                window.scrollTo(0,0)
            },
            /*更新修改模态框里面的内容*/
            update(value) {
                if (this.users.isHide == 0){
                    this.users.isHide = '否';
                }else {
                    this.users.isHide = '是';
                }
                this.dialogFormVisible = true;
            },
            /*提交用户更新信息*/
            submitForm(value){
                if (this.users.isHide == '是'){
                    this.users.isHide = 1
                }else {
                    this.users.isHide = 0
                }
                /*if (localStorage.getItem("id") != ''|| localStorage.getItem("id") != null){*/
                this.users.userId = localStorage.getItem("id")

                const _that = this;
                this.$axios.post('/userDetail/updateUserInfo', _that.users).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.dialogFormVisible = false;
                    _that.reload();
                })
            },
        },
        created() {
            this.user();
        }
    }
</script>

<style scoped>
    .hide .el-upload--picture-card {
        display: none;
    }

    #wrapper{
        min-height: 1000px;
        width: 80%;
        margin: 0 auto;
    }

    .userInfo{
        margin: 100px auto;
        min-width: 80%;
        width: 60%;
        height: 750px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        float: revert;
    }

    .myAvatar {
        opacity: 0.88;
    }

    .header_top {
        border-radius: 10px;
        background:url(https://www.arien.top/image/userInfoBackground2.jpg);
        height:auto;
        width:100%;
        filter: blur(0px);
    }


    .el-avatar{
        margin: 20px 32px;
        float: left;
    }
    /*去掉加载的图标*/
    >>>.el-icon-loading:before {
        content: "";
    }

    .el-icon-loading {
        -webkit-animation: rotating 2s linear infinite;
        animation: rotating 8s linear infinite;
    }

   /* .header_top{
        width: 100%;
        height: 150px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }*/

    .avatar{
        width: 150px;
        height: 150px;
        background-color: #ffffff;
        border-radius: 10px;
        /*float: revert;*/
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        /*float: left;*/
        margin: 0 auto;
    }

    /*.title{
        width: 70%;
        height: 100px;
        background-color: #90e87c;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        float: right;
    }*/

    .information{
        opacity: 0.78;
        height: 600px;
        width: 100%;
        font-weight: bolder;
        padding: 30px 0;
        background-color: #fcfaff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        float: right;
    }
    .data{
        color: #2c0b61;
        margin: 0 auto;
        width: 60%;
        height: 60%;
        font-size: 15px;
    }


    .el-button--primary {
        color: #FFF;
        background-color: rgba(58, 208, 173, 0.64);
        border-color: #36aa89;
    }

    @media screen and (max-width:867px) {
        #wrapper{
            min-height: 1000px;
            width: 90%;
            margin: 0 auto;
        }

        /*>>>.el-avatar {
            margin: 20px 36px;
            float: left;
        }*/

        .userInfo{
            margin: 100px auto;
            min-width: 80%;
            width: 90%;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            float: revert;
        }

        .avatar{
            width: 150px;
            height: 150px;
            background-color: #ffffff;
            border-radius: 10px;
            /*float: revert;*/
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }
        .information{
            opacity: 0.78;
            height: 600px;
            width: 100%;
            font-weight: bolder;
            padding: 30px 0;
            background-color: #fcfaff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            float: right;
        }
        .data{
            margin: 0 auto;
            width: 90%;
            height: 70%;
            font-size: 15px;
        }

    }

</style>