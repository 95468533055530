<template>
    <div>
        <Header></Header>
        <div class="wrapper">

        <div class="search_background">
           <div class="mysearch">
               <div class="mysearch_left"><el-input v-model="input" v-on:input="search(input)" placeholder="请输入标题、作者、文章相关信息等搜索全站内容"></el-input></div>
               <div class="mysearch_right"><el-button type="primary" icon="el-icon-search" @click="page(input)">搜索</el-button></div>
           </div>
            <div class="search" >
                <el-tag class="myTag"  v-for="(item,index) in article" @click="searchArticle(item)" :key="index"   :type="state">{{item}}</el-tag>
                <!--v-if="index%2 != 0?state = 'warning':state = 'success'"-->
            </div>
        </div>

            <!--搜索内容显示-->
            <div class="search_content">

                <div class="mycard" @click="detail(blog.id, blog.userId)" v-for="(blog, index) in blogs" :key="index">
                    <div v-show="blog.titleImage != null">
                        <div class="mycard_left" @click="detail(blog.id, blog.userId)"><img :src="blog.titleImage" /></div>
                    </div>
                    <div v-show="blog.titleImage == null || blog.titleImage == ''">
                        <img  @click="detail(blog.id, blog.userId)" src="https://www.arien.top/image/02.jpg" />
                    </div>
                    <div class="mycard_right">
                        <div class="mycard_right_title">
                            <span class="el-icon-mouse">{{
                  blog.title
                }}</span>
                        </div>
                        <div class="mycard_right_content">
                            <p>摘要:{{blog.detail|ellipsis}}</p>
                            <p style="border-radius:2px;color: #ede5e6;font-weight: bold;line-height: 3px;">作者:{{blog.updatedUser}}</p>
                        </div>
                        <div
                                v-show="blog.ariticle != null && blog.ariticle != ''"
                                class="blogstype"
                        >
                            <el-tag type="success" size="mini">{{ blog.ariticle }}</el-tag>
                        </div>
                        <div class="mycard_date el-icon-alarm-clock">
                            {{ blog.createdTime }}
                        </div>
                    </div>
                </div>

            <!--分页-->

            <el-pagination
                    class="mpage"
                    background
                    layout="prev, pager, next"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                    @current-change="page"
            >
            </el-pagination>

            </div>

        </div>
<!--      <Footer></Footer>-->
    </div>
</template>

<script>
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    export default {
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 20) {
                    return value.slice(0,20) + '...'
                }
                return value
            }
        },
        name: "Search",
        components: {Footer, Header},
        data(){
            return{
                state: 'success',
                input: '',
                value: new Date(),
                blogs:{},
                currentPage: 1,
                total: 1,
                pageSize: 8,
                queryInfo: '',
                article: []
            }
        },
        methods:{
            search(input){
                this.page(this.currentPage)
            },
            page(currentPage){
                const _this = this;
                _this.$axios.get("/javaBlogs/search?currentPage="+currentPage+"&query="+this.input).then(res =>{
                    _this.blogs = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                    window.scrollTo(0,0)
                })
            },
            detail(id, userId){
                /*console.log(JSON.stringify(id+"数据"+userId))*/
                localStorage.setItem("BLOGID", id)
                localStorage.setItem("CUURENTSERID", userId)
                this.$store.commit('SET_BLOGID',id)
                this.$store.commit('SET_CURRENTUSERID',userId)
                this.$router.push({path:"/blogDetail"})
            },
            queryAll() {
                const _this = this;
                _this.$axios.get("/javaBlogs/query_all_article").then(res =>{
                    _this.article = res.data.data
                })
            },
            searchArticle(item) {
                const _this = this;
                _this.$axios.get("/javaBlogs/searchArticle?currentPage="+_this.currentPage+"&article="+item).then(res =>{
                    _this.blogs = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                })
            }
        },
        created() {
            this.queryAll()
            this.page(1);
            /*this.queryInfo = this.$route.query.queryInfo;*/
            this.queryInfo = localStorage.getItem("queryInfo")
            if (this.queryInfo != null && this.queryInfo != ''){
                this.input = this.queryInfo
                this.page(1);
            }
            localStorage.setItem("queryInfo", "")
            /*if (localStorage.getItem("searchInfo") != null){
                this.input = localStorage.getItem("searchInfo")
                this.page(1);
            }*/
        }
    }
</script>

<style scoped>

    @import "../assets/cssgo/myCar.css";

    .wrapper{
        width: 80%;
        margin: 250px auto 0px;
        min-height: 1200px;
    }

    >>>.el-button--primary {
        color: #FFF;
        background-image: linear-gradient(to right, rgb(255, 88, 71), rgba(208, 110, 255, 0.99));
        border-color: #ff877e;
    }

    >>>.el-button--primary:focus, .el-button--primary:hover {
        background: #af39c5;
    }

    p{
        color: white;
    }
    .search_background{
        width: 90%;
        height: 400px;
        margin: -150px auto;
        padding: 50px;
        border-radius: 15px;
        background: url("https://www.arien.top/image/搜索背景.jpg");
    }

    .mysearch{
        width: 820px;
        height: 80px;
        margin: 100px auto 20px;
    }

    .search{
        width: 80%;
        height: auto;
        margin: 10px auto;

    }
    .mysearch_left{
        width: 650px;
        height: 80px;
        float: left;
    }
    .mysearch_right{
        width: 150px;
        float: left;
        margin-left: 20px;
    }
    /*搜索内容显示*/
    /*.mycard {
        width: 100%;
        height: 200px;
        border-radius: 20px;
        background: white;
        margin: 70px 2px 70px 20px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }*/
    .mycard {
        width: 88%;
        max-width: 1200px;
        height: 210px;
        border-radius: 20px;
        /*background: white;*/
        margin: 50px auto;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        background-image: linear-gradient(to right, rgba(106, 224, 236, 0.93), rgba(151, 149, 255, 0.99));
    }
    .mycard_left {
        float: left;
        width: 40%;
        min-width: 170px;
        height: 230px;
    }
    .mycard_left > img {
        width: 100%;
        height: 100%;
    }
    .mycard_right {
        float: left;
        height: 210px;
        width: 60%;
        background-image: linear-gradient(to right, rgba(255, 248, 241, 0), rgba(208, 110, 255, 0));
    }


    .mycard_right_title {
        font-size: 25px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: bold;
        text-decoration: none;
    }
    .mycard_right_title > span{
        color: #ffffff;
    }
    a {
        text-decoration: none;
    }

    .mycard_right_content {
        font-size: 17px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: lighter;
        padding: 1px;
        font-family: 迷你简家书;
    }
    .mycard_date {
        font-size: 20px;
        float: right;
        height: 15px;
        width: 160px;
        margin: 40px 1px 10px 0px;
        font-size: 12px;
        font-family: "Adobe 仿宋 Std R";
        font-weight: bold;
        line-height: 3px;
    }
    .carousel {
        width: 100%;
        height: 370px;
        overflow: hidden;
    }
    .carousel > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    /*AD走马灯*/
    /* .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 200px;
            margin: 0;
        }*/

    .blogstype {
        float: left;
        height: 25px;
        margin-left: 17px;
        border-radius: 8px;
    }
    .mpage{
        margin: 10px auto;
        text-align: center;
    }

    .myTag {
        margin: 1px 10px;
    }

    >>>.el-tag.el-tag--success {
        /*background-image: linear-gradient(to right, #8f7bd7, #ec74a2);*/
        background-color: #8f7bd7;
        border-color: #e1f3d8;
        color: #ffffff;
        margin-left: 10px;
    }



    @media screen and (max-width:867px) {

        .wrapper{
            width: 100%;
        }

        .search_background{
            width: 70%;
            min-height: 350px;
            height: auto;
            margin: -150px auto;
            padding-top: 100px;
            border-radius: 15px;
            background: url("https://www.arien.top/image/搜索背景.jpg");
        }

        .mysearch{
            width: 100%;
            height: 80px;
            margin: 100px auto;

        }

        .mysearch{
            width: 100%;
            height: 80px;
            margin: 0px auto;
        }
        .mysearch_left{
            width: 70%;
            height: 80px;
            float: left;
        }
        .mysearch_right{
            width: 20%;
            float: left;
            margin-left: 20px;
        }
        .mycard {
            margin: 18px auto;
            width: 98%;
            height: 160px;
            overflow: hidden;
        }

        .mycard_right {
            width: 56%;
            height: 100%;
            z-index: 999;
            margin: 0 0 0 5px;
            /*opacity: 0.78;*/
            float: right;
            background-image: linear-gradient(to right, rgba(151, 149, 255, 0.78), rgba(105, 24, 130, 0.78));
            /*filter: blur(1px);*/
        }

        .mycard_left {
            float: left;
            width: 0%;
            min-width: 100px;
            height: 230px;
        }

        .carousel {
            width: 100%;
            height: 370px;
            border-radius: 10px !important;;
            overflow: hidden !important;;
        }

        .carousel > img {
            height: 100%;
            width: 100%;
        }

        /*  .mycard_left {
            float: left;
            width: 35%;
            min-width: 150px;
            height: 230px;
          }*/
        .carousel > img {
            height: 100%;
            width: 100%;
        }

        .mycard_right_title {
            font-size: 16px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            text-decoration: none;
            line-height: 8px;
        }

        .mycard_right_content {
            font-size: 13px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            line-height: 15px;
            padding: 0px;
            color: #ffffff;
        }

        .mycard_date {
            float: right;
            height: auto;
            width: 100%;
            margin-bottom: 0;
            margin-right: 0;
            font-size: 10px;
            margin-top: 12px;
            font-weight: bold;
        }

        >>> .el-button--mini {
            margin-left: 16px;
            padding: 7px 15px;
        }

        >>> .el-menu-item [class^=el-icon-] {
            margin-right: 5px;
            width: 2px;
            text-align: center;
            font-size: 25px;
            vertical-align: middle;
        }

        .mydate {
            float: right;
            margin-right: 28px;
            margin-top: 70px;
            width: 300px;
            height: auto;
            /* border: 2px solid #336699;*/
            overflow: hidden;
            border-radius: 10px;
            padding: 10px 3px;
            font-size: 18px;
            background: white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }

        .mycard_left > img {
            width: auto;
            height: 100%;
        }

        .search{
            width: 110%;
            height: 0px;
            margin: 10px auto;

        }

    }
</style>