<template>
<div>
    <el-tooltip content="返回上一页" placement="bottom" effect="light">
        <div class="idea" @click="goBack()">
            <div class="blue">
            </div>
            <div class="yellow">
            </div>
            <div class="red">
            </div>
        </div>
    </el-tooltip>
</div>
</template>

<script>
    export default {
        name: "GoBack",
        data(){
            return{

            }
        },
        methods:{
            goBack(){
                this.$router.back()
            }
        }
    }
</script>

<style scoped>
    .idea {
        margin-top: 5px;
        margin-left: 5px;
        width: 120px;
        height: 36px;
        padding: 3px 3px;
    }

    .blue {
        width: 30px;
        height: 30px;
        float: left;
        border-radius: 100px;
        background-color: #3eb4a6;
    }


    .yellow {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 10px;
        border-radius: 100px;
        background-color: #eeda5d;
    }

    .red {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        float: left;
        border-radius: 100px;
        background-color: #de505e;
    }

</style>