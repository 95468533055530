<template>
    <div>
        <Header></Header>
        <div id="wrapper">
            <GoBack></GoBack>
            <h1 align="center" style="color: #612bb3">Friends</h1>
            <div style="display:flex;justify-content: space-around;flex-wrap: wrap;">
                <div class="friendInfo" v-for="info in tableData">
                    <a :href="info.link" target="_blank">
                        <div class="leave_left" >
                            <div  class="mylogo el-icon-loading"><el-avatar :size="80" :src="info.avatar"></el-avatar></div>
                            <div class="leave_name">
                                <el-tag type="warning" effect="dark">
                                    {{info.image}}
                                </el-tag>
                            </div>
                            <div class="userDetail">
                                {{info.detail}}
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="lowInfo">
                <a href="/blogDetail?shareId=18">
                <el-alert
                        closable = false
                        title="提示"
                        type="success"
                        show-icon
                        description="如果需要添加友链可以点击这里跳转到友链添加页面">
                </el-alert>
                </a>
            </div>
        </div>
<!--      <Footer></Footer>-->
    </div>

</template>

<script>
    import Header from "../components/Header";
    import GoBack from "../components/GoBack";
    import Footer from "@/components/Footer.vue";
    export default {
        components: {Footer, GoBack, Header},
        inject: ["reload"],
        name: "friendLink",
        data(){
            return{
                dialogFormVisible: false,
                tableData:{
                    requestTate: 0,
                },
                form: {
                    id:'',
                    title:'',
                    requestMsg:'',
                    requestTate:''
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total: ''
                },
                languageList:[
                    {
                        value:'未处理',
                        key:0
                    },
                    {
                        value:'已处理',
                        key:1
                    }
                ],
            }
        },
        methods:{
            query(){
                const _that = this;
                this.$axios.post('friend/list', _that.pageInfo).then(function (response) {
                    console.log(response.data.data.records)
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                    if (_that.pageInfo.total <= 5){
                        _that.value = true;
                    }
                    window.scrollTo(0, 0)
                })
            },
        },
        created() {
            this.query()
        }
    }
</script>

<style scoped>
    #wrapper {
        margin: 100px auto;
        min-height: 800px;
        height: auto;
        width: 80%;
        border-radius: 12px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        overflow: hidden;
        z-index: 1;
        background-image: linear-gradient(to right, #9aedfc, #fc9ad0, #9c92f8);
    }


    /*.temp {
        width: 90%;
        margin: 0 auto;
        height: auto;
        background: #42b983;
    }*/
    .leave_left{
        color: #fff6f6;
        float: left;
        width: 200px;
        height: 220px;
        margin: 10px auto;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgb(74, 86, 161);
        background-image: linear-gradient(to bottom right, #4c83fc, #ff5791);
        z-index: 10;
        transform: scale(1);
        transition: all 1s ease 0s;
    }

    .leave_left:hover {
        transform: scale(1.1);
        transition: all 1s ease 0s;
    }

    /*.leave_left:hover {
        background-image: linear-gradient(to bottom right, #4c83fc, #ff5791);
    }*/

    >>>.el-tag--dark.el-tag--warning {
        background-image: linear-gradient(to bottom right, #a83ab4, #7a6cf6);
        border-color: #7a6cf6;
        color: #fff;
    }

    >>>.el-tag--dark.el-tag--warning:hover {
        background-image: linear-gradient(to bottom right, #84adf3, #7f5ad4);
    }

    >>>.el-alert--success.is-light {
        background-image: linear-gradient(to right, #3db8e8, #ae2ef3);
        color: #ffffff;
    }
    >>>.el-alert--success.is-light .el-alert__description {
        color: #ffffff;
    }
    .mylogo{
        width: 80px;
        height: 80px;
        margin: 30px 20px 10px 57px;
    }
    .leave_name{
        width: 200px;
        text-align: center;
        height: 20px;
        font-weight: bold;
        font-size: 20px;
        font-family: 微軟正黑體;
        color: #0f3c3e;
        margin: 0 auto;
    }

    .friendInfo {
        width: auto;
        margin-left: 20px;
        margin-top: 20px;
        float: left;
    }

    /*去掉加载的图标*/
    >>>.el-icon-loading:before {
        content: "";
    }

    .el-icon-loading {
        -webkit-animation: rotating 2s linear infinite;
        animation: rotating 8s linear infinite;
    }

    >>>.el-alert .el-alert__description {
        font-size: 25px;
        margin: 5px 0 0;
    }

    >>>.el-alert__title {
        font-size: 28px;
        line-height: 18px;
    }
    .lowInfo{
        margin-top: 50px;
        width: 100%;
        float: left;
    }
    .userDetail {
        margin: 20px auto;
        height: 50px;
        width: 180px;
        color: #ffffff;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
    }

    @media screen and (max-width:867px) {
        #wrapper {
            margin: 100px auto;
            min-height: 800px;
            height: auto;
            width: 98%;
            border-radius: 15px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            overflow: hidden;
            z-index: 1;
        }
        .leave_left{
            color: #fff6f6;
            float: left;
            width: 145px;
            height: 220px;
            margin: 10px auto;
            border-radius: 10px;
            z-index: 10;
            /*background-image: linear-gradient(to bottom right, #4c83fc, #ff5791);*/
        }

        .mylogo{
            width: 80px;
            height: 80px;
            margin: 30px 20px 10px 33px;
        }

        .leave_name{
            width: 145px;
            text-align: center;
            height: 20px;
            font-weight: bold;
            font-size: 15px;
            font-family: 微軟正黑體;
            color: #0f3c3e;
            margin: 0 auto;
        }
        .userDetail {
            margin: 20px auto;
            height: 50px;
            width: 145px;
            font-weight: bold;
            text-align: center;
            font-size: 14px;
            font-family: 华光平黑_CNKI;
        }
    }

</style>