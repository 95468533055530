<template>
    <div class="emoji">
        <el-tabs v-model="activeFace" cancel-button-text='关闭'>
            <el-tab-pane label="图片表情" name="face"></el-tab-pane>
        </el-tabs>
        <ul class="emoji-container" >
            <li v-for="(item, index) in faceList" :key="index" @click="shoseEmoji(item.value)">
                <a href="javaScript:;">
                    <img :src="item.url" alt="">
                </a>
            </li>
        </ul>
        <!--<MyLeave ref="leave" />-->
        <div id="hello1" style="width: 10px;height: 10px" v-show="false"></div>
    </div>
</template>

<script>
    import MyLeave from "../views/MyLeave";
    export default {
        name: 'emoji',
        inject:['reload'],//注入刷新页面代码块
        components: {MyLeave},
        data () {
            return {
                isShow: true,
                activeFace: 'face',
                faceList: [ // 存放在本地文件的图片的获取方式需要用require
                    {value: '$001', url: require('../assets/imgs/01.png')},  // value为表情对应的unicode码，url为路径
                    {value: '$002', url: require('../assets/imgs/02.png')},
                    {value: '$003', url: require('../assets/imgs/03.png')},
                    {value: '$004', url: require('../assets/imgs/04.png')},
                    {value: '$005', url: require('../assets/imgs/05.png')},
                    {value: '$006', url: require('../assets/imgs/06.png')},
                    {value: '$007', url: require('../assets/imgs/07.png')},
                    {value: '$008', url: require('../assets/imgs/08.png')},
                    {value: '$009', url: require('../assets/imgs/09.png')},
                    {value: '$010', url: require('../assets/imgs/10.png')},
                    {value: '$011', url: require('../assets/imgs/11.png')},
                    {value: '$012', url: require('../assets/imgs/12.png')}
                ]
            }
        },
        methods: {
            shoseEmoji (emoji) {
                localStorage.setItem("emoji", emoji)
                /*this.$emit('select', emoji)*/
                /*this.$refs.leave.pushEmoji();*/
            },
            esc () {
                document.getElementById("hello1").click();
            },
            openImgs() {
                this.isShow = true;
            }
        }
    }
</script>

<style scoped>

    .emoji {
        width: 350px;
        height: 186px;
        bottom: 50px;
        background: #fff;
        z-index: 10;
        padding: 10px;
        margin-right: 10px;
        background: white;
        overflow-y: scroll;
        border-radius: 10px;
    }
    li{ list-style: none;}
    .emoji-container {
        overflow-y: auto;
        overflow-x: hidden;
        position: relative;
    }
    .emoji-container li {
        float: left;
        width:50px;
        height: 50px;
        margin: 5px
    }
    .emoji-container li a {
        display: block;
        float: left;
        overflow: hidden;
        width: 50px;
        height: 50px;
        display:flex;
        justify-content:center;
        align-items:center;
        transition: all ease-out .2s;
        border-radius: 4px;
    }
    .emoji-container li a img {
        display: block;
        width: 40px;
        height: 40px;
    }
    .emoji-container li a:hover {
        background-color: #d8d8d8;
        border-color: #d8d8d8;
    }

    @media screen and (max-width:867px) {
        .emoji {
            width: 250px;
            height: 186px;
            bottom: 50px;
            background: #fff;
            z-index: 10;
            padding: 10px;
            margin-right: 10px;
            background: white;
            overflow-y: scroll;
            border-radius: 10px;
        }
    }
</style>