<template>
    <div class="wrapper">
        <Header></Header>
        <br/>
        <!--<el-button type="primary" @click="getBack()">返回</el-button>-->
        <el-tooltip content="返回上一页" placement="bottom" effect="light">
            <div class="idea" @click="getBack">
                <div class="blue">
                </div>
                <div class="yellow">
                </div>
                <div class="red">
                </div>
            </div>
        </el-tooltip>
        <h1 align="center">文章编辑模块</h1>
        <div class="m-content">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="ruleForm.title"></el-input>
                </el-form-item>

                <el-form-item label="摘要" prop="detail">
                    <el-input type="textarea" v-model="ruleForm.detail"></el-input>
                </el-form-item>

                <el-form-item label="封面预览效果">

                    <div class="mycard">
                        <div>
                            <div class="mycard_left"><img :src="ruleForm.titleImage" /></div>
                        </div>
                        <div class="mycard_right">
                            <div class="mycard_right_title">
                            </div>
                            <div class="mycard_right_content">
                                <p style="border-radius:2px;color: #ed6f26;font-weight: bold;line-height: 3px;"></p>
                            </div>
                            <div
                                    class="blogstype"
                            >
                            </div>
                            <div class="mycard_date">
                            </div>
                        </div>
                    </div>

                   <!-- <el-image
                            style="width: 200px; height: 100px;"
                            :src="ruleForm.titleImage"
                            >
                    </el-image>-->
                    <!--<el-input v-model="ruleForm.titleImage"></el-input>-->
                    <el-button type="success" @click="changeCover">修改封面</el-button>
                </el-form-item>


                <el-form-item label="分类">
                    <el-input v-model="ruleForm.ariticle"></el-input>
                </el-form-item>

                <el-form-item label="模块划分">
                <el-select v-model="ruleForm.plateId" placeholder="模块" clearable>
                    <el-option
                            v-for="l in languageList"
                            :key="l.key"
                            :label="l.value"
                            :value="l.key">
                    </el-option>
                </el-select>
                </el-form-item>


                <el-form-item style="margin-top: 20px;" label="内容" prop="content">
                </el-form-item>
                <mavon-editor class="markdown-body" ref=md  @imgAdd="$imgAdd" v-highlight  v-model="ruleForm.content"></mavon-editor>


                <el-form-item>
                    <el-button  type="primary" @click="submitForm('ruleForm')">发布</el-button>
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
            <div class="commitInfo">
                <el-button  type="warning" size="mini" @click="submitForm('ruleForm')">快速发布</el-button>
                <!--<el-button @click="resetForm('ruleForm')">重置</el-button>-->
            </div>
        </div>

        <el-dialog title="选择封面"
                   :visible.sync="dialogVisible2"
                   width="300px">
            <el-form :model="form">
                <el-form-item :label-width="formLabelWidth"
                              ref="uploadElement">
                    <el-upload ref="upload"
                               action="#"
                               accept="image/png,image/gif,image/jpg,image/jpeg"
                               list-type="picture-card"
                               :limit=limitNum
                               :auto-upload="false"
                               :on-exceed="handleExceed"
                               :before-upload="handleBeforeUpload"
                               :on-preview="handlePictureCardPreview"
                               :on-remove="handleRemove"
                               :on-change="imgChange"
                               :class="{hide:hideUpload}">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                        <img width="100%"
                             :src="dialogImageUrl"
                             alt="">
                    </el-dialog>
                </el-form-item>
                <el-form-item>
                    <el-button size="small"
                               type="primary"
                               @click="uploadFile">立即上传</el-button>
                    <el-button size="small"
                               @click="tocancel">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
      <Footer></Footer>
    </div>

</template>

<script>
    import Footer from "../components/Footer";
    import Header from "../components/Header";
    export default {
        name: "BlogEdit",
        components: {Header, Footer},
        data() {
            return {
                hideUpload: false,
                dialogImageUrl: '',
                dialogVisible: false,//图片预览弹窗
                formLabelWidth: '80px',
                limitNum: 1,
                dialogFormVisible: false,
                form: {},
                dialogVisible2: false,//弹窗
                ruleForm: {
                    id:'',
                    title: '',
                    detail: '',
                    ariticle: '',
                    content:'',
                    userId:'',
                    currentUserId:'',
                    plateId:1,
                    titleImage:"https://www.arien.top/image/blogs/default.jpg",
                    type:""
                },
                rules: {
                    title: [
                        { required: true, message: '请输入标题', trigger: 'blur' },
                        { min: 3, max: 25, message: '长度在 3 到 25 个字符', trigger: 'blur' }
                    ],
                    detail: [
                        { required: true, message: '请输入摘要', trigger: 'blur' }
                    ],
                    content: [
                        { required: true, message: '请输入内容', trigger: 'blur' }
                    ]
                },
                languageList:[
                    {
                        value:'JAVA',
                        key:1
                    },
                    {
                        value:'Spring',
                        key:2
                    },
                    {
                        value:'数据库',
                        key:3
                    },
                    {
                        value:'VUE',
                        key:4
                    },
                    {
                        value:'服务器',
                        key:5
                    },
                    {
                        value:'其他',
                        key:6
                    }
                ],
            };
        },
        methods: {
            // 绑定@imgAdd event
            $imgAdd(pos, $file){
                // 第一步.将图片上传到服务器.
                var formdata = new FormData();
                formdata.append('images', $file);
                if (!($file.type === 'image/png' || $file.type === 'image/gif' || $file.type === 'image/jpg' || $file.type === 'image/jpeg')) {
                    this.$notify.warning({
                        title: '警告',
                        message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
                    })
                }
                let size = $file.size / 1024 / 1024 / 12
                if (size > 5) {
                    this.$notify.warning({
                        title: '警告',
                        message: '图片大小必须小于12M'
                    })
                }
                this.$axios.post("/javaBlogs/markdownImg",formdata,
                    {headers: { 'Content-Type': 'multipart/form-data' }
                    }).then((url) => {
                    // 第二步.将返回的url替换到文本原位置![...](0) -> ![...](url)
                    // $vm.$img2Url 详情见本页末尾
                    this.$refs.md.$img2Url(pos, url.data);
                })
            },
            submitForm(formName) {
                this.ruleForm.userId = localStorage.getItem("id");
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        const _this = this
                        this.ruleForm.createdTime = ''
                        this.ruleForm.updatedTime = ''
                        this.$axios.post("/javaBlogs/updateContent", this.ruleForm,{
                            headers:{
                                "Authorization":localStorage.getItem("token")
                            }
                        }).then(res =>{
                            this.$message({
                                message: '发表成功！',
                                type: 'success'
                            });
                            _this.$router.push("/")
                            console.log(res);
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            /*修改封面*/
            changeCover() {
            this.dialogVisible2 = true;
            },
            uploadFile () {
                this.$refs.upload.submit()
            },
            tocancel () {
                this.dialogVisible2 = false
            },
            /*文件上传*/
            // 上传文件之前的钩子
            handleBeforeUpload (file) {

                if (!(file.type === 'image/png' || file.type === 'image/gif' || file.type === 'image/jpg' || file.type === 'image/jpeg')) {
                    this.$notify.warning({
                        title: '警告',
                        message: '请上传格式为image/png, image/gif, image/jpg, image/jpeg的图片'
                    })
                }
                let size = file.size / 1024 / 1024 / 10
                if (size > 100) {
                    this.$notify.warning({
                        title: '警告',
                        message: '图片大小必须小于10M'
                    })
                }
                let fd = new FormData();//通过form数据格式来传
                fd.append("picFile", file); //传文件
                this.$axios.post("/javaBlogs/uploadTitleImg", fd, {
                    headers: { 'Content-Type': 'multipart/form-data' }}
                ).then((data) => {
                    this.ruleForm.titleImage = data.data
                    this.$notify.success({
                        title: '上传成功',
                        message: '封面已修改！'
                    })
                })
            },
            getBack(){
                this.$router.back()
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }/*,
            /!*编辑器默认值和图片上传*!/
            uploadImg(pos, file) {
                var formData = new FormData();
                formData.append('image', file);
                this.$axios({
                    url: 'static',
                    method: "post",
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((url) => {
                    //使用服务器返回的图片地址替换原图片地址
                    $vm.$img2Url(pos, url);
                })
            }*/
        },
        created() {
            const blogId = this.$route.params.blogId;
            const articleId = this.$route.params.currentUserId;
            this.ruleForm.currentUserId = articleId;
            if(blogId){
                const _this =this
                this.$axios.post("/javaBlogs/queryById", {id:blogId}).then(res =>{
                    const blog = res.data.data
                    _this.ruleForm = blog
                    _this.ruleForm.id = blog.id
                    _this.ruleForm.title = blog.title
                    _this.ruleForm.detail = blog.detail
                    _this.ruleForm.content = blog.content
                    _this.ruleForm.titleImage = blog.titleImage
                    _this.ruleForm.ariticle = blog.ariticle
                    window.scrollTo(0,0)
                })
            }
        },
        toolbars: {
            bold: true, // 粗体
            italic: true, // 斜体
            header: true, // 标题
            underline: true, // 下划线
            strikethrough: true, // 中划线
            mark: true, // 标记
            superscript: true, // 上角标
            subscript: true, // 下角标
            quote: true, // 引用
            ol: true, // 有序列表
            ul: true, // 无序列表
            link: true, // 链接
            imagelink: true, // 图片链接
            code: true, // code
            table: true, // 表格
            fullscreen: true, // 全屏编辑
            readmodel: true, // 沉浸式阅读
            htmlcode: true, // 展示html源码
            help: true, // 帮助
            /* 1.3.5 */
            undo: true, // 上一步
            redo: true, // 下一步
            trash: true, // 清空
            save: true, // 保存（触发events中的save事件）
            /* 1.4.2 */
            navigation: true, // 导航目录
            /* 2.1.8 */
            alignleft: true, // 左对齐
            aligncenter: true, // 居中
            alignright: true, // 右对齐
            /* 2.2.1 */
            subfield: true, // 单双栏模式
            preview: true, // 预览
        }
    }
</script>

<style scoped>
    .wrapper{
        margin: 0 auto;
        height: auto;
        min-height: 800px;
        width: 95%;
        padding: 10px;
        margin: 50px auto;
        background: #ffffff;
        border-radius: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    >>>pre {
        position: relative;
        margin-bottom: 24px;
        background: #FFF;
        overflow: hidden;
        font-size: 16px;
    }
    >>>.pre-numbering {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        padding: 12px 2px 12px 0;
        border-right: 1px solid #C3CCD0;
        background-color: #EEE;
        text-align: right;
    }
    >>>.pre-numbering li{
        list-style:none;
        font-family: Menlo, monospace;
        color: #47a394;
    }

    .m-content {
        margin-top: 100px;
        text-align: center;
        width: 100%;
        height: auto;
        float: revert;
    }

    .idea {
        width: 120px;
        height: 30px;
    }

    .blue {
        width: 30px;
        height: 30px;
        float: left;
        border-radius: 100px;
        background-color: #3eb4a6;
    }

    .yellow {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 10px;
        border-radius: 100px;
        background-color: #eeda5d;
    }

    .red {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        float: left;
        border-radius: 100px;
        background-color: #de505e;
    }


    .markdown-body {
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
        line-height: 1.5;
        color: #24292e;
        font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 16px;
        line-height: 1.5;
        word-wrap: break-word;
        z-index: auto;
    }

    /*卡片*/
    .mycard {
        width: 500px;
        height: 160px;
        border-radius: 20px;
        background: #acf8c6;
        margin: 50px 0px 50px 0px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .mycard_left {
        float: left;
        width: 43%;
        min-width: 150px;
        height: 200px;
    }
    .mycard_left > img {
        width: 100%;
        height: 100%;
    }
    .mycard_right {
        float: left;
        height: 200px;
        width: 60%;
    }
    .mycard_right_title {
        font-size: 25px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: bold;
        text-decoration: none;
    }
    .mycard_right_title > span{
        color: #ff7d05;
    }
    a {
        text-decoration: none;
    }

    .mycard_right_content {
        font-size: 17px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: lighter;
        padding: 1px;
        font-family: 迷你简家书;
    }
    .mycard_date {
        font-size: 20px;
        float: right;
        height: 15px;
        width: 160px;
        margin: 40px 1px 10px 0px;
        font-size: 12px;
        font-family: "Adobe 仿宋 Std R";
        font-weight: bold;
        line-height: 3px;
    }

    .commitInfo {
        width: 70px;
        height:2.8em;
        margin:0 auto;
        overflow:hidden;
        position: fixed;
        /*bottom:0;*/
        z-index: 999;
        top: 45%;
        right: 0;
    }

    .el-button--primary {
        color: #FFF;
        background-color: rgba(58, 208, 173, 0.64);
        border-color: #36aa89;
    }

    >>>.markdown-body {
        box-sizing: border-box;
        min-width: 200px;
        width: 100%;
        max-width: 1500px;
        margin: 0 auto;
        padding: 5px;
        /*首行缩进两个字符*/
        /*text-indent: 2em;*/
        z-index: auto;
        /*font-family: 华文仿宋;*/
        font-weight: bold;
        font-size: 17px;
        /*color: rgba(0, 0, 0, 0.72);*/
    }

    >>>.markdown-body h1 {
        padding-bottom: .3em;
        font-size: 2em;
        border-bottom: 1px solid #b264d2;
        color: #571887;
    }

    >>>.markdown-body h2 {
        padding-bottom: .3em;
        font-size: 1.5em;
        border-bottom: 1px solid #eaecef;
        color: #32857d;
    }

    .markdown-body h3 {
        font-size: 1.25em;
        color: #541698;
    }

    >>>.markdown-body p {
        margin-top: 0;
        margin-bottom: 10px;
        color: #2e6479;
    }


    >>>.markdown-body h3 {
        font-size: 1.25em;
        color: #619c4e;
    }

    /*代码块*/
    >>>.hljs {
        display: block;
        overflow-x: auto;
        padding: 0.5em;
        color: #bd74ee;
        background: #27291e;
        border: 0px solid #27291e;
        border-radius: 8px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        font-size: 14px;
        font-family: 方正综艺_GBK;
    }

    >>>.markdown-body pre {
        padding: 6px;
        overflow: auto;
        font-size: 35%;
        line-height: 1.45;
        background-color: #212528;
        border-radius: 5px;
    }
    >>>.markdown-body .highlight pre, .markdown-body pre {
        padding: 16px;
        overflow: auto;
        font-size: 85%;
        line-height: 1.45;
        background-color: #f6f8fa;
        border-radius: 5px;
        font-family: 方正综艺_GBK;
        font-size: 15px;
    }



    >>>.hljs-number, .hljs-literal, .hljs-variable, .hljs-template-variable, .hljs-tag .hljs-attr {
        color: #70b723;
    }



    >>>.hljs-string, .hljs-doctag {
        color: #78cebc;
    }

    >>>.lang- {
        color: #3bc984;
        font-weight: bold;
        font-size: 14px;
    }


    >>>.hljs-keyword, .hljs-selector-tag, .hljs-subst {
        color: #95e54c;
        font-weight: bold;
    }

    >>>.hljs-comment, .hljs-quote {
        color: #e6a23c;
        font-style: italic;
    }

    >>>.hljs-title, .hljs-section, .hljs-selector-id {
        color: #7a82e9;
        font-weight: bold;
    }

    /*String*/
    >>>.hljs-string, .hljs-doctag {
        color: #11ddc1;
    }

    >>>.markdown-body img {
        useCORS: true;
        background-size: 100%;
        background-repeat: no-repeat;
        display:block;
        margin: 10px auto;
        /*width: auto;*/
        width: auto;
        max-width: 100%;
        /*padding: 0 10%;*/
        object-fit: cover;
        max-height: 550px;
        border-radius:6px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    >>>.v-note-wrapper .v-note-panel .v-note-show .v-show-content, .v-note-wrapper .v-note-panel .v-note-show .v-show-content-html {
        width: 100%;
        height: 100%;
        padding: 8px 2px 15px 2px;
        overflow-y: auto;
        box-sizing: border-box;
        overflow-x: hidden;
        z-index: 900;
    }

    @media screen and (max-width:867px) {
        .wrapper{
            margin: 0 auto;
            height: auto;
            min-height: 800px;
            width: 98%;
            padding: 3px;
            margin: 50px auto;
            background: #ffffff;
            border-radius: 20px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
        /*卡片*/
        .mycard {
            width: 100%;
            height: 120px;
            border-radius: 20px;
            background: #cafcb4;
            margin: 50px 0px 50px 0px;
            overflow: hidden;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
    }

</style>