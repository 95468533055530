<template>
    <div>
        <el-scrollbar>
            <el-button size="mini" type="danger" @click="moreDel(authId)">删除勾选</el-button>
            <el-table
                    show-header
                    ref="multipleTable"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :data="tableData"
                    :key="Math.random()">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column prop="permission" label="权限说明（名称）" width="140">
                </el-table-column>
                <el-table-column prop="permissionSign" label="权限标识" width="120">
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="180">
                </el-table-column>
                <el-table-column prop="updatedUser" label="更新人" width="130">
                </el-table-column>
                <el-table-column prop="updatedTime" label="更新时间" width="180">
                </el-table-column>
                <el-table-column prop="authId" label="删除" width="120">
                    <template slot-scope="scope">
                        <el-popover
                        placement="top"
                        width="160"
                        v-model="visible">
                        <p>你确定删除当前权限吗？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="success" @click="del(scope.row.authId)">确认</el-button>
                        </div>
                        <el-button slot="reference" size="mini" type="danger">删除</el-button>
                        </el-popover>
                        &nbsp;&nbsp;
                    </template>
                </el-table-column>
                <el-table-column prop="authId" label="更新" width="180">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="update(scope.row)">修改</el-button>
                        <el-button size="mini" type="success" @click="add()">添加</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>




        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>

        <div class="search">
            <el-dialog title="更新" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="权限名称" :label-width="formLabelWidth" prop="permission" >
                        <el-input v-model="form.permission" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="权限标识" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.permissionSign" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitForm('form')">确认更新</el-button>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>

        </div>
    </div>

</template>

<script>
    export default {
        name: "AccountInfo",
        data() {
            return {
                value: false,
                rules:{
                    permission: [
                        { required: true, message: "请输入权限名称", trigger: "blur" },
                        {
                            min: 3,
                            max: 15,
                            message: "长度在 3 到 15 个字符",
                            trigger: "blur",
                        },
                    ],
                    permissionSign: [
                        { required: true, message: "请输入权限标识", trigger: "blur" },
                        {
                            min: 3,
                            max: 15,
                            message: "长度在 6 到 15 个字符",
                            trigger: "blur",
                        },
                    ],
                },
                tableData:{
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total: ''
                },
                ids:[],
                dialogFormVisible: false,
                form: {
                    authId:'',
                    permission:'',
                    permissionSign:''
                },
                formLabelWidth: '120px',

            }
        },
        inject: ['reload'], //注入
        methods:{
            /*将当前勾选值赋值出去*/
            handleSelectionChange(val) {
                // 获取到的勾选值
                this.multipleSelection = val;
                let list = []
                this.multipleSelection.forEach((res)=>{
                    list.push(res.authId)
                })
                this.ids = list
            },
            /*删除更多数据*/
            moreDel(value){
                const _that = this;
                this.$axios.post('/authority/deleteAuthority', {ids:this.ids}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    this.query();
                })
            },
            /*删除单个数据*/
            del(value){
                const _that = this;
                this.$axios.post('/authority/deleteAuthority', {authId:value}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.reload();
                })
             },
            query(){
                const _that = this;
                this.$axios.post('/authority/queryAllAuthority', _that.pageInfo).then(function (response) {
                    console.log(response.data.data.records)
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                    if (_that.pageInfo.total <= 10){
                        _that.value = true;
                    }
                })
            },
            submitForm(value){
               const _that = this;
               this.$axios.post('/authority/updateAuthority', _that.form).then(function (response) {
                   _that.$message({
                       showClose: true,
                       message: response.data.data,
                       type: "success",
                   });
                   _that.dialogFormVisible = false;
                   _that.reload();
               })
            },
            update(value) {
                this.form.permission = value.permission;
                this.form.permissionSign = value.permissionSign;
                this.form.authId = value.authId;
                this.dialogFormVisible = true;
            },
            add(value){
                this.form.authId = '';
                this.form.permission = '';
                this.form.permissionSign = '';
                this.dialogFormVisible = true;
            }
        },
        created() {
            this.query();
        }
    }
</script>

<style scoped>
    .el-input {
        width: 80%;
        text-align: center;
    }

</style>