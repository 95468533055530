import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
import axios from './axios'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import Print from 'vue-print-nb'

// 全局注册
import mavonEditor from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
// use
Vue.use(mavonEditor)
// 跨域携带cookies
axios.defaults.withCredentials=true;
Vue.use(Print);
Vue.use(ElementUI, { locale })

Vue.prototype.$axios= axios;
Vue.config.productionTip = false
Vue.use(Element)

// highlight.js代码高亮插件
import Highlight from './util/highlight';
Vue.use(Highlight);

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
