<template>
    <div>
    <el-table
            show-header
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange">
        <el-table-column
                type="selection"
                width="55">
        </el-table-column>
        <el-table-column prop="userName" label="姓名" width="140">
        </el-table-column>
        <el-table-column prop="email" label="邮箱" width="120">
        </el-table-column>
        <el-table-column prop="image" label="头像地址" width="130">
        </el-table-column>
        <el-table-column prop="loginState" label="登录状态" width="130">
            <template slot-scope="scope">
                <span v-if="scope.row.loginState==0">离线</span>
                <span v-if="scope.row.loginState==1">在线</span>
            </template>
        </el-table-column>
        <el-table-column prop="state" label="账号状态" width="130">
            <template slot-scope="scope">
                <span v-if="scope.row.state==0">正常</span>
                <span v-if="scope.row.state==-1">异常</span>
            </template>
        </el-table-column>
        <el-table-column prop="loginTime" label="登录时间" width="180">
        </el-table-column>
        <el-table-column prop="createdTime" label="账号创建时间" width="180">
        </el-table-column>
    </el-table>

        <el-tabs type="border-card" style="margin-top: 20px;width: 80%;">
            <el-tab-pane label="发送用户邮箱">
                <div style="margin-top: 20px;width: 80%;height: 80%">
                <!--<el-button @click="toggleSelection([tableData[1], tableData[2]])">切换第二、第三行的选中状态</el-button>-->
                <!--活动表单-->
                <!--发送用户邮件-->
                <el-form ref="form" :model="form" label-width="80px">
                    <el-form-item label="邮件内容" >
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="sendEmail">立刻发送</el-button>
                        <el-button @click="toggleSelection()">取消选择</el-button>
                    </el-form-item>
                </el-form>
            </div>
            </el-tab-pane>
            <el-tab-pane label="发送指定邮件">
                <div style="margin-top: 20px;width: 80%;height: 80%">
                <!--发送指定邮件-->
                <el-form :label-position="labelPosition" label-width="80px" :model="formLabelAlign">
                    <el-form-item label="接收者名称">
                        <el-input v-model="formLabelAlign.receiveName"></el-input>
                    </el-form-item>
                    <el-form-item label="接收邮箱">
                        <el-input v-model="formLabelAlign.singleEmail"></el-input>
                    </el-form-item>
                    <el-form-item label="发送内容">
                        <el-input type="textarea" v-model="formLabelAlign.message"></el-input>
                    </el-form-item>
                    <el-button type="primary" @click="appointEmail">立刻发送</el-button>
                </el-form>
                </div>
            </el-tab-pane>
        </el-tabs>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                form: {
                    desc: ''
                },
                labelPosition: 'top',
                formLabelAlign: {
                    receiveName: '',
                    singleEmail: '',
                    message: ''
                },
                tableData:{
                    /*id:'',
                    userName:'',
                    image:'',
                    password:'',
                    email:'',
                    loginState: '',
                    state: '',
                    loginTime: '',
                    createdTime: '',
                    updateUser: '',
                    updateTime: ''*/
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total:''
                },
                multipleSelection: [],
                ids:[],
                message:''
            }
        },

        methods: {
            handleSelectionChange(val) {
                // 获取到的勾选值
                this.multipleSelection = val;
                let list = []
                this.multipleSelection.forEach((res)=>{
                    list.push(res.id)
                })
                this.ids = list

            },
            query() {
                const _that = this;
                this.$axios.post('/account/queryAllInfo', _that.pageInfo).then(function (response) {
                    /*response.data.data.records.createdTime = this.timeFormat(response.data.data.records.createdTime)*/
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                })
            },
            toggleSelection(rows) {
                if (rows) {
                    rows.forEach(row => {
                        this.$refs.multipleTable.toggleRowSelection(row);
                    });
                } else {
                    this.$refs.multipleTable.clearSelection();
                }
            },
            /*发送指定邮箱*/
            appointEmail(){
                const _that = this;
                this.$axios.post('/pushInfo/pushEmailInfo', {
                    receiveName:_that.formLabelAlign.receiveName,
                    message: _that.formLabelAlign.message,
                    singleEmail:_that.formLabelAlign.singleEmail,
                pushName:localStorage.getItem("userName")},{
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                }).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.reload();
                })
            },
            /*批量发送邮箱*/
            sendEmail() {
                const _that = this;
                this.$axios.post('/pushInfo/pushEmailInfo', {ids:_that.ids, message: _that.form.desc, pushName:localStorage.getItem("userName")},
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _this.reload();
                })
            },
        },
        created() {
          this.query();
        }
    }
</script>