<template>
  <div id="app">

    <transition name="el-fade-in-linear">
    <router-view v-if="isRouterAlive" ></router-view>
    </transition>
    <el-backtop class="scrolldIV"></el-backtop>

    <Footer></Footer>
  </div>
</template>
<script>
  import Header from "./components/Header";
  import Footer from "./components/Footer";
  export default {
    name: 'App',
    components: {Footer,Header},
    provide () {    //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
      return {
        /*刷新当前页面*/
        reload: this.reload
      }
    },
    data() {
      return{
        isRouterAlive: true                    //控制视图是否显示的变量
      }
    },
    methods: {
      reload () {
        this.isRouterAlive = false;            //先关闭，
        this.$nextTick(function () {
          this.isRouterAlive = true;
          //再打开
        })
      }
    }
  }
</script>


<style>

  #app{
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    min-height: auto;
    max-width: 2500px;
    padding: 0px;
    border: 0px;
   /* min-width: 1350px;*/
    background: #ffffff;
   /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1)*/
  }


  a {
    text-decoration: none;
  }
  img {
    -webkit-user-drag: none;
    /*如果开启指针事件图片将无法保存*/
    pointer-events:none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
    user-select: none;
  }

  ::selection{
    /*background-color:#84ca7f;*/
    background-color: #9b7db9;
    color:#000;
  }

  ::-webkit-selection{
    background-color: #9b7db9;
    color:#000;
  }
  ::-moz-selection{
    background-color: #9b7db9;
    color:#000;
  }

  ::-webkit-scrollbar {
       width: 10px;
       height: 10px;
  }

  ::-webkit-scrollbar-track {
    width: 6px;
    background: rgba(#101F1C, 0.1);
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(144,147,153,.5);
    background-clip: padding-box;
    min-height: 28px;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    transition: background-color .3s;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: rgba(144,147,153,.3);
  }

  @media screen and (max-width:867px){

    .el-menu-item:focus, .el-menu-item:hover{
      background-color: white;
    }

    .el-menu-item.is-active {
      background-color: rgb(246, 245, 233) !important;
    }

    #app{
      width:100%;
      min-height: 1350px;
      border:0px;
      padding:0px;
      /* position: fixed; */
      /* visibility: hidden; */
    }
  }


</style>
