<template>
    <div>
        <el-scrollbar>
        <el-table :data="tableData"
                  @selection-change="handleSelectionChange"
        >
            <el-table-column
                    type="selection"
                    width="55">
            </el-table-column>
            <el-table-column prop="userName" label="姓名" width="140">
            </el-table-column>
            <el-table-column prop="email" label="邮箱" width="120">
            </el-table-column>
            <el-table-column prop="image" label="头像地址" width="130">
            </el-table-column>
            <el-table-column prop="loginState" label="登录状态" width="130">
                <template slot-scope="scope">
                    <span v-if="scope.row.loginState==0">离线</span>
                    <span v-if="scope.row.loginState==1">在线</span>
                </template>
            </el-table-column>
            <el-table-column prop="state" label="账号状态" width="130">
                <template slot-scope="scope">
                    <span v-if="scope.row.state==0">正常</span>
                    <span v-if="scope.row.state==-1">异常</span>
                </template>
            </el-table-column>
            <el-table-column prop="loginTime" label="登录时间" width="180">
            </el-table-column>
            <el-table-column prop="createdTime" label="账号创建时间" width="180">
            </el-table-column>
            <!--<el-table-column prop="updateUser" label="更新人" width="130">
            </el-table-column>
            <el-table-column prop="updatedTime" label="更新时间" width="180">
            </el-table-column>-->
            <el-table-column prop="id" label="更新" width="180">
                <template slot-scope="scope">
                    <el-popover
                            placement="top"
                            width="160"
                            v-model="visible">
                        <p>你确定删除该用户信息吗？</p>
                        <div style="text-align: right; margin: 0">
                            <el-button size="mini" type="success" @click="moreDel(scope.row.id)">确认</el-button>
                        </div>
                        <el-button slot="reference" size="mini" type="danger" >删除</el-button>
                    </el-popover>
                    &nbsp;&nbsp;
                    <el-button size="mini" type="primary" @click="update(scope.row)">修改</el-button>
                </template>
            </el-table-column>


        </el-table>
        </el-scrollbar>

        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>



            <el-dialog title="更新" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="用户名" :label-width="formLabelWidth" prop="permission" >
                        <el-input v-model="form.userName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮箱" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.email" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="密码" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input type="password" v-model="form.password" placeholder="修改密码时改动" autocomplete="off"></el-input>
                    </el-form-item>
                    <div class="selectTest">
                        <el-form-item label="登录状态">
                            <el-select v-model="form.loginStateCopy" :label-width="formLabelWidth"  placeholder="修改账号状态">
                                <el-option label="在线" value="1"></el-option>
                                <el-option label="下线" value="0"></el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="selectTest">
                    <el-form-item label="账号状态">
                        <el-select v-model="form.stateCopy" :label-width="formLabelWidth"  placeholder="修改账号状态">
                            <el-option label="正常" value="0"></el-option>
                            <el-option label="异常" value="-1"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitForm('form')">确认更新</el-button>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>


    </div>


</template>

<script>
    export default {
        name: "AccountInfo",
        data() {
            return {
                dialogFormVisible: false,
                tableData:{
                    id:'',
                    userName:'',
                    image:'',
                    password:'',
                    email:'',
                    loginState: '',
                    state: '',
                    loginTime: '',
                    createdTime: '',
                    updateUser: '',
                    updateTime: ''
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total:''
                },
                form: {
                    id:'',
                    userName:'',
                    email:'',
                    loginState:'',
                    loginStateCopy:'',
                    state:'',
                    stateCopy:'',
                    password:''
                },
                ids:[],
                /*修改模态框宽度*/
                formLabelWidth: '120px',
            }
        },
        methods:{
            /*将当前勾选值赋值出去*/
            handleSelectionChange(val) {
                // 获取到的勾选值
                this.multipleSelection = val;
                let list = []
                this.multipleSelection.forEach((res)=>{
                    list.push(res.authId)
                })
                this.ids = list
            },
            /*删除更多数据*/
            moreDel(value){
                if (this.ids == null || this.ids == ''){
                    this.ids.push(value);
                }
                const _that = this;
                this.$axios.post('/account/deleteUser', {ids:this.ids}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    this.query();
                })
            },
            /*提交用户更新信息*/
            submitForm(value){
                if (this.form.loginStateCopy == 1 || this.form.loginStateCopy == 0){
                    this.form.loginState = this.form.loginStateCopy;
                }
                if (this.form.stateCopy == 0 || this.form.stateCopy == -1){
                    this.form.state = this.form.stateCopy;
                }
                const _that = this;
                this.$axios.post('/account/updateUser', _that.form).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.dialogFormVisible = false;
                    _that.reload();
                })
            },
            /*更新修改模态框里面的内容*/
            update(value) {
                this.form.userName = value.userName;
                this.form.email = value.email;
                this.form.loginState = value.loginState;
                this.form.state = value.state;
                this.form.id = value.id;
                if (this.form.loginState == 0){
                    this.form.loginStateCopy = '离线';
                }else {
                    this.form.loginStateCopy = '在线';
                }
                if (this.form.state == 0){
                    this.form.stateCopy = "正常";
                } else {
                    this.form.stateCopy = '异常';
                }
                this.dialogFormVisible = true;
            },
            timeFormat(data) {
                return data.replace("T", " ");
            },
            query(){
                const _that = this;
                this.$axios.post('/account/queryAllInfo', _that.pageInfo).then(function (response) {
                    /*response.data.data.records.createdTime = this.timeFormat(response.data.data.records.createdTime)*/
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                })
            }
        },
        // 计算属性
        computed:{
            //改变备注的长度，长度大于14位就用...代替剩余内容
            changeRemarkLength(){
                //text就是所传参数
                return function (text) {
                    if(text.length > 14){
                        return text.slice(0,14)+"...";
                    }else{
                        return text;
                    }
                }
            }},
        created() {
            this.query();
        }
    }
</script>

<style scoped>

    .selectTest{
        margin-left: 13%;
    }
</style>