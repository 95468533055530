<template>
    <div>
        <el-scrollbar>
            <el-button size="mini" type="success" @click="add()">添加请求</el-button>
            <el-table
                    show-header
                    ref="multipleTable"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :data="tableData"
                    :key="Math.random()">
                <el-table-column prop="title" label="请求标题" width="140">
                </el-table-column>
                <el-table-column prop="requestMsg" label="请求内容" width="120">
                </el-table-column>
                <el-table-column prop="requestUser" label="请求用户" width="120">
                </el-table-column>
                <el-table-column prop="requestTime" label="请求时间" width="180">
                </el-table-column>
            </el-table>
        </el-scrollbar>

        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>

        <div class="search">
            <el-dialog title="发送请求" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="请求主题" :label-width="formLabelWidth" prop="permission" >
                        <el-input v-model="form.title" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="请求内容" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.requestMsg" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitForm('form')">确认更新</el-button>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Feedback",
        data(){
            return{
                form: {
                    title:'',
                    requestMsg:'',
                    requestUser:'',
                    userId: ''
                },
                dialogFormVisible: false,
                tableData:{
                    requestTate: 0,
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total: '',
                },
                userId: '',
                languageList:[
                    {
                        value:'未处理',
                        key:0
                    },
                    {
                        value:'已处理',
                        key:1
                    }
                ],
            }
        },
        methods:{
            query(){
                const _that = this;
                this.pageInfo.userId = localStorage.getItem("id");
                this.$axios.post('account_request/query_user_request', {
                    userId:this.pageInfo.userId,
                    currentPage:this.pageInfo.currentPage,
                    showPage:this.pageInfo.showPage
                }).then(function (response) {
                    console.log(response.data.data.records)
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                    if (_that.pageInfo.total <= 5){
                        _that.value = true;
                    }
                })
            },
            add(){
                this.dialogFormVisible = true;
            },
            submitForm(value){
                const _that = this;
                this.form.userId = localStorage.getItem("id")
                this.form.requestUser = localStorage.getItem("userName")
                this.$axios.post('/account_request/update_user_request', _that.form).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.dialogFormVisible = false;
                    _that.reload();
                })
            },
        },
        created() {
            this.query()
        }
    }
</script>

<style scoped>

</style>