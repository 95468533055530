<template>
    <div class="allShow">
        <Header></Header>
        <div class="block change">
            <el-carousel height="370px" interval="3000">
                <div v-for="txt in change">
                    <el-carousel-item :key="txt.id">
                        <div class="carousel" @click="showImage(txt.id)">
                            <img
                                    :src="txt.titleImage"
                            />
                        </div>
                    </el-carousel-item>
                    <!--<div class="myTitle">{{txt.title}}</div>-->
                </div>
            </el-carousel>
        </div>
        <div class="wrapper" v-loading="loading">
            <div class="avigation">
                <el-breadcrumb separator="/">
                    <el-badge value="new" class="item">
                        <el-button style="margin-left: 30px" type="success" size="mini" round
                                   @click="changeNavigation(2)">最新
                        </el-button>
                    </el-badge>
                    <el-badge value="hot" class="item">
                        <el-button type="danger" size="mini" round @click="changeNavigation(1)">热度</el-button>
                    </el-badge>
                    <el-button style="margin-left: 30px" type="primary" size="mini" round @click="changeNavigation(3)">
                        顺序
                    </el-button>
                    <el-badge class="item">
                        <el-button type="warning" size="mini" round v-html="changeInfo"></el-button>
                    </el-badge>
                </el-breadcrumb>
            </div>
            <div class="content">
                <div class="mycard" @click="detail(blog.id, blog.userId)" v-for="(blog, index) in blogs" :key="index">
                    <div v-show="blog.titleImage != null">
                        <div class="mycard_left"><img :src="blog.titleImage"/></div>
                    </div>
                    <div v-show="blog.titleImage == null || blog.titleImage == ''">
                        <img src="https://www.arien.top/image/02.jpg"/>
                    </div>
                    <div class="mycard_right" @click="detail(blog.id, blog.userId)">
                        <div class="mycard_right_title">
                            <!--<router-link
                              :to="{ name: 'blogDetail', params: { blogId: blog.id, currentUserId:blog.userId } }"
                              >--><span class="el-icon-apple">{{
                  blog.title
                }}</span><!--</router-link>-->
                        </div>
                        <div class="mycard_right_content">
                            <p>摘要:{{blog.detail|ellipsis}}</p>
                            <p style="border-radius:2px;color: #ed6f26;font-weight: bold;line-height: 3px;">
                                作者:{{blog.updatedUser}}</p>
                        </div>

                        <div
                                v-show="blog.ariticle != null && blog.ariticle != ''"
                                class="blogstype"
                        >
                            <div class="tagIndex"><p>{{ blog.ariticle }}</p></div>
                            <!--<el-tag type="primary" size="mini">{{ blog.ariticle }}</el-tag>-->
                        </div>
                        <div class="mycard_date">
                            <i class="el-icon-chat-dot-square"></i>{{blog.plateId}}
                            <i class="el-icon-view"></i>{{blog.pageView}}
                            <i class="el-icon-alarm-clock"></i>{{ blog.createdTime }}
                        </div>
                    </div>
                </div>

                <!--分页-->
                <el-pagination
                        class="mpage"
                        :hide-on-single-page="value"
                        background
                        pager-count="7"
                        layout="prev, pager, next"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        :total="total"
                        @current-change="page"
                >
                </el-pagination>
            </div>

            <div class="mydate">
                <h1 align="center" class="el-icon-cloudy-and-sunny
          ">标签云</h1>
                <div class="search">
                    <el-tag class="myTag" v-for="(item,index) in article" @click="searchArticle(item)" :key="index"
                            :type="state">{{item}}
                    </el-tag>
                    <!--v-if="index%2 != 0?state = 'warning':state = 'success'"-->
                </div>
            </div>

            <div class="mydate">
                <h1 align="center" class="el-icon-water-cup
          ">关于</h1>
                <div class="blog">
                    <!--https://www.arien.top/image/title/arien20210509172646.jpg-->
                    <el-avatar :size="100" src="https://www.arien.top/image/logo/logoarien20210419124502.jpg"></el-avatar>
                    <h3 align="center">Arien</h3>
                </div>
                <p style="font-weight: bold;color: #357d6c">
                    <strong style="color: #eca95e">关于本站：</strong>本站点于2020年1月份开始迭代更新，之后参与阿里云高校计划拿到了半年免费服务器，但是到期后我没有对数据库进行备份，所以
                    实例释放后我之前写的博客数据全没了💔，在今年又重构了这个网站......<a href="/blogDetail?shareId=22">查看更多</a>
                </p>
                <p style="font-weight: bold;color: #357d6c">
                    <strong style="color: #eca95e">关于我：</strong>自我介绍有点烦，好像没什么好说的😂......<a
                        href="/blogDetail?shareId=21">查看更多</a>
                </p>
            </div>


            <div class="mydate">
                <!--<h1 class="el-icon-chat-line-square">热门评论</h1>
                <el-collapse accordion>
                  <el-collapse-item v-for="(leave, index) in leaves" :key="index">
                    <template slot="title">
                      <div class="mydate_right el-icon-loading">{{ leave.lmessage }}</div>
                    </template>
                    <div class="mydate_on" >
                     <i class="el-icon-loading"></i> 内容:{{ leave.lmessage }}
                    </div>
                  </el-collapse-item>
                </el-collapse>-->
                <h1 align="center" class="el-icon-magic-stick">热门文章</h1>
                <el-collapse accordion>
                    <el-collapse-item v-for="(blog, index) in hots" :key="index">
                        <template slot="title">
                            <div class="mydate_right el-icon-medal">{{ blog.title }}</div>
                        </template>
                        <div class="mydate_on el-icon-date" @click="detail(blog.id, blog.userId)">
                            概述:{{blog.detail|ellipsis}}
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </div>

            <div class="mydate">
                <h1 align="center" class="el-icon-s-opportunity
          ">创作者排行</h1>
                <!--<el-collapse accordion style="height: 40px">
                  <el-collapse-item v-for="(user, index) in users" :key="index">
                    <template slot="title">
                      <div class="mydate_right">-->
                <div v-for="(user, index) in users" :key="index">
                    <div class="myAuthor <!--el-icon-user-solid-->" @click="userInfo(user.id)">
                        <!--头像信息-->
                        <el-badge :value="index+1" class="item" type="warning">
                            <el-avatar :size="30" :src="user.image"></el-avatar>
                        </el-badge>
                        <div class="userName">{{user.userName}}</div>
                    </div>
                </div>
            </div>
            <!-- </template>
             <div class="mydate_on el-icon-date" @click="userInfo(user.id)">
               用户:{{ user.userName }}
             </div>
           </el-collapse-item>
         </el-collapse>-->
        </div>


        <!--展示show图-->
        <div class="mydate">
            <!--<el-carousel height="300px" direction="vertical" :autoplay="false">
              <el-carousel-item :key="1">
                <div class="carousel">
                  <img src="https://www.arien.top/image/AD/001.jpg" />
                </div>
              </el-carousel-item>
              <el-carousel-item :key="2">
                <div class="carousel">
                  <img src="https://www.arien.top/image/AD/002.jpg" />
                </div>
              </el-carousel-item>
              <el-carousel-item :key="3">
                <div class="carousel">
                  <img src="https://www.arien.top/image/AD/002.jpg" />
                </div>
              </el-carousel-item>
            </el-carousel>-->
            <h1 class="el-icon-chat-line-square">最新评论</h1>
            <el-collapse accordion>
                <el-collapse-item v-for="(leave, index) in leaves" :key="index">
                    <template slot="title">
                        <div class="mydate_right2 el-icon-loading" v-html="leave.lmessage"></div>
                    </template>
                    <div class="mydate_on">
                        <i class="el-icon-loading"></i><div style="color: #310d32;font-weight: bold"> 内容:</div><strong v-html="leave.lmessage"></strong>
                    </div>
                </el-collapse-item>
            </el-collapse>
        </div>
<!--      <Footer></Footer>-->
    </div>

</template>

<style>
    body {
        margin: 0;
    }
</style>

<script>
    import {mapState} from 'vuex';
    import Header from "../components/Header";
    import Footer from "../components/Footer";

    export default {
        filters: {
            ellipsis(value) {
                if (!value) return ''
                if (value.length > 20) {
                    return value.slice(0, 20) + '...'
                }
                return value
            }
        },
        name: "Blogs",
        components: {Footer, Header},
        data() {
            return {
                change: {},
                value: new Date(),
                blogs: {},
                hots: {},
                users: {},
                leaves: {},
                article: [],
                typeInfo: 2,
                changeInfo: '',
                //分页信息
                currentPage: 1,
                total: 1,
                pageSize: 8,
                loading: true
            };
        },
        methods: {
            showImage(value) {
                localStorage.setItem("BLOGID", value)
                this.$router.push({path: "/blogDetail"})
            },
            userInfo(id) {
                localStorage.setItem("leaveUserId", id)
                this.$router.push({path: "/userInfo"})
            },
            page(currentPage, type) {
                type = localStorage.getItem("infoType")
                const _this = this;
                _this.$axios.post("/javaBlogs/list_select_type", {
                    currentPage: currentPage,
                    showPage: 8,
                    typeInfo: type
                }).then((res) => {
                    _this.blogs = res.data.data.records;
                    //_this.currentPage = res.data.data.current
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                    _this.loading = false
                    window.scrollTo(0, 0)
                });
            },
            getHeight() {
                const _this = this;
                _this.$axios.get("/javaBlogs/getHot").then((res) => {
                    _this.hots = res.data.data.records;
                    //_this.currentPage = res.data.data.current
                    _this.total = res.data.data.total;
                    _this.pageSize = res.data.data.size;
                });
            },
            detail(id, userId) {
                /*console.log(JSON.stringify(id+"数据"+userId))*/
                localStorage.setItem("BLOGID", id)
                localStorage.setItem("CUURENTSERID", userId)
                this.$store.commit('SET_BLOGID', id)
                this.$store.commit('SET_CURRENTUSERID', userId)
                this.$router.push({path: "/blogDetail"})
            },
            //获取主留言分页信息
            leave() {
                //alert(currentPage)
                const _this = this;
                this.$axios.get('/leave/getLeave?currentPage=' + 1).then(function (res) {
                    //console.log(res)
                    _this.leaves = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                })
            },
            changeBlog() {
                const _this = this;
                _this.$axios.get("/javaBlogs/change").then((res) => {
                    _this.change = res.data.data;
                });
            },
            //获取所有用户信息
            user() {
                const _this = this;
                this.$axios.get('/javaBlogs/ranking').then(function (res) {
                    _this.users = res.data.data
                })
            },
            changeNavigation(type) {
                localStorage.setItem("infoType", type)
                this.typeInfo = type;
                this.currentPage = 1;
                this.page(1, this.typeInfo)
                this.changeTag()
            },
            searchArticle(item) {
                const _this = this;
                _this.$axios.get("/javaBlogs/searchArticle?currentPage=" + _this.currentPage + "&article=" + item).then(res => {
                    _this.blogs = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                })
            },
            queryAll() {
                const _this = this;
                _this.$axios.get("/javaBlogs/query_all_article").then(res => {
                    _this.article = res.data.data
                })
            },
            changeTag() {
                const info = localStorage.getItem("infoType");
                if (info == 1) {
                    this.changeInfo = "<strong style='color: #ffffff'>热门</strong>"
                } else if (info == 2) {
                    this.changeInfo = "<strong style='color: #ffffff'>最新</strong>"
                } else {
                    this.changeInfo = "<strong style='color: #ffffff'>顺序</strong>"
                }
            }
        },
        computed: {
            ...mapState({})
        },
        created() {
            this.changeBlog();
            this.page(1, this.typeInfo);
            this.user();
            this.queryAll()
            this.getHeight();
            this.leave();
            this.changeTag();
        },
    };
</script>

<style scoped>
    .allShow {
        width: 80%;
        margin: 0 auto;
        min-height: 1200px;
    }

    /*.myTitle {
        background-color: #42b983;
        width: 100%;
        font-weight: bold;
        text-align: center;
        font-size: 50px;
        margin-top: 130px;
        z-index: 999;
        position: absolute;
    }*/

    .mpage {
        margin: 10px auto;
        text-align: center;
    }

    .content {
        float: left;
        width: 68%;
        min-width: 500px;
        min-height: 1500px;
    }

    .myAuthor {
        width: 90%;
        height: 30px;
        float: left;
        /*text-align: center;*/
        margin-top: 12px;
        margin-left: 10px;
        /*margin-right: 10px;*/
        border-radius: 8px;
        font-weight: bolder;
        line-height: 24px;
        color: #ffffff;
        /* background-color: #e2c216;*/
        background-image: linear-gradient(to right, #3587c6, #be7ce2);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        padding: 1px 3px;
    }

    .userName {
        float: right;
        margin: 6px auto;
    }

    .tagIndex {
        width: 100%;
    }

    .blogstype > .tagIndex > p {
        background-color: #9c53ce;
        border-radius: 4px;
        color: white;
        width: auto;
        line-height: 18px;
        padding: 2px;
        border: 1px solid black;
    }

    .mydate {
        float: right;
        margin-right: 0px;
        margin-top: 70px;
        width: 300px;
        height: auto;
        /* border: 2px solid #336699;*/
        overflow: hidden;
        border-radius: 10px;
        padding: 10px 3px;
        font-size: 18px;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }

    /*标签*/
    >>> .el-tag {
        background-color: #9258e5;
        border-color: #1d403a;
        display: inline-block;
        height: 32px;
        padding: 0 10px;
        line-height: 30px;
        font-size: 12px;
        color: #ffffff;
        border-width: 1px;
        border-style: solid;
        border-radius: 4px;
        box-sizing: border-box;
        white-space: nowrap;
        margin: 4px 3px;
        font-weight: bold;
    }

    .myad {
        float: right;
        margin-right: 10px;
        margin-top: 70px;
        width: 300px;
        height: 300px;
        /* border: 2px solid #336699;*/
        overflow: hidden;
        border-radius: 10px;
        padding: 0px 0px;
        font-size: 18px;
        background: white;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }

    .mydate_right {
        color: #522b7d;
        font-size: 20px;
        font-weight: bold;
    }

    .mydate_right2 {
        color: #522b7d;
        font-size: 20px;
        font-weight: bold;
    }

    .mydate_on {
        color: brown;
        font-size: 18px;
    }

    .change {
        width: 100%;
        height: 350px;
        margin-top: 80px;
        border-radius: 10px;
        overflow: hidden;
    }

    .mycard {
        width: 100%;
        height: 210px;
        border-radius: 20px;
        background: white;
        margin: 50px 0px 50px 0px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        transform: scale(1);
        transition: all 1s ease 0s;
    }

    .mycard:hover {
        transform: scale(1.06);
        transition: all 1s ease 0s;
    }

    .mycard_left {
        float: left;
        width: 40%;
        min-width: 150px;
        height: 230px;
    }

    .mycard_left > img {
        width: 100%;
        height: 100%;
    }

    .mycard_right {
        float: left;
        height: 200px;
        width: 60%;
        border-radius: 10px;
    }

    .mycard_right_title {
        font-size: 20px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: bold;
        text-decoration: none;
    }

    .mycard_right_title > span {
        /*color: #ea7613;*/
        /*color: rgba(42, 141, 112, 0.98);*/
        color: white;
        font-weight: bold;
        background-image: linear-gradient(to right, #8831dc, #ec4040);
        padding: 2px;
        border-radius: 5px;
    }

    a {
        text-decoration: none;
    }

    .mycard_right_content {
        font-size: 17px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: lighter;
        padding: 3px;
        font-family: 华光综艺_CNKI;
    }

    .mycard_date {
        float: right;
        height: 15px;
        width: 250px;
        /*margin: 30px 1px 10px 0px;*/
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: bold;
        line-height: 3px;
        color: #1d3547;
    }

    .mycard_date>i {
        margin-left: 3px;
    }

    .carousel {
        width: 100%;
        height: 370px;
        overflow: hidden;
        border-radius: 10px;
        /*filter: blur(6px);*/
    }

    .carousel > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        position: relative;
        border-radius: 10px;
    }

    /*去掉加载的图标*/
    >>> .el-icon-loading:before {
        content: "";
    }

    .el-icon-loading {
        -webkit-animation: rotating 2s linear infinite;
        animation: rotating 6s linear infinite;
    }

    /*img {
      pointer-events:none;
      border-radius: 10px;
    }*/
    /*AD走马灯*/
    /* .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 200px;
            margin: 0;
        }*/

    .blogstype {
        float: left;
        height: 38px;
        width: auto;
        margin-left: 17px;
        border-radius: 8px;
        margin-left: 22px;
    }

    .avigation {
        margin-top: 20px;
    }

    >>> .el-button--mini, .el-button--mini.is-round {
        margin-left: 30px;
        padding: 7px 15px;
    }

    /*博主*/
    .blog {
        width: 100px;
        margin: 2px auto;
    }

    >>> .el-button--warning {
        color: #ffffff;
        background-image: linear-gradient(to right, #e83b97, #8c71ef);
        /*border: 2px solid #11af99;*/
        background-color: #9f3ce6;
        border-color: #c13ce6;
    }

    >>> .el-button--warning:hover {
        color: #ffffff;
        background-image: linear-gradient(to right, #66e8c3, #5d35ef);
    }

    a {
        color: #575590;
    }

    /*>>> .el-dropdown {
        display: inline-block;
        position: relative;
        color: #606266;
        font-size: 14px;
        display: none;
    }*/


    @media screen and (max-width: 867px) {

        .mycard_right_title > span {
            color: #ea7613;
            color: rgba(42, 141, 112, 0.98);
            /*color: white;*/
            font-weight: bold;
            background-image: linear-gradient(to right, #ffffff, #ffffff);
            padding: 2px;
            border-radius: 5px;
        }

        >>> .el-tag {
            background-color: #9258e5;
            border-color: #1d403a;
            display: inline-block;
            height: 20px;
            padding: 0 10px;
            line-height: 18px;
            font-size: 12px;
            color: #fff;
            border-width: 1px;
            border-style: solid;
            border-radius: 4px;
            box-sizing: border-box;
            white-space: nowrap;
            margin: 4px 3px;
            font-weight: 700;
            /*margin-left: -18px;*/
        }

        .blogstype {
            float: left;
            height: 25px;
            width: auto;
            margin-top: -14px;
            margin-left: 2px;
            border-radius: 8px;
            margin-bottom: 7px;
            font-weight: normal;
        }

        .blogstype > .tagIndex > p {
            background-color: #9c53ce;
            border-radius: 3px;
            color: white;
            width: auto;
            line-height: 12px;
            padding: 3px;
            border: 1px solid black;
        }

        >>> .el-dropdown {
            display: inline-block;
            position: relative;
            color: #606266;
            font-size: 14px;
            display: block !important;
        }

        >>> .el-carousel__item, .el-carousel__mask {
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
        }


        .allShow {
            width: 96%;
            /*min-width: 100%;*/
        }

        .content {
            width: 100%;
            min-width: 100%;
            line-height: 11px;
            float: left;
            min-height: auto;
        }

        .footer_top_left {
            width: 100%;
        }

        .mycard {
            margin: 18px auto;
            width: 98%;
            height: 160px;
            overflow: hidden;
        }

        .mycard_right {
            width: 56%;
            height: 100%;
            z-index: 999;
            margin: 0 0 0 5px;
            background-color: #fff;
            opacity: 0.78;
            /*background: rgba(255, 248, 241, 0.31); !* Green background with 30% opacity *!*/
            float: right;
            /*filter: blur(1px);*/
        }

        .mycard_left {
            float: left;
            width: 0%;
            min-width: 100px;
            height: 230px;
        }

        .carousel {
            width: 100%;
            height: 370px;
            border-radius: 10px !important;;
            overflow: hidden !important;;
        }

        .carousel > img {
            height: 100%;
            width: 100%;
        }

        /*  .mycard_left {
            float: left;
            width: 35%;
            min-width: 150px;
            height: 230px;
          }*/
        .carousel > img {
            height: 100%;
            width: 100%;
        }

        .mycard_right_title {
            font-size: 16px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            text-decoration: none;
            line-height: 8px;
        }

        .mycard_right_content {
            font-size: 13px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            line-height: 15px;
            padding: 0px;
            color: #586b85;
        }

        .mycard_date {
            float: right;
            height: auto;
            width: 100%;
            margin-bottom: 0;
            margin-right: 0;
            font-size: 10px;
            margin-top: 8px;
            font-weight: bold;
        }

        >>> .el-button--mini {
            margin-left: 16px;
            padding: 7px 15px;
        }

        >>> .el-menu-item [class^=el-icon-] {
            margin-right: 5px;
            width: 2px;
            text-align: center;
            font-size: 25px;
            vertical-align: middle;
        }

        .mydate {
            float: right;
            /*margin-right: 28px;
            margin-top: 70px;*/
            width: 98%;
            min-width: 300px;
            height: auto;
            /* border: 2px solid #336699;*/
            overflow: hidden;
            border-radius: 10px;
            padding: 10px 3px;
            font-size: 18px;
            background: white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            transform: scale(1);
            transition: all 1s ease 0s;
        }


        .mycard_left > img {
            width: auto;
            height: 100%;
        }


    }

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: landscape) {

        .allShow {
            width: 95%;
            margin: 0 auto;
            min-height: 1200px;
        }

        .mycard {
            margin: 58px auto;
            width: 100%;
            height: 180px;
            overflow: hidden;
        }

        .content {
            width: 70%;
            min-height: 2100px;
            line-height: 11px;
            float: left;
            min-height: auto;
        }

        .mycard_date {
            float: right;
            height: auto;
            width: 50%;
            margin-bottom: 0;
            margin-right: 0;
            font-size: 10px;
            font-weight: bold;
        }


        .mydate {
            float: right;
            margin-right: 1px;
            margin-top: 70px;
            width: 265px;
            height: 380px;
            /* border: 2px solid #336699; */
            overflow: hidden;
            border-radius: 10px;
            padding: 10px 3px;
            font-size: 18px;
            background: white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }

    }

    /* ipad竖屏 */
    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: portrait) {
        /* style */
        .carousel > img {
            height: 100%;
            width: 100%;
        }

        .mycard_right {
            width: 50%;
            height: 100%;
            z-index: 999;
            margin: 0 0 0 5px;
            background-color: #fff;
        }
    }


</style>
