<template>
    <div class="all">
    <!--<div id="wrapper">-->
        <Header></Header>
        <div class="iMacContour"><img :src="iMac"></div>
            <div class="info">
                <GoBack></GoBack>
                <div v-for="(map, i) in info" :key="i">
                <div v-for="(value, key) in map" :key="key" >
                    <div class="hello" :key="key">
                        <h1 style="color: #000000;font-size: 36px">{{key}}<strong style="font-size: 24px;color: #875cb9">({{Object.keys(value).length}}篇)</strong></h1>
                        <!--手风琴-->
                        <el-collapse v-model= "activeName" accordion v-for="(v1,k1) in value" :key="k1">
                            <el-collapse-item>
                                <template slot="title">
                                    <!--具体日期-->
                                    <i class="el-icon-date"></i>{{k1}}(<strong style="color: #7f9bff">{{v1.length}}篇</strong>)
                                </template>
                                <div v-for="(v2, k2) in v1" :key="k2" >
                                    <el-tooltip content="点击查看文章" placement="bottom" effect="light">
                                        <a @click="pushDetail(v2.id)">
                                           <i class="el-icon-reading"></i> {{v2.title}}
                                        </a>
                                    </el-tooltip>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                        </div>
                    </div>
            </div>
            </div>

        <div @click="iMacYellow" class="change">
            <el-button type="warning">
                <i class="el-icon-mobile-phone"></i>换个心情
            </el-button></div>
<!--          <Footer></Footer>-->
        </div>

   <!-- </div>-->
</template>

<script>
    import Header from "../components/Header";
    import GoBack from "../components/GoBack";
    import Footer from "@/components/Footer.vue";

    export default {
        name: "Pigeonhole",
        components: {Footer, GoBack, Header},
        data() {
            return {
                info:{},
                activeName: '1',
                baseiMac: require("../assets/imgs/baseIMAC.png"),
                iMac: require("../assets/imgs/baseIMAC.png"),
                iMac2: require("../assets/imgs/iMac.png"),
                iMac3: require("../assets/imgs/iMac2.png"),
            }
        },
        methods: {
            page() {
                const _this = this;
                _this.$axios.post("/javaBlogs/classifyBlogs", {}).then((res) => {
                    _this.info = res.data.data;
                    console.log(_this.info)
                    //_this.currentPage = res.data.data.current
                    /*_this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                    _this.loading = false*/
                    window.scrollTo(0,0)
                });
            },
            pushDetail(data) {
                localStorage.setItem("BLOGID", data);
                this.$router.push({path:"/blogDetail"})
            },
            iMacYellow() {
                if (this.iMac == this.baseiMac) {
                    this.iMac = this.iMac2;
                } else if (this.iMac == this.iMac2) {
                    this.iMac = this.iMac3;
                } else if (this.iMac == this.iMac3) {
                    this.iMac = this.baseiMac
                } else {
                    this.iMac = localStorage.getItem("iMac")
                }
                localStorage.setItem("iMac", this.iMac)
            }
        },
        created() {
            this.page()
            if (localStorage.getItem("iMac") != '' && localStorage.getItem("iMac") != null) {
                this.iMac = localStorage.getItem("iMac")
            }
        }
    }
</script>

<style scoped>
    .all {
        min-height: 1200px;
        height: auto;
        width: 80%;
        margin: 100px auto;
        border-radius: 15px;
        background-image: linear-gradient(to bottom right, rgba(251, 253, 190, 0.87), #fc9ad0, #9c92f8);
        padding: 15px 1px;
    }

    >>>.idea {
        margin-top: 12px;
        margin-left: 5px;
        margin-bottom: 25px;
        width: 120px;
        height: 16px;
        padding: 3px 3px;
        float: right;
    }

    .iMacContour {
        width: 1000px;
        margin: 100px auto;
        position: relative;
        z-index: 2;
        pointer-events: none;
        overflow-y: auto;
    }

    .iMacContour> img {
        pointer-events: none;
    }

    .change {
        text-align: center;
    }

    >>>.el-button--warning.is-plain {
        color: white;
        background: #ffffff;
        border-color: #8476f5;
    }

    >>>.el-button--warning {
        color: #FFF;
        background-image: linear-gradient(to bottom right, #a696e8, #e0889c);
        border-color: #783be8;
    }

    >>>.el-button--warning {
        color: #FFF;
        background-color: #a48dfc !important;
        border-color: #a48dfc;
    }


    #wrapper {
        margin: 0 auto;
        with:100%;
        height: auto;
        min-width: 100%;
        min-height: 1000px;
        position:relative;
        /*background:url("https://www.arien.top/image/title/arien20210501201138.jpg") !*no-repeat fixed*!;*/
        padding:1px;
        box-sizing:border-box;
        z-index:1;
        border-radius: 13px;
        /*overflow: hidden;*/
    }

    #wrapper:after {
        content:"";
        with:100%;
        height: auto;
        min-width: 100%;
        min-height: 1100px;
        position:absolute;
        left:0;
        top:0;
        background:inherit;
        filter:blur(5px);
        z-index:2;
    }

    /*.special {
        width: 100%;
        height: 100%;
        background: url("https://www.arien.top/image/搜索背景.jpg");
        filter: blur(2px);
    }*/

    .info {
        /*position:absolute;*/
        /*left:55%;
        top:60%;*/
        margin:500px auto;
        /*transform:translate(-50%,-50%);*/
        width:930px;
        height: 530px;
        min-width: 350px;
        min-height: 20px;
        /*height:auto;*/
        /*text-align:center;*/
       /* border-radius: 8px;*/
        overflow: hidden;
       /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);*/
        border-radius: 13px;
        /*background: url("https://www.arien.top/image/title/arien20210509045818.png");*/
        top: 0%;
        left: 49.7%;
        position: absolute;
        transform:translate(-50%,-49.7%);/*50%为自身尺寸的一半*/
        z-index: 1;
        overflow-y: auto;
        float: left;
    }

    >>> .el-collapse-item__header {
        display: flex;
        align-items: center;
        height: 48px;
        line-height: 48px;
        background-color: #ffffff00;
        color: #417980;
        cursor: pointer;
        border-bottom: 0px solid #ebeef5db;
        font-size: 18px;
        font-weight: bold;
        transition: border-bottom-color .3s;
        outline: 0;
    }

    >>>.el-collapse-item__content {
        padding-bottom: 5px;
        font-size: 18px;
        color: #4d6593;
        font-weight: bold;
        line-height: 2;
        background-color: #fff6f6;
    }

    .hello {
        width: 100%;
        height: auto;
    }

    @media screen and (max-width:867px) {

        .all {
            height: auto;
            min-height: 800px;
            width: 100%;
            margin: 100px auto;
            border-radius: 15px;
            padding: 15px 1px;
            background-image: none;
        }

        .iMacContour {
            width: 1000px;
            margin: 100px auto;
            position: relative;
            z-index: 2;
            pointer-events: none;
            overflow-y: auto;
            display: none;
        }

        .change {
            display: none;
        }

        .info {
            /*position:absolute;*/
            /*left:55%;
            top:60%;*/
            margin:350px auto;
            /*transform:translate(-50%,-50%);*/
            width:80%;
            height: auto;
            min-width: 350px;
            min-height: 20px;
            /*height:auto;*/
            /*text-align:center;*/
            /* border-radius: 8px;*/
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            border-radius: 13px;
            /*background: url("https://www.arien.top/image/title/arien20210509045818.png");*/
            top: 30%;
            left: 49.7%;
            position: absolute;
            transform:translate(-50%,-49.7%);/*50%为自身尺寸的一半*/
            z-index: 1;
            overflow-y: auto;
            background-image: linear-gradient(to bottom right, rgba(251, 253, 190, 0.87), #fc9ad0, #9c92f8);
            float: left;
        }
    }

    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape){

        .all {
            min-height: 800px;
            height: auto;
            width: 100%;
            margin: 100px auto;
            border-radius: 15px;
            background-image: none;
            padding: 15px 1px;
        }

        .iMacContour {
            width: 800px;
            margin: 35px auto;
            position: relative;
            z-index: 2;
            pointer-events: none;
            overflow-y: auto;
           /* display: none;*/
        }

        .iMacContour>img {
            width: 800px;
        }

        .info {
            margin:150px auto;
            width:760px;
            height: 440px;
            min-width: 350px;
            min-height: 20px;
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            border-radius: 13px;
            top: 30%;
            left: 50%;
            position: absolute;
            transform:translate(-50%,-49.7%);/*50%为自身尺寸的一半*/
            z-index: 1;
            overflow-y: auto;
        }
    }
</style>