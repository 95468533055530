<template>
    <div>
        <el-scrollbar>
            <router-link :to="{ name: 'blogEdit'}"><el-button size="mini" type="success" >添加</el-button></router-link>
            <el-table
                    show-header
                    ref="multipleTable"
                    tooltip-effect="dark"
                    style="width: 100%"
                    :data="tableData"
                    :key="Math.random()">
                <el-table-column prop="title" label="标题" width="140">
                </el-table-column>
                <el-table-column prop="detail" label="描述" width="200">
                </el-table-column>
                <el-table-column prop="ariticle" label="标签" width="120">
                </el-table-column>
                <el-table-column prop="roleId"  label="模块" width="180">
                    <template slot-scope="scope">
                        <el-select disabled="false" v-model="scope.row.plateId" placeholder="用户角色" clearable>
                            <el-option
                                    v-for="l in languageList"
                                    :key="l.key"
                                    :label="l.value"
                                    :value="l.key">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" width="180">
                </el-table-column>
                <el-table-column prop="updatedUser" label="更新人" width="130">
                </el-table-column>
                <el-table-column prop="updatedTime" label="更新时间" width="180">
                </el-table-column>
                <el-table-column prop="id" label="删除" width="80">
                    <template slot-scope="scope">
                        <el-popover
                                placement="top"
                                width="160"
                                v-model="visible">
                            <p>你确定删除这篇文章吗？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="success"  @click="del(scope.row.id)">确认</el-button>
                            </div>
                            <el-button slot="reference" size="mini" type="danger">删除</el-button>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="authId" label="更新" width="120">
                    <template slot-scope="scope">
                        <router-link :to="{ name: 'blogEdit', params: { blogId: scope.row.id, currentUserId:scope.row.userId } }"><el-button size="mini" type="primary" @click="update(scope.row)">修改</el-button></router-link>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>




        <!--分页-->
        <!--分页页脚-->
        <div class="mypage">
            <el-pagination
                    class="mpage"
                    background
                    layout="prev, pager, next"
                    :current-page="currentPage"
                    :page-size="pageSize"
                    :total="total"
                    @current-change="query"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "BlogInfo",
        data(){
            return{
                tableData:{

                },
                currentPage: 1,
                total: 1,
                pageSize: 5,
                languageList:[
                    {
                        value:'JAVA',
                        key:1
                    },
                    {
                        value:'Spring',
                        key:2
                    },
                    {
                        value:'数据库',
                        key:3
                    },
                    {
                        value:'VUE',
                        key:4
                    },
                    {
                        value:'服务器',
                        key:5
                    },
                    {
                        value:'其他',
                        key:6
                    }
                ],
            }
        },
        methods: {
            query(currentPage){
                const _that = this;
                this.$axios.post('/javaBlogs/queryContent', {userId:localStorage.getItem("id"),currentPage: currentPage}).then(function (response) {
                    _that.tableData = response.data.data.records;
                    _that.total = response.data.data.total
                    _that.pageSize = response.data.data.size
                })
            },
            /*删除单个数据*/
            del(value){
                const _that = this;
                this.$axios.post('/javaBlogs/deleteBlog', {userId:localStorage.getItem("id"), id:value}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.reload();
                })
            }
        },
        created() {
            const id = localStorage.getItem("id");
            if (id != '' && id != null){
                this.query(this.currentPage);
            }

        }
    }
</script>

<style scoped>

</style>