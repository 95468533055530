<template>
    <div>
        <Header></Header>
        <div class="tableInfo">
            <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
                <el-radio-button :label="showMenu">展开</el-radio-button>
                <el-radio-button :label="!showMenu">收起</el-radio-button>
            </el-radio-group>
            <el-container style="height: 80%; border: 1px solid #eee;">
                <!--<el-aside width="200px" style="background-color:#FFCC99">
                  <el-menu @open="handleOpen" @close="handleClose" :collapse="isCollapse"
                           class="el-menu-vertical-demo"
                           background-color="#FF6666"
                           text-color="#fff"
                           active-text-color="#FFFF66"
                           :default-openeds="['1', '2']">
                    <el-submenu index="1">
                      <template slot="title"><i class="el-icon-s-custom" style="color: #FFFF66"></i>用户信息管理</template>
                        <el-menu-item index="1-1" @click="menuClick('coma')">用户信息</el-menu-item>
                        <el-menu-item index="1-2" @click="menuClick('coma')">用户请求</el-menu-item>
                    </el-submenu>
                    <el-submenu index="2">
                      <template slot="title"><i class="el-icon-s-check" style="color: #FFFF66"></i>权限管理</template>
                        <el-menu-item index="2-1" @click="menuClick('coma')">用户授权</el-menu-item>
                    </el-submenu>
                    <el-submenu index="3">
                      <template slot="title"><i class="el-icon-menu" style="color: #FFFF66"></i>文章信息管理</template>
                      <el-menu-item index="3-1" @click="menuClick('coma')">博客信息</el-menu-item>
                      <el-menu-item index="3-2" @click="menuClick('pushEmail')">热门推送</el-menu-item>
                      <el-menu-item index="3-3" @click="menuClick('coma')">首页排行</el-menu-item>
                    </el-submenu>
                  </el-menu>
                </el-aside>-->
                <el-menu default-active="1-5-1" :default-openeds="['1','2','4']" class="el-menu-vertical-demo"
                         @open="handleOpen" @close="handleClose" :collapse="isCollapse">

                    <el-submenu v-show="isAuth" index="1">
                        <template slot="title">
                            <i class="el-icon-s-custom"></i>
                            <span slot="title">用户信息管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="1-1" @click="menuClick('coma')">用户信息</el-menu-item>
                            <el-menu-item index="1-2" @click="menuClick('userRequest')">用户请求</el-menu-item>
                            <el-menu-item index="1-3" @click="menuClick('friend')">友情链接</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu v-show="isAuth" index="2">
                        <template slot="title">
                            <i class="el-icon-s-check"></i>
                            <span slot="title">权限管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="2-1" @click="menuClick('authorityInfo')">权限设置</el-menu-item>
                            <el-menu-item index="2-2" @click="menuClick('authority')">角色授权</el-menu-item>
                            <el-menu-item index="2-3" @click="menuClick('userAuth')">用户授权</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>

                    <el-submenu v-show="isAuth" index="3">
                        <template slot="title">
                            <i class="el-icon-menu"></i>
                            <span slot="title">内容管理</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="3-2" @click="menuClick('pushEmail')">热门推送</el-menu-item>
                            <el-menu-item index="3-3" @click="menuClick('coma')">首页排行</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                    <el-submenu index="4">
                        <template slot="title">
                            <i class="el-icon-suitcase"></i>
                            <span slot="title">工作台</span>
                        </template>
                        <el-menu-item-group>
                            <el-menu-item index="4-1" @click="menuClick('blogInfo')">文章管理</el-menu-item>
                            <el-menu-item index="4-2" @click="menuClick('feedBack')">请求反馈</el-menu-item>
                        </el-menu-item-group>
                    </el-submenu>
                </el-menu>

                <el-container>
                    <el-main>
                        <component :is="currentComponent">
                        </component>
                    </el-main>
                </el-container>
            </el-container>
        </div>
<!--      <Footer></Footer>-->
    </div>

</template>
<script>
    import AccountInfo from "../components/AccountInfo";
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    import PushEmail from "../components/PushEmail";
    import Authority from "../components/Authority";
    import AuthorityInfo from "../components/AuthorityInfo";
    import UserAuth from "../components/UserAuth";
    import BlogInfo from "../components/BlogInfo";
    import UserRequest from "../components/UserRequest"
    import FeedBack from "../components/Feedback"
    import Friend from "../components/Friend";

    export default {
        inject: ["reload"],
        components: {
            Footer,
            Header,
            coma: AccountInfo,
            pushEmail: PushEmail,
            authority: Authority,
            authorityInfo: AuthorityInfo,
            userAuth: UserAuth,
            blogInfo: BlogInfo,
            userRequest: UserRequest,
            feedBack: FeedBack,
            friend: Friend
        },
        data() {
            return {
                showMenu: false,
                isAuth: false,
                isCollapse: true,
                currentComponent: 'blogInfo'
            }
        },

        methods: {
            handleOpen(key, keyPath) {
                /*console.log(key, keyPath);*/
            },
            handleClose(key, keyPath) {
                /*console.log(key, keyPath);*/
            },
            menuClick(value) {
                this.currentComponent = value
            }

        },
        created() {
            if (localStorage.getItem("roleId") == 1 || localStorage.getItem("roleId") == 2) {
                this.isAuth = true;
                this.currentComponent = 'coma';
            }
        }
    };
</script>

<style>
    .tableInfo {
        margin-top: 100px;
        margin-bottom: 20%;
        float: revert;
    }

    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
</style>
