<template>
    <div>
        <Header></Header>
        <div class="wrapper">
            <div class="content">
                <GoBack></GoBack>
                <div class="showInfo">
                    <h1 class="titleInfo">
                        <div class="titleSize  el-icon-loading">
                            {{moduleTitle}}
                        </div>
                    </h1>
                <div class="mycard" v-for="(blog, index) in tableData" :key="index" v-show="isShowInfo">
                    <div v-show="blog.titleImage != null">
                        <div class="mycard_left" @click="detail(blog.id, blog.userId)"><img :src="blog.titleImage" /></div>
                    </div>
                    <div v-show="blog.titleImage == null || blog.titleImage == ''">
                        <img  @click="detail(blog.id, blog.userId)" src="https://www.arien.top/image/02.jpg" />
                    </div>
                    <div class="mycard_right" @click="detail(blog.id, blog.userId)" >
                        <div class="mycard_right_title">
                            <router-link
                                    :to="{ name: 'blogDetail', params: { blogId: blog.id, currentUserId:blog.userId } }"
                            ><span class="el-icon-mouse">{{
                  blog.title
                }}</span></router-link
                            >
                        </div>
                        <div class="mycard_right_content">
                            <p>摘要:{{blog.detail|ellipsis}}</p>
                            <p style="border-radius:2px;color: #ed6f26;font-weight: bold;line-height: 3px;">作者:{{blog.updatedUser}}</p>
                        </div>
                        <div
                                v-show="blog.ariticle != null && blog.ariticle != ''"
                                class="blogstype"
                        >
                            <el-tag type="success" size="mini">{{ blog.ariticle }}</el-tag>
                        </div>
                        <div class="mycard_date el-icon-alarm-clock">
                            {{ blog.createdTime }}
                        </div>
                    </div>
                </div>

                    <div class="noData" v-show="!isShowInfo">
                        <h1>该模块无内容哦！</h1>
                    </div>

            </div>

        <div class="pageInfo">
        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>
        </div>
        </div>
    </div>
    </div>
</template>

<script>
    import Footer from "./Footer";
    import Header from "./Header";
    import GoBack from "./GoBack";

    export default {
        filters: {
            ellipsis (value) {
                if (!value) return ''
                if (value.length > 15) {
                    return value.slice(0,15) + '...'
                }
                return value
            }
        },
        components: {
            GoBack,
            Footer,
            Header,
        },
        name: "BlogInfo",
        data(){
            return{
                moduleTitle: '',
                tableData:{

                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 5,
                    total: ''
                },
                isShowInfo:true
            }
        },
        methods: {
            detail(id, userId){
                /*console.log(JSON.stringify(id+"数据"+userId))*/
                localStorage.setItem("BLOGID", id)
                localStorage.setItem("CUURENTSERID", userId)
                this.$store.commit('SET_BLOGID',id)
                this.$store.commit('SET_CURRENTUSERID',userId)
                this.$router.push({path:"/blogDetail"})
            },
            query(currentPage){
                const _that = this;
                this.$axios.post('/javaBlogs/moduleInfo', {currentPage: currentPage, plateId:localStorage.getItem("moduleId" )}).then(function (response) {
                    console.log(response)
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                    _that.total = response.data.data.total;
                    _that.showPage = response.data.data.size;
                    if (_that.tableData == null || _that.tableData == ''){
                        _that.isShowInfo = false
                    }
                    window.scrollTo(0,0)
                })
            }
        },
        created() {
            this.query();
            const moduleType = localStorage.getItem("moduleId" );
            if (moduleType == 1){
                this.moduleTitle = '后端'
            }else if (moduleType == 2){
                this.moduleTitle = "Spring"
            }else if (moduleType == 3){
                this.moduleTitle = "数据库"
            }else if (moduleType == 4){
                this.moduleTitle = "前端"
            }else if (moduleType == 5){
                this.moduleTitle = "服务器"
            }else if (moduleType == 6){
                this.moduleTitle = "其它"
            }

        }
    }
</script>

<style scoped>

    .pageInfo{
        text-align: center;
    }

    .wrapper{
        width: 100%;
        min-height: 1000px;
    }

    .showInfo{
        min-height: 800px;
        width: 100%;
    }

    /*去掉加载的图标*/
    >>>.el-icon-loading:before {
        content: "";
    }

    .el-icon-loading {
        -webkit-animation: rotating 2s linear infinite;
        animation: rotating 8s linear infinite;
    }

    .noData{
        width: 80%;
        height: 100%;
        text-align: center;
        margin: 20% auto;
        border: 3px solid #f1722c;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .titleInfo{
        text-align: center;
        color: rgba(0, 0, 0, 0.72);
        margin: 10px auto !important;
        width: 160px;
        height: 160px;
        font-size: 30px;
        /*background-color: #233d5c;*/
        background-image: linear-gradient(to right, #8fcfec, #c09fff);
        font-family: 华光综艺_CNKI;
        border-radius: 100px;
        padding: 40px 20px 0px 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .titleSize {
        height: 40px;
        width: 100px;
        margin: 40px auto;
    }

    .content {
        margin: 100px auto;
        width: 80%;
        min-width: 500px;
        min-height: auto;
        height: auto;
        padding: 10px 2px;
        border-radius: 20px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    /*.mycard {
        width: 100%;
        height: 210px;
        border-radius: 20px;
        background: white;
        margin: 50px 2px 50px 20px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }*/

    .mycard {
        width: 98%;
        height: 210px;
        border-radius: 20px;
        background: white;
        margin: 50px 5px 50px 5px;
        overflow: hidden;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }

    .mycard_left {
        float: left;
        width: 35%;
        min-width: 150px;
        height: 230px;
    }
    .mycard_left > img {
        width: 100%;
        height: 100%;
    }
    .mycard_right {
        float: left;
        height: 200px;
        width: 60%;
    }
    .mycard_right_title {
        font-size: 25px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: bold;
        text-decoration: none;
    }
    .mycard_right_title > span{
        color: #FFCC99;
    }
    a {
        text-decoration: none;
    }

    .mycard_right_content {
        font-size: 17px;
        margin-top: 15px;
        margin-left: 25px;
        font-weight: lighter;
        padding: 1px;
        font-family: 迷你简家书;
    }
    .mycard_date {
        font-size: 20px;
        float: right;
        height: 15px;
        width: 160px;
        margin: 40px 1px 10px 0px;
        font-size: 12px;
        font-family: "Adobe 仿宋 Std R";
        font-weight: bold;
        line-height: 3px;
    }
    .carousel {
        width: 100%;
        height: 370px;
        overflow: hidden;
    }
    .carousel > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    /*AD走马灯*/
    /* .el-carousel__item h3 {
            color: #475669;
            font-size: 14px;
            opacity: 0.75;
            line-height: 200px;
            margin: 0;
        }*/

    .blogstype {
        float: left;
        height: 25px;
        margin-left: 17px;
        border-radius: 8px;
    }
    @media screen and (max-width:867px) {
        .allShow{
            width: 100%;
            min-width: 100%;
        }
        .content{
            width: 100%;
            min-width: 100%;
        }

        .titleInfo {
            font-size: 24px;
        }
        .footer_top_left{
            width: 100%;
        }
        .mycard {
            margin: 18px auto;
            width: 98%;
            height: 160px;
            overflow: hidden;
        }

        .mycard_right {
            width: 56%;
            height: 100%;
            z-index: 999;
            margin: 0 0 0 5px;
            background-color: #fff;
            opacity: 0.78;
            float: right;
            /*filter: blur(1px);*/
        }

        .mycard_left {
            float: left;
            width: 0%;
            min-width: 100px;
            height: 230px;
        }

        .carousel {
            width: 100%;
            height: 370px;
            border-radius: 10px !important;;
            overflow: hidden !important;;
        }

        .carousel > img {
            height: 100%;
            width: 100%;
        }

        /*  .mycard_left {
            float: left;
            width: 35%;
            min-width: 150px;
            height: 230px;
          }*/
        .carousel > img {
            height: 100%;
            width: 100%;
        }

        .mycard_right_title {
            font-size: 16px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            text-decoration: none;
            line-height: 8px;
        }

        .mycard_right_content {
            font-size: 13px;
            margin-top: 5px;
            margin-left: 5px;
            font-weight: bold;
            line-height: 15px;
            padding: 0px;
            color: #586b85;
        }

        .mycard_date {
            float: right;
            height: auto;
            width: 100%;
            margin-bottom: 0;
            margin-right: 0;
            font-size: 10px;
            margin-top: 12px;
            font-weight: bold;
        }

        >>> .el-button--mini {
            margin-left: 16px;
            padding: 7px 15px;
        }

        >>> .el-menu-item [class^=el-icon-] {
            margin-right: 5px;
            width: 2px;
            text-align: center;
            font-size: 25px;
            vertical-align: middle;
        }

        .mydate {
            float: right;
            margin-right: 28px;
            margin-top: 70px;
            width: 300px;
            height: auto;
            /* border: 2px solid #336699;*/
            overflow: hidden;
            border-radius: 10px;
            padding: 10px 3px;
            font-size: 18px;
            background: white;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }

        .mycard_left > img {
            width: auto;
            height: 100%;
        }

    }
</style>