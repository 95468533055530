<template>
    <div id="wrapper">
        <Header></Header>
        <div class="userInfo">
            <!--<el-button type="primary" @click="getBack()">返回</el-button>-->
            <GoBack></GoBack>

            <div class="header_top">
                <div class="avatar">
                    <div class="myAvatar el-icon-loading">
                        <el-avatar :size="90" :src="users.image"></el-avatar>
                    </div>
                </div>
                <div class="title"><h2 style="color: rgb(26,87,94);text-align: center">用户{{users.userName}}的资料</h2></div>
            </div>
            <div class="information">

                <div class="data">
                    <span>用户名：{{users.userName}}</span>
                    <el-divider></el-divider>
                    <span>昵称：{{users.nickname}}</span>
                    <el-divider></el-divider>
                    <span>邮件：{{users.email}}</span>
                    <el-divider></el-divider>
                    <span>电话：{{users.phone}}</span>
                    <el-divider></el-divider>
                    <span>专业：{{users.profession}}</span>
                    <el-divider></el-divider>
                    <span>简介：{{users.introduction}}</span>
                    <el-divider></el-divider>
                </div>
            </div>
        </div>
<!--      <Footer></Footer>-->
    </div>
</template>

<script>
    import Header from "../components/Header";
    import GoBack from "../components/GoBack";
    import Emoji from "../components/Emoji";
    import Footer from "@/components/Footer.vue";
    export default {
        components: {
          Footer,
            Emoji,
            GoBack,
            Header
        },
        name: "UserInfo",
        data () {
            return {
                hideUpload: false,
                dialogImageUrl: '',
                dialogVisible: false,//图片预览弹窗
                formLabelWidth: '80px',
                limitNum: 1,
                dialogFormVisible: false,
                form: {},
                isHide: 0,
                users:{
                    isHide:0,
                    userId:''
                },
                dialogVisible2: false,//弹窗
                languageList:[
                    {
                        value:'是',
                        key:1
                    },
                    {
                        value:'否',
                        key:0
                    }
                ],
            }
        },
        methods:{
            //获取所有用户信息
            user(){
                const _this = this;
                this.$axios.post("/userDetail/userInfoById", {id:localStorage.getItem("leaveUserId")}).then(function (res) {
                    _this.users = res.data.data
                    window.scrollTo(0,0)
                })
            },
            getBack(){
                this.$router.back()
            }
        },
        created() {
            this.user()
        }
    }
</script>

<style scoped>
    .hide .el-upload--picture-card {
        display: none;
    }

    #wrapper{
        min-height: 1000px;
        width: 80%;
        margin: 0 auto;
    }

    .idea {
        margin-top: 10px;
        width: 120px;
        height: 30px;
    }

    .blue {
        width: 30px;
        height: 30px;
        float: left;
        border-radius: 100px;
        background-color: #3eb4a6;
    }

    .yellow {
        width: 30px;
        height: 30px;
        float: left;
        margin-left: 10px;
        border-radius: 100px;
        background-color: #eeda5d;
    }

    .red {
        width: 30px;
        height: 30px;
        margin-left: 10px;
        float: left;
        border-radius: 100px;
        background-color: #de505e;
    }


    /*去掉加载的图标*/
    >>>.el-icon-loading:before {
        content: "";
    }

    .el-icon-loading {
        -webkit-animation: rotating 2s linear infinite;
        animation: rotating 8s linear infinite;
    }
    .userInfo{
        margin: 100px auto;
        min-width: 80%;
        width: 60%;
        height: 750px;
        border-radius: 10px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        float: revert;
    }

    .el-avatar{
        margin: 20px 32px;
        float: left;
    }

    /*.header_top{
        width: 100%;
        height: 150px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }*/

    .avatar{
        width: 150px;
        height: 150px;
        background-color: rgba(56, 201, 126, 0.46);
        border-radius: 100%;
        /*float: revert;*/
        overflow: hidden;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        /*float: left;*/
        padding: 18px 6px 0px 6px;
        margin: 10px auto;
    }

    /*.title{
        width: 70%;
        height: 100px;
        background-color: #bbc9fd;
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        float: right;
    }*/

    .information{
        opacity: 0.78;
        height: auto;
        width: 100%;
        font-weight: bolder;
        padding: 30px 0;
        background-color: #fcfaff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        border-radius: 10px;
        float: right;
    }
    .data{
        color: #0b663c;
        margin: 0 auto;
        width: 60%;
        height: 60%;
    }

    .el-button--primary {
        color: #FFF;
        background-color: rgba(58, 208, 173, 0.64);
        border-color: #36aa89;
    }

    @media screen and (max-width:867px) {
        #wrapper{
            min-height: auto;
            width: 90%;
            margin: 0 auto;
        }

        .userInfo{
            margin: 100px auto;
            min-width: 80%;
            width: 90%;
            border-radius: 10px;
            background-color: #ffffff;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            float: revert;
        }

        .avatar{
            width: 150px;
            height: 150px;
            background-color: #ffffff;
            border-radius: 10px;
            /*float: revert;*/
            overflow: hidden;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        }
        .el-avatar{
            margin: 20px 32px;
            float: left;
        }
        .information{
            opacity: 0.78;
            height: auto;
            width: 100%;
            font-weight: bolder;
            background-color: #d6f4f6;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
            border-radius: 10px;
            float: right;
        }
        .data{
            color: #42b983;
            margin: 0 auto;
            width: 90%;
            height: 70%;
            font-size: 16px;
        }

    }

</style>