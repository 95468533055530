<template>
    <div>
        <Header></Header>
        <div class="format"></div>
        <div class="myleave">
            <div class="primaryleave" v-for="item in leaves">
                <div class="leave_left" v-for="u in users" v-if="u.id == item.uid" @click="detail(u.id)" >
                     <div style = "width: auto"><GoBack></GoBack></div>
                    <div class="mylogo"><el-avatar :fit =fit :size="80" :src="u.image"></el-avatar><!--<el-badge v-show="u.role == 1?true:false" value="博主" class="item">/el-badge>--></div>
                    <div class="leave_name">{{u.userName}}</div>
                </div>
                <div class="leave_right">
                    <div class="leave_message" v-html="item.lmessage"></div>
                    <div class="leave_tiem">{{item.lcreated}}</div>
                    <div class="replaybutton">
                        <!--<div class="youLike" v-show="userLike" @click="myLike">
                        <img src="../assets/点赞.png">
                        </div>
                        <div class="youLike" v-show="!userLike">
                            <img src="../assets/点赞2.png">
                        </div>-->
                        <el-button type="success" icon="el-icon-chat-dot-round" size="mini" @click="replyLid(item.lid)">回复</el-button>
                        <el-button v-show="isAdmin" slot="reference" size="mini" type="danger" @click="del(item.lid)">删除</el-button>
                    </div>

                    <!--回复模态框-->
                    <el-dialog class="statu"  title="回复" :visible.sync="dialogFormVisible" width="400px">
                    <el-form :model="form" >
                        <el-form-item  label="内容:" :label-width="formLabelWidth">
                            <el-input placeholder="说点什么......" type="textarea" :rows="3" v-model="form.desc"></el-input>
                        </el-form-item>
                        <el-form-item  label="当前身份:" :label-width="formLabelWidth">
                        <el-input placeholder="" v-model="author" :disabled="true">
                        </el-input>
                        </el-form-item>
                        <el-form-item label="怎么称呼您:" :label-width="formLabelWidth" v-show="!exsitUser">
                            <el-input v-model="form.userName" autocomplete="off"></el-input>
                        </el-form-item>
                    </el-form>
                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogFormVisible = false">取 消</el-button>
                        <el-button type="primary" @click="submitForm('form')">确定</el-button>
                    </div>
                </el-dialog>
                </div>

                <!--回复内容展示-->
                <div class="replay" v-for="r in replys" v-if="item.lid == r.rid" v-loading="loading">
                    <!--展示回复者的信息-->
                    <div class="replyer" v-for="user in users" v-show="r.ruid == user.id">
                        <div class="replyer_logo" @click="detail(user.id)"><el-avatar :size="30" :src="user.image"></el-avatar></div>
                        <div class="replyer_name">{{user.userName}}&nbsp@</div>
                    </div>

                    <!--展示被回答者的信息-->
                    <div v-for="user in users">
                    <div class="be_replyer"  v-if="item.uid == user.id">
                        <div class="be_replyer_name">{{user.userName}}:</div>
                    </div>
                    </div>

                    <div class="reply_content" v-html="r.rmessage">
                    </div>

                    <!--回复时间-->
                    <div class="reply_time">
                        <i class="el-icon-watch"></i> {{r.rcreated}}
                    </div>
                </div>

            </div>

            <!--分页页脚-->
            <div class="mypage">
                <el-pagination
                        class="mpage"
                        background
                        layout="prev, pager, next"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        :total="total"
                        @current-change="page"
                >
                </el-pagination>
            </div>

        </div>

        <!--主页面评论区-->
        <div class="primaryreply">
            <h2 align="center">留言</h2>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item v-show = "ruleForm.desc2 != ''" label="评论预览：" prop="desc">
                <div style="width: 100%;height: auto;padding-top:10px;background-color: yellowgreen;border-radius: 10px;" v-html="ruleForm.desc2"></div>
                </el-form-item>
                <el-form-item label="发表评论" prop="desc">
                    <div v-show = "loginSuccess"><el-avatar :size="50" :src="avatar"></el-avatar>
                    </div>
                    <el-popover
                            placement="right"
                            trigger="click">
                        <div @click="pushEmoji()">
                        <Emoji ></Emoji>
                        </div>
                        <el-button size="mini" type="warning" slot="reference"><i class="el-icon-picture-outline-round"></i>表情</el-button>
                    </el-popover>
                    <el-input v-on:input = "changeInfo(ruleForm.desc)" placeholder="说点什么......（可支持css或html标签）" type="textarea" :rows="4" v-model="ruleForm.desc"></el-input>
                </el-form-item>
                <el-form-item  label="当前身份:" :label-width="formLabelWidth">
                    <el-input placeholder="" v-model="author" :disabled="true">
                    </el-input>
                </el-form-item>
                <el-form-item label="怎么称呼:" :label-width="formLabelWidth" v-show="!exsitUser" prop="userName">
                    <el-input v-model="ruleForm.userName" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="邮箱:" :label-width="formLabelWidth" v-show="!exsitUser" prop="email">
                    <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm02('ruleForm')"><i class="el-icon-s-promotion"></i>发表</el-button>
                    <el-button @click="resetForm02('ruleForm')"><i class="el-icon-refresh-left"></i>重置</el-button>
                </el-form-item>
            </el-form>
        </div>
<!--      <Footer></Footer>-->
    </div>

</template>

<script>
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    import GoBack from "../components/GoBack";
    import Emoji from "../components/Emoji";
    export default {
        inject:['reload'],//注入刷新页面代码块
        name: "MyLeave",
        components:{Emoji, GoBack, Header,Footer},
        data() {
            return {
                lid:1,
                replys:{},
                fit: 'contain',
                users:{},
                //分页信息
                leaves:[],
                currentPage: 1,
                total: 1,
                avatar:'',
                pageSize: 5,
                loading: true,
                isAdmin: false,
                userLike: true,
                loginSuccess: false,
                rules: {
                    desc: [
                        {required: true, message: '请输入内容！', trigger: 'blur'},
                        {min: 3, max: 500, message: '长度在 3 到 350 个字符', trigger: 'blur'}
                    ],
                    userName: [
                        {required: true, message: '游客需要输入称呼！', trigger: 'blur'},
                        {min: 3, max: 10, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ],
                    email: [
                        {required: true, message: '请输入邮箱地址', trigger: 'blur'},
                        {type:'email', message: '请输入正确的邮箱地址', trigger: 'blur'}
                    ],
                },
                    ruleForm: {
                        userName: '',
                        desc: '',
                        email: '',
                        desc2: ''
                    },
                authority: false,
                    userName: '',
                    exsitUser: false,
                    author: "游客（游客身份的留言回复可能存在bug！）",
                    dialogFormVisible: false,
                    form: {
                        authorize: '',
                        desc: '',
                        userName: ''
                    },
                    formLabelWidth: '120px'
            }
        },
        methods:{
            //存储回复的内容
            submitForm(form){
                if (localStorage.getItem("id") == null || localStorage.getItem("id") == ''){
                    this.$message({
                        showClose: true,
                        message: '无法回复，请登录后再回复！',
                        type: 'warning'
                    })
                }
                const _this = this;
                this.$axios.post('/leave/saveReply',{uid:localStorage.getItem("id"),lid:this.lid,name:this.form.name,rmessage:this.form.desc}).then(function (res) {
                    //alert(_this.lid)
                    _this.$message({
                        showClose: true,
                        message: '回复成功！',
                        type: 'success'
                    })
                    //关闭模态框
                    _this.dialogFormVisible = false
                    _this.reload();
                    //清空回复表单
                    _this.$refs[form].resetFields()
            })
            },
            //处理提交的留言
            submitForm02(formName) {
                const _this = this;
                if (this.ruleForm.userName == '' || this.ruleForm.userName == null) {
                    this.ruleForm.userName = localStorage.getItem("name");
                }
                this.$refs[formName].validate((valid) => {
                    if (valid || this.exsitUser) {
                        this.$axios.post('/leave/saveLeave',{
                            uid:localStorage.getItem("id"),
                            name:this.ruleForm.userName,
                            lmessage:this.ruleForm.desc2,
                            email:this.ruleForm.email
                        }).then(function (res) {
                            //清楚内容
                            //_this.resetForm02("ruleForm");
                            _this.$message({
                                message: '留言成功！',
                                type: 'success'
                            })

                            if (localStorage.getItem("id") == '' || localStorage.getItem("id") == null ){
                                localStorage.setItem("id", res.data.data.userId)
                                localStorage.setItem("avatar", res.data.data.image)
                                localStorage.setItem("name", res.data.data.userName)
                            }
                            _this.form.name = _this.ruleForm.name
                            _this.reload();
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //清空formName表单
            resetForm02(formName) {
                this.$refs[formName].resetFields();
                this.ruleForm.desc2 = ''
            },
            //获取主留言分页信息
            page(currentPage){
                //alert(currentPage)
                const _this = this;
                this.$axios.get('/leave/getLeave?currentPage='+currentPage).then(function (res) {
                    //console.log(res)
                    _this.leaves = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                    window.scrollTo(0,0)
                })
            },
            //获取所有用户信息
            user(){
                const _this = this;
                this.$axios.get('/leave/getUser').then(function (res) {
                    //console.log(res)
                    _this.users = res.data.data
                })
            },
            //获取留言的lid
            replyLid(lid){
                this.dialogFormVisible = true
                //alert(lid)
                this.lid = lid
    },
            reply(){
                const _this = this;
                this.$axios.get('/leave/getReply').then(function (res) {
                    _this.replys = res.data.data
                    _this.loading = false
                })
            },
            myLike(){
                this.userLike = false;
            },
            detail(id){
                localStorage.setItem("leaveUserId", id)
                this.$router.push({path:"/userInfo"})
            },
            /*获取表情*/
            pushEmoji(){
                const emoji = localStorage.getItem("emoji");
                if (emoji != null || emoji != ''){
                    this.ruleForm.desc = this.ruleForm.desc + emoji
                    this.ruleForm.desc2 = this.ruleForm.desc
                    this.replaceEmoji(this.ruleForm.desc2)

                }

            },
            replaceEmoji(value) {
                this.ruleForm.desc2 = value.replaceAll("$001", "<img src=\"https://www.arien.top/image/emoji/01.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$002", "<img src=\"https://www.arien.top/image/emoji/02.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$003", "<img src=\"https://www.arien.top/image/emoji/03.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$004", "<img src=\"https://www.arien.top/image/emoji/04.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$005", "<img src=\"https://www.arien.top/image/emoji/05.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$006", "<img src=\"https://www.arien.top/image/emoji/06.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$007", "<img src=\"https://www.arien.top/image/emoji/07.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$008", "<img src=\"https://www.arien.top/image/emoji/08.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$009", "<img src=\"https://www.arien.top/image/emoji/09.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$010", "<img src=\"https://www.arien.top/image/emoji/10.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$011", "<img src=\"https://www.arien.top/image/emoji/11.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$012", "<img src=\"https://www.arien.top/image/emoji/12.png\" style = \"width:35px;height:35px\"/>")
            },
            changeInfo(value) {
                this.ruleForm.desc2 = value
                this.replaceEmoji(this.ruleForm.desc2)
            },
            /*删除单个数据*/
            del(value){
                const _that = this;
                this.$axios.post('/leave/delete', {lid:value}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.reload();
                })
            }
        },
        created() {
            this.page(this.currentPage);
            this.user()
            this.reply()
            this.avatar = localStorage.getItem("avatar")
            if(localStorage.getItem("id") !=  null && localStorage.getItem("id") != ''){
                this.author = "普通用户";
                this.exsitUser = true;
                this.loginSuccess = true;
            }else {
                this.exsitUser = false;
            }

            if (localStorage.getItem("roleId") == 1) {
                this.isAdmin = true;
            }

        }
    }
</script>

<style scoped>
    .myleave{
        min-height: 800px;
        width: 80%;
        margin: 0 auto;
    }

    >>>.el-form-item__content {
        margin-left: 120px !important;
    }

    .format{
        height: 100px;
    }
    img {
        pointer-events:none;
    }
    .primaryleave{
        width: 100%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 20px auto 30px;
        border-radius: 10px;
        /*margin-top: 100px;
        margin-bottom: 30px;*/
        height:auto;
        display:inline-block !important;
        display:inline;
    }
    .leave_left{
        float: left;
        width: 16%;
        min-width: 120px;
        height: 200px;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .leave_right{
        width: 77%;
        max-width: 100%;
        font-family: 华光综艺_CNKI;
        /*background-color: #b6cbff;*/
        background-image: linear-gradient(to right, #ffbecd, #de83ee, #916df1);
       /* min-height: 100px;*/
        border-radius: 6px;
        padding: 5px;
        float: right;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 10px 0px 20px 0px;
        height: auto;
       /* display:inline-block !important;
        display:inline;*/
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
    }
    .replay{
        width: 60%;
        height: auto;
        min-height: 50px;
        border-radius: 6px;
        background-image: linear-gradient(to right, #b990f8, #77b8ee);
        float: right;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 10px 0px 20px 50px;
        height:auto;
        display:inline-block !important;
        display:inline;
        overflow: hidden;

    }
    .replyer{
        width: auto;
        height: auto;
        float: left;
        margin: 10px 5px 5px 10px;
    }
    .replyer_logo{
        float: left;
    }
    .replyer_name{
        float: left;
        margin: 6px 10px;
        color: #041b36;
        font-size: 15px;
        font-weight: bold;
    }
    .be_replyer{
        float: left;
        margin: 10px 0px 10px -20px;
        width: auto;
    }
    .be_replyer_name{
        float: left;
        margin: 6px 6px;
        color: #ed3469;
        font-size: 15px;
        font-weight: bold;
    }
    .reply_content{
        margin-top: 14px;
        width: 60%;
        margin-left: 5px;
        float: left;
        font-size: 15px;
        color: #39404b;
        font-weight: bold;
    }
    .reply_time{
        float: right;
        /*margin-top: 8%;*/
        width: 100%;
        margin-bottom: 1px;
        margin-right: 1px;
        height: auto;
        font-size: 10px;
        color: #8f33c4;
        font-family: 方正综艺_GBK;
        font-weight: bold;
        text-align: right;
    }
    .replaybutton{
        float: right;
        width: auto;
        margin: -35px 10px 15px 10px;
        height: 20px;
    }
    .youLike {
        float: left;
        margin-right: 20px;
        margin-top: 2px;
    }
    .replaybutton .youLike>img{
        width: 20px;
        width: 20px;
        pointer-events:none;
    }
    .primaryreply{
        width: 80%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 10px 18px;
        margin: 50px auto;
    }
    .mypage{
        width: 100%;
        height: 60px;
        text-align: center;
        margin: 30px auto;
    }
    .mylogo{
        width: 80px;
        height: 80px;
        margin: 20px auto;
    }
    .leave_name{
        width: 100%;
        text-align: center;
        height: 20px;
        font-weight: bold;
        font-size: 20px;
        font-family: 微軟正黑體;
        color: #fa7c42;
        margin: 0 auto;
    }
    .leave_message{
        float: left;
        width: 88%;
        height: auto;
        padding: 10px;
        color: #40196c;
        font-family: 华光综艺_CNKI;
        font-weight: bold;
    }
    .leave_tiem{
        width: 100%;
        color: #336699;
        margin: 2px 2px;
        float: right;
    }

    >>>.idea {
        /* margin-top: 5px; */
        /* margin-left: 5px; */
        width: 120px;
        height: 36px;
        padding: 3px 3px;
        margin: 5px auto;
    }

    @media screen and (max-width:867px) {
        .leave_left{
            float: left;
            width: 100%;
            height: 200px;
            border-radius: 10px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
        .leave_right{
            width: 96%;
            min-height: 80px;
            border-radius: 6px;
            float: left;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 20px 20px 0px;
            height:auto;
            display:inline-block !important;
            display:inline;
        }

        >>>.idea {
             margin-top: 5px;
             margin-left: 5px;
            width: 120px;
            height: 36px;
            padding: 3px 3px;
        }

        .primaryreply{
            width: 80%;
            height: auto;
            border-radius: 10px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            padding: 10px 18px;
            margin: 50px auto;
        }

        .leave_message{
            float: left;
            width: 98%;
            min-height: 60px;
            height: auto;
            padding: 10px;
            font-family: 华光综艺_CNKI;
        }

        .replay{
            width: 87%;
            min-height: 70px;
            height: auto;
            border-radius: 6px;
            float: right;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 20px 20px 50px;
            height:auto;
            /*display:inline-block !important;
            display:inline;*/
        }
        .statu{
            width: 160%;
            margin-left: -120px;
        }
        .reply_content{
            margin-top: 3px;
            width: 60%;
            margin-left: 5px;
            float: left;
            font-size: 15px;
            color: #39404b;
            font-weight: bold;
        }
    }

    /*ipad竖屏*/
    @media only screen
    and (min-device-width : 768px)
    and (max-device-width : 1024px)
    and (orientation : landscape){
        .leave_right{
            width: 66%;
            min-height: 80px;
            border-radius: 6px;
            float: left;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 20px 20px 0px;
            height:auto;
            display:inline-block !important;
            display:inline;
        }
    }

    @media only screen
    and (min-device-width: 768px)
    and (max-device-width: 1024px)
    and (orientation: landscape) {
        .leave_right{
            width: 70%;
            min-height: 80px;
            border-radius: 6px;
            float: right;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 20px 20px 0px;
            height:auto;
            display:inline-block !important;
            display:inline;
        }
    }

</style>