import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      token: '',
      userInfo: '',
      userName:'',
      userId:'',
      id:'',
      aid:'',
      roleId: '',
    blogId:'',
    currentUserId:'',
    moduleId:''
  },
  mutations: {
    //set
    SET_TOKEN:(state,token) => {
      state.token = token;
      //浏览器关闭将值存放进去
      localStorage.setItem("token", token)
    },
    SET_USERINFO:(state, userInfo) => {
      state.userInfo = userInfo;
      sessionStorage.setItem("userInfo", userInfo)
    },
    REMOVE_INFO:(state) => {
      state.token = '',
      state.userInfo = {},
      state.userName = '',
      // 清除登录信息
      localStorage.setItem("token", '')
      localStorage.setItem("userInfo", '')
      localStorage.setItem("userName", '')
      localStorage.setItem("avatar", '')
      localStorage.setItem("id", '')
      localStorage.setItem("roleId", '')
      localStorage.setItem("token", '')
    },
    SET_USERNAME:(state, userName) => {
      state.userName = userName
      localStorage.setItem("userName", userName)
    },
    SET_AVATAR:(state, avatar) => {
      state.avatar = avatar
      localStorage.setItem("avatar", avatar)
    },
    SET_ID:(state, id) => {
      state.id = id
      localStorage.setItem("id", id)
    },
    SET_ROLEID:(state, roleId) => {
      state.roleId = roleId
      localStorage.setItem("roleId", roleId)
    },
    SET_BLOGID:(state,data)=>{
      state.blogId=data
    },
    SET_CURRENTUSERID:(state,data)=>{
      state.currentUserId=data
    },
    SET_MODUELINFO:(state,data)=>{
      state.moduleId=data
    }
  },
  getters: {
    //get
    getUser:state => {
      return state.userInfo;
    },
    getUserName:state => {
      return state.userName
    }
  },
  actions: {
  },
  modules: {
  }
})
