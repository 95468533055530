<template>
  <div class="global">
    <el-menu
      :append-to-body="true"
      :collapse-transition="false"
      :default-active="activeIndex"
      router
      class="myheader"
      style=""
      mode="horizontal"
      @select="handleSelect"
    >
      <el-row type="flex">
        <div>
          <el-menu-item :key="0" index="/">
            <div id="logo">
              <img class="logo" src="https://www.arien.top/image/logo2.gif" />
            </div>
          </el-menu-item>
        </div>
        <el-col class="col_2" :span="20">
          <!--设置链接的样式-->

          <el-menu-item :key="1" index="/" class="showWidth">首页</el-menu-item>
          <el-submenu :key="2" index="2" class="showWidth02">
            <template slot="title" >工具</template>
            <el-menu-item
              ><a href="https://www.5axxw.com/tools/web/ps.html" target="_blank"
                >在线ps</a
              ></el-menu-item
            >
            <el-menu-item
              ><a href="http://tool.c7sky.com/webcolor/" target="_blank"
                >配色表</a
              ></el-menu-item
            >
            <el-menu-item
              ><a href="https://mvnrepository.com/" target="_blank"
                >Maven仓库</a
              ></el-menu-item
            >
          </el-submenu>
          <el-menu-item :key="3" index="/search" class="showWidth"
            >文章搜索</el-menu-item
          >
          <el-menu-item :key="4" index="/newLeave" class="showWidth">留言板</el-menu-item>
          <div v-show="hasLogin">
            <!--@click="author"-->
            <el-menu-item :key="5" index="/pictureInfo" class="showWidth">个人中心</el-menu-item>
          </div>
          <el-menu-item :key="7" index="/module" class="showWidth">模块</el-menu-item>
          <el-menu-item :key="8" index="/pigeonhole" class="showWidth">归档</el-menu-item>
          <el-menu-item :key="9" index="/friendLink" class="showWidth">友链</el-menu-item>
          <el-menu-item class="showWidth">
            <div class="header_login">
              <!--全文搜索框-->
              <el-select
                size="small"
                multiple
                filterable
                remote
                reserve-keyword
                placeholder="全局模糊查询"
                :remote-method="remoteMethod"
                :loading="loading"
                v-model="searchInfo"
              >
                <el-option
                  v-for="(item, index) in options"
                  :key="index"
                  :label="item.label"
                  :value="value"
                >
                </el-option>
              </el-select>
              <el-button
                type="warning"
                class="button_search"
                size="small"
                @click="searchAll"
                plain
                >搜索</el-button
              >
              <!--<el-button icon="el-icon-search" @click="searchAll" class="button_search" circle></el-button>-->
            </div>
          </el-menu-item>
        </el-col>

        <div class="col_3">
          <!--显示登录状态-->
          <div v-show="!hasLogin">
          <el-menu-item class="loginInfo showWidth">
            <!--登陆注册按钮-->
            <div class="header_login_right" v-show="!hasLogin">
              <el-button
                style="margin-left:50px;"
                type="success"
                size="mini"
                @click="resetForm('ruleForm')"
                >注册</el-button
              >
              <el-button
                type="primary"
                size="mini"
                @click="resetFormlogin('ruleForm')"
                >登陆</el-button
              >

              <!--显示没有登录时信息-->
              <div class="block">
                <div class="myname">
                  <el-dropdown>
                  <span class="el-dropdown-link" ref="echarType">
                    <!--头像信息-->
                    <i class="el-icon-arrow-down el-icon-s-fold"></i>
                  </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item @click.native="search('/')"
                      >首页</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="search('/search')"
                      >搜索文章</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="search('/newLeave')"
                      >留言评论</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="search('/module')"
                      >模块</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="search('/pigeonhole')"
                      >归档</el-dropdown-item
                      >
                      <el-dropdown-item @click.native="search('/friendLink')"
                      >友链</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>

                </div>
              </div>

            </div>
            <!--注册模态框-->
            <el-dialog
              :close-on-click-modal="false"
              :append-to-body="true"
              title="注册"
              class="statu"
              :visible.sync="dialogFormVisible"
              center
            >
              <el-form
                :model="ruleForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
              >
                <el-form-item label="Email:" prop="email" required>
                  <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="用户名" prop="username">
                  <el-input v-model="ruleForm.userName"></el-input>
                </el-form-item>
                <el-form-item label="密 码" prop="password">
                  <el-input
                    type="password"
                    v-model="ruleForm.password"
                  ></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="valid">
                  <el-input
                    class="myvalid"
                    type="text"
                    v-model="ruleForm.verify"
                  ></el-input>
                  &nbsp;&nbsp;
                  <el-button
                    type="success"
                    :disabled="disabled"
                    @click="sendcode"
                    class="sendcode"
                    plain
                    >{{ btntxt }}</el-button
                  >
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="newUser('ruleForm')"
                  >注 册</el-button
                >
                <el-button @click="dialogFormVisible = false">取 消</el-button>
              </div>
            </el-dialog>

            <!--登陆模态框-->
            <el-dialog
              :close-on-click-modal="false"
              :append-to-body="true"
              title="登陆"
              :visible.sync="dialogFormVisible2"
              class="loginStatu"
              center
            >
              <el-form
                :model="form"
                :rules="login"
                ref="form"
                label-width="100px"
                class="demo-ruleForm"
                @submit.native.prevent
              >
                <el-form-item label="用户名" prop="username">
                  <el-input v-model="form.userName" @keyup.enter.native="submitForm('form')"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" @keyup.enter.native="submitForm('form')">
                  <el-input type="password" v-model="form.password"></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="submitForm('form')"  v-on:keyup.enter="submitForm('form')"
                  >登 陆</el-button
                >
                <el-button @click="dialogFormVisible2 = false">取 消</el-button>
              </div>
            </el-dialog>

          </el-menu-item>
          </div>

          <el-menu-item class="loginInfo2" v-show="hasLogin">
            <!--显示登陆信息-->
            <div class="block">
              <div class="myname">
                <el-dropdown>
                  <span class="el-dropdown-link" ref="echarType">
                    <!--头像信息-->
                    <el-badge :value="userRequest" class="item">
                    <el-avatar :size="40" :src="userAvatar" :fit="fill"></el-avatar>
                      <!-- <el-avatar :size="40" src="https://q1.qlogo.cn/g?b=qq&nk=1045117761&s=100" :fit="fill"></el-avatar>-->
                    </el-badge>
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                    >用户:{{user.name}}</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/pictureInfo')"
                    >个人信息</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/search')"
                    >搜索文章</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/newLeave')"
                    >留言评论</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/module')"
                    >模块</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/pigeonhole')"
                    >归档</el-dropdown-item
                    >
                    <el-dropdown-item @click.native="search('/friendLink')"
                    >友链</el-dropdown-item
                    >
                    <el-badge style="color: lightcoral" :value="userRequest" class="item">
                      <el-dropdown-item @click.native="gotoMenu"
                      >后台管理</el-dropdown-item
                      ></el-badge>
                    <el-dropdown-item @click.native="userLogout"
                    >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <!--<el-button size="mini" type="info" plain disabled
                  ><i
                    class="el-icon-user-solid
                        el-icon&#45;&#45;left"
                  ></i
                  >{{ user.name }}</el-button
                >-->
              </div>
            </div>
          </el-menu-item>
        </div>
      </el-row>
    </el-menu>
    <div class="line"></div>
  </div>

  <!--<div class="m-content">
        <h3>欢迎来到Arien的博客</h3>
        <div class="block">
            <el-avatar :size="80" :src="user.avatar"></el-avatar>
            <div>{{user.username}}</div>
        </div>
        <div class="maction">
            <span><el-link type="primary" href="/blogs">主页</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span><el-link type="success" href="/blog/add">发表博客</el-link></span>
            <el-divider direction="vertical"></el-divider>
            <span v-show="!hasLogin"> <el-link type="warning" href="/login">登陆</el-link></span>
            <span v-show="hasLogin"> <el-link type="danger" @click="logout">退出</el-link></span>
        </div>
    </div>-->
</template>

<script>
export default {
  inject: ["reload"],
  name: "Header",
  data() {
    //校验邮箱格式
    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };

    return {
      drawer: false,
      searchInfo: '',
      userAvatar: '',
      myAuth: false,
      //搜索框配置
      key: [],
      label: [],
      hell: [],
      options: [],
      value: [],
      list: [],
      loading: false,
      states: [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
      ],
      //导航栏设置
      activeIndex: "1",
      activeIndex2: "1",
      user: {
        id: '',
        name: "",
        avatar: "",
      },
      userRequest: null,
      hasLogin: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      disabled: false,
      time: 60,
      btntxt: "发送验证码",

      ruleForm: {
        userName: "",
        email: "",
        password: "",
        verify: "",
      },
      form: {
        userName: "",
        password: "",
      },
      formLabelWidth: "65px",
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个数字或英文组成",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        email: [{ validator: checkEmail, trigger: "blur" }],
        verify: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      login: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 15,
            message: "长度在 3 到 15 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
          valid:[
              { required: true, message: "请输入验证码", trigger: "blur" },
              {
                  min: 5,
                  max: 5,
                  message: "长度在个字符",
                  trigger: "blur",
              },
          ]
      },
    };
  },
  //搜索框属性
  mounted() {
    this.list = this.states.map((item) => {
      return { value: `value:${item}`, label: `label:${item}` };
    });
  },
  methods: {
    // 回车登录
    keyupSubmit(){
      const _this = this;
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          if (_this.dialogFormVisible2 === true){
            _this.submitForm('form')
          }
          if (_this.dialogFormVisible === true){
            _this.newUser('ruleForm')
          }

        }
      }
    },

    //搜索框方法
    remoteMethod(query) {
     /* localStorage.setItem("searchInfo", query)
      this.$router.push({path:"/search"})*/
      /*this.$router.push({ path: 'search', query: { queryInfo: query }})*/
      localStorage.setItem("queryInfo", query)
      /*alert(query)
      const _this = this;
      if (query !== "") {
        //传递axios请求模糊查询文章内容
        _this.$axios.get("/search?query=" + query).then((res) => {
          //_this.list = res.data.data
          //_this.value = res.data.data
          //_this.states = res.data.data
          _this.hello = res.data.data;
          for (let i = 0; i < _this.hello.length; i++) {
            _this.value = _this.hello[i];
            console.log(_this.value);
            _this.key = i;
            _this.label = this.hello.length;
          }
          _this.options = res.data.data;
        });
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
          this.options = this.list.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }*/
    },
    /*发送邮箱*/
    sendcode() {
      const _this = this;
      this.$axios
        .post("/account/getvarify", {
          email: this.ruleForm.email,
          name: this.ruleForm.userName,
        })
        .then(function(res) {
          if (res.data.code == 200) {
            /*_this.$alert("验证码已经发送，将在两分钟后失效，请注意查收！", "提示", {
              confirmButtonText: "确定",
              callback: (action) => {},
            });*/
            _this.$message({
              message: "验证码已经发送，将在两分钟后失效，请注意查收！",
              type: "success",
            });
            this.time = 60;
            this.timer();
          } else if (res.data.code == 400) {
            _this.$message.error(res.data.msg);
          }
        });
    },
    //发送邮箱验证码倒计时
    timer() {
      if (this.ruleForm.email != "") {
        if (this.time > 0) {
          this.disabled = true;
          this.time--;
          this.btntxt = this.time + "秒";
          setTimeout(this.timer, 1000);
        } else {
          this.time = 0;
          this.btntxt = "发送验证码";
          this.disabled = false;
        }
      } else {
        this.$message.error("请检查内容是否正确哦！");
        /*this.$alert("请输入正确邮箱地址", "Hello", {
          confirmButtonText: "确定",
          callback: (action) => {},
        });*/
      }
    },
    resetForm(formName) {
      this.dialogFormVisible = true;
      this.$refs[formName].resetFields();
    },
    /*登陆的模态框*/
    resetFormlogin(formName) {
      this.dialogFormVisible2 = true;
      this.$refs[formName].resetFields();
    },
    submitForm(form) {
      // alert("登陆")
      // this.dialogFormVisible = true
      this.$refs[form].validate((valid) => {
        if (valid) {
          const _this = this;
          this.$axios
            .post("/account/userLogin", this.form)
            .then(function(response) {
              //console.log(response.headers);
              const jwt = response.headers["authorization"];
              const userInfo = response.data.data;
              const userName = response.data.data.userName;
              const avatar = response.data.data.image;
              const id = response.data.data.id;
              const roleId = response.data.data.roleId;

              //存储登陆成功后数据
              _this.$store.commit("SET_TOKEN", jwt);
              _this.$store.commit("SET_USERINFO", userInfo);
              _this.$store.commit("SET_USERNAME", userName);
              _this.$store.commit("SET_AVATAR", avatar);
              _this.$store.commit("SET_ID", id);
              _this.$store.commit("SET_ROLEID", roleId);


              //console.log(sessionStorage.getItem("username"))

              //获取头像和姓名
              _this.user.name = userName;
              _this.user.avatar = avatar;

              //设置为登陆状
              _this.hasLogin = true;

              //登陆成功提示
              _this.$message({
                message: "登陆成功！",
                type: "success",
              });
              //跳转登陆页面
              // _this.$router.push("/blogs")
              //关闭登陆框
              _this.reload();
              _this.$refs[form].resetFields();
              _this.dialogFormVisible2 = false;
            });
        } else {
          return false;
        }
      });
    },
    newUser(formName) {
      // alert("注册")
      // this.dialogFormVisible = true
        //用户名正则，4到16位（字母，数字，下划线，减号）
        var uPattern = /^[a-zA-Z0-9]{3,15}$/;
        //输出 true
        if (uPattern.test(formName.userName) == false){
            false;
        }

      if (this.ruleForm.email != "") {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            const _this = this;
            this.$axios
              .post("/account/signIn", this.ruleForm)
              .then(function(response) {
                _this.dialogFormVisible = false;
                _this.dialogFormVisible2 = true;

                //提示注册成功
                _this.$alert("注册成功！", "Arien博客提示您", {
                  confirmButtonText: "确定",
                  callback: (action) => {
                    this.$message({
                      type: "info",
                      message: `action: ${action}`,
                    });
                  },
                });
              });
          } else {
            return false;
          }
        });
      } else {
        this.$message.error("请检查内容是否正确哦！");
      }
    },
    gotoMenu() {
      this.$router.replace("/background")
    },
    search(info){
      this.$router.replace(info)
    },
    mylogout() {
      const _this = this;
      _this.$axios
              .get("/account/logout/" + localStorage.getItem("id"), {
                headers: {
                  Authorization: localStorage.getItem("token"),
                },
              })
              .then((res) => {
                _this.$store.commit("REMOVE_INFO");
                //刷新页面
                //window.location.reload()
                _this.hasLogin = false;
                //_this.$router.push("/login")
                //退出登陆成功提示
                _this.$message({
                  showClose: true,
                  message: "退出成功！",
                  type: "success",
                });
                _this.reload();
              });
    },
    /*获取用户请求数量*/
    getUserRequest(){
      const _this = this;
      _this.$axios.get("account_request/count_user_request").then((res) => {
        _this.userRequest = res.data.data;
      });
    },
    userLogout() {
      const _this = this;
      _this.$axios
        .post("/account/userLogout", {id:localStorage.getItem("id")})
        .then((res) => {
          _this.$store.commit("REMOVE_INFO");
          //刷新页面
          //window.location.reload()
          _this.hasLogin = false;
          //_this.$router.push("/login")
          //退出登陆成功提示
          _this.$message({
            showClose: true,
            message: "退出成功！",
            type: "success",
          });
          _this.reload();
        });
    },
    handleSelect(key, keyPath) {
    },
    searchAll() {
      this.$router.push({path:"/search"})
      /*this.$message({
        showClose: true,
        message: "此功能测试中，暂时未开放！",
        type: "warning",
      });*/
      /*alert(this.searchInfo)*/
      this.reload()
    },
    //作品
    author() {
      this.$message({
        showClose: true,
        message: "你没有足够权限！",
        type: "warning",
      });
    },
  },
  created() {
    this.userAvatar = localStorage.getItem("avatar");
    if (
      localStorage.getItem("userName") != null &&
      localStorage.getItem("userName") != ""
    ) {
      //获取头像和姓名
      this.user.name = localStorage.getItem("userName");
      if (localStorage.getItem("avatar") != "") {
        this.user.avatar = localStorage.getItem("avatar");
      }
      this.hasLogin = true;
    }
    if (localStorage.getItem("roleId") == 1 || localStorage.getItem("roleId") == 2){
      this.getUserRequest()
    }
    this.keyupSubmit()

  },
};
</script>

<style scoped>

  /*去掉返回的轮廓*/
  /deep/{
    outline: none;
  }

  >>>.el-menu {
    border-right: solid 1px #e6e6e6;
    list-style: none;
    position: relative;
    margin: 0;
    padding-left: 0;
    background-color: #FFF;
    opacity: 0.78;
  }


  .el-menu-item:focus, .el-menu-item:hover{
    background-color: white;
  }

  >>>.el-menu-item.is-active {
    background-color: rgb(246, 245, 233) !important;
  }

  >>>.el-menu-item {
      font-size: 14px;
      /*color: #746161;*/
    color: #5f5151;
      font-family: 华光综艺_CNKI;
      padding: 0 20px;
      /*cursor: pointer;
      transition: border-color .3s,background-color .3s,color .3s;*/
      box-sizing: border-box;
      /*cursor: none;*/
  }

  >>>.el-submenu__icon-arrow {
    position: absolute;
    top: 60%;
    right: 8px;
    margin-top: -7px;
    transition: transform .3s;
    font-size: 1px;
  }

  >>>.el-submenu__title {
      font-size: 14px;
      font-family: 华光综艺_CNKI;
      color: #01102f;
      padding: 0 0px;
      cursor: pointer;
      transition: border-color .3s,background-color .3s,color .3s;
      box-sizing: border-box;
  }

  >>>.el-button--success {
    color: #FFF;
    background-color: #83ddb8;
    border-color: white;
  }

  >>>.el-button--success:focus, .el-button--success:hover {
    background: #52cab6;
    border-color: white;
    color: #FFF;
  }

  >>>.el-button--primary {
    color: #FFF;
    background-color: #7370cf;
    border-color: white;
  }


 /* .demo-ruleForm{
    width: 100%;
    margin: 0px -20px 0px 0px;
  }*/

  /*登录模态框*/
  .loginStatu{
    width: 50%;
    margin: auto;
  }
  .statu{
    width: 65%;
    margin: auto;
  }
  .mobileMenu{
      top: 50px;
      width: 15%;
      height: 30px;
    }
  .showWidth{
    margin-top: 4px;
      grid-row:1;
  }
  .showWidth02{
      grid-row:2;
  }
  .col_2{
      display:flex;
  }
  .myheader {
    min-width: 100%;
    position: fixed;
    top: -10px;
    left: -8px;
    right: -10px;
    margin-top: 5px;
    margin-bottom: 50px;
    color: #ffffff;
    height: 60px;
    z-index: 999;
  }
  .showWidth:hover{
    background-color:#fff;
  }
  /*>>>.el-icon-arrow-down:before {
    content: "";
  }*/
  >>>.el-submenu__title {
    font-size: 14px;
    color: #303133;
    padding: 4px 20px;
    cursor: pointer;
    color: #6c27ce;
    transition: border-color .3s,background-color .3s,color .3s;
    box-sizing: border-box;
    font-family: 华光综艺_CNKI;
  }
  >>>.el-row {
    box-sizing: border-box;
  }
  >>> .el-submenu__title:hover{
    background-color:#fff;
  }
  >>>.el-row--flex {
    display: flex;
    z-index: 999;
  }
  .logo {
    width: 155px;
    top: -13px;
    margin-left: -15px;
  }
  .header_login {
    width: 275px;
    height: 15px;
  }
  .header_login_right {
    float: right;
    width: 220px;
    height: 52px;
  }
  .loginInfo {
    background-color: white;
    width: 200px;
  }

  .loginInfo2 {
    /*opacity: 0;*/
    width: 150px;
    margin-right: 10px;
  }

  .block {
    float: right;
    min-width: 40px;
    max-width: 100%;
  }
  .myname {
    /*float: right;
    margin-top: -5px;
    margin-right: 8px;
    font-family: 幼圆;
    font-size: 12px;
    font-weight: bold;
    width: 40px;
    height: 50px;
    color: #ffffff;*/
    /*display: none;*/
  }
  .mylogout {
    float: right;
    margin-right: 16px;
  }
  .el-avatar {
    display: inline-block;
    box-sizing: border-box;
    background: #a7c3ff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
  img{
    -webkit-user-drag: none;
  }
  .myvalid {
    width: 220px;
  }
  .button_search {
    width: 60px;
    height: 30px;
    margin: 12px 10px 10px 10px;
  }
  a {
    text-decoration: none;
  }

  >>> .el-badge__content.is-fixed {
    position: absolute;
    top: 13px;
    right: 10px;
    transform: translateY(-50%) translateX(100%);
  }

  >>>.el-menu-item [class^=el-icon-] {
    margin-right: 5px;
    width: 25px;
    text-align: center;
    font-size: 25px;
    vertical-align: middle;
  }

  >>>.el-button--warning.is-plain {
    color: #ff9900;
    background: #ffffff;
    border-color: #f5dab1;
  }

  /*>>>.el-dropdown {
    display: inline-block;
    position: relative;
    color: #606266;
    font-size: 14px;
    !*display: none;*!
  }*/

  >>>.el-button+.el-button {
    margin-left: 10px;
  }
  @media screen and (max-width: 867px) {
    .myheader {
      min-width: 100%;
      position: fixed;
      top: -10px;
      left: -8px;
      right: -10px;
      margin-top: 5px;
      margin-bottom: 50px;
      color: #ffffff;
      height: 60px;
      z-index: 999;
    }

    >>>.el-dropdown {
      display: inline-block;
      position: relative;
      color: #606266;
      font-size: 14px;
      /*display: block !important;*/
    }

      /deep/ .el-menu-item {
          font-size: 14px;
          color: #ffffff;
          padding: 0 20px;
          /*cursor: pointer;
          transition: border-color .3s,background-color .3s,color .3s;*/
          box-sizing: border-box;
          cursor: none;
      }

      .global{
          width: 100%;
      }
      .col_2{
          display: none;
      }
      .col_3{
          margin-left: 38px;
          background-color: white;
      }
      .logo{
          height:40px;
          width:100px;
      }
    .loginStatu{
      width: 180%;
      margin-left: -150px;
    }

    .loginInfo {
      background-color: white;
      width: 245px;
    }
    .loginInfo2 {
      /*opacity: 0;*/
      width: 210px;
      margin-right: 10px;
    }
    .statu{
      width: 190%;
      margin-left: -170px;
    }
    .block {
      float: right;
      min-width: 30px;
      max-width: 100%;
    }
    .myname {
      float: right;
      margin-top: -1px;
      margin-right: 6px;
      font-family: 幼圆;
      font-size: 12px;
      font-weight: bold;
      width: 30px;
      height: 50px;
      color: #ffffff;
      display: block !important;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait){
    .block {
      float: right;
      min-width: 100px;
      max-width: 100%;
      margin-right: -370px;
    }

    .loginInfo {
      background-color: white;
      width: 180px;
      margin-right: 10px;
    }
  }

  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape){
    /* style */
    .header_login {
      display: none;
    }
  }

</style>
