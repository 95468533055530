<template>
    <div>
        <el-scrollbar>
            <el-button size="mini" type="success" @click="add()">添加友链</el-button>
            <el-table
                    show-header
                    ref="multipleTable"
                    tooltip-effect="dark"
                    style="width: 100%"
                    @selection-change="handleSelectionChange"
                    :data="tableData"
                    :key="Math.random()">
                <el-table-column prop="image" label="用户名称" width="140">
                </el-table-column>
                <el-table-column prop="avatar" label="用户头像" width="120">
                </el-table-column>
                <el-table-column prop="link" label="用户链接" width="120">
                </el-table-column>
                <el-table-column prop="detail" label="用户描述" width="180">
                </el-table-column>
                <el-table-column prop="email" label="用户邮箱" width="180">
                </el-table-column>
                <el-table-column prop="id" label="删除" width="80">
                    <template slot-scope="scope">
                        <el-popover
                                placement="top"
                                width="160"
                                v-model="visible">
                            <p>你确定删除当前请求信息吗？</p>
                            <div style="text-align: right; margin: 0">
                                <el-button size="mini" type="success" @click="del(scope.row.id)">确认</el-button>
                            </div>
                            <el-button slot="reference" size="mini" type="danger">删除</el-button>
                        </el-popover>
                        &nbsp;&nbsp;
                    </template>
                </el-table-column>
                <el-table-column prop="authId" label="更新" width="80">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" @click="update(scope.row)">修改</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-scrollbar>

        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>

        <div class="search">
            <el-dialog title="更新" :visible.sync="dialogFormVisible" width="30%">
                <el-form :model="form" :rules="rules">
                    <el-form-item label="用户名" :label-width="formLabelWidth" prop="permission" >
                        <el-input v-model="form.image" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户头像" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.avatar" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户链接" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.link" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户描述" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.detail" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="用户邮箱" :label-width="formLabelWidth" prop="permissionSign">
                        <el-input v-model="form.email" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="submitForm('form')">确认更新</el-button>
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                </div>
            </el-dialog>

        </div>
    </div>
</template>

<script>
    export default {
        inject: ["reload"],
        name: "UserRequest",
        data(){
            return{
                dialogFormVisible: false,
                tableData:{
                    requestTate: 0,
                },
                form: {
                },
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total: ''
                },
                languageList:[
                    {
                        value:'未处理',
                        key:0
                    },
                    {
                        value:'已处理',
                        key:1
                    }
                ],
            }
        },
        methods:{
            query(){
                const _that = this;
                this.$axios.post('friend/list', _that.pageInfo).then(function (response) {
                    console.log(response.data.data.records)
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                    if (_that.pageInfo.total <= 5){
                        _that.value = true;
                    }
                })
            },
            update(value) {
                console.log(value)
                this.form = value
                this.dialogFormVisible = true;
            },
            /*删除单个数据*/
            del(value){
                const _that = this;
                this.$axios.post('/friend/delete', {id:value}).then(function (response) {
                    _that.reload();
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                })
            },
            add(){
                this.dialogFormVisible = true;
            },
            submitForm(value){
                const _that = this;
                this.$axios.post('/friend/update', _that.form).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.dialogFormVisible = false;
                    _that.reload();
                })
            },
        },
        created() {
            this.query()
        }
    }
</script>

<style scoped>

</style>