<template>
    <div>
    <el-table :data="tableData"
    >
        <el-table-column prop="userName" label="姓名" width="140">
        </el-table-column>
        <el-table-column prop="roleId" label="用户角色" width="180">
            <template slot-scope="scope">
            <el-select v-model="scope.row.roleId" placeholder="用户角色" clearable>
                <el-option
                        v-for="l in languageList"
                        :key="l.key"
                        :label="l.value"
                        :value="l.key">
                </el-option>
            </el-select>
            </template>
        </el-table-column>
        <el-table-column prop="roleId" label="更新">
            <template slot-scope="scope">
            <el-button size="mini" type="success" @click="submitForm(scope.row)">更新角色</el-button>
            </template>
        </el-table-column>
    </el-table>

        <!--分页-->
        <el-pagination
                :hide-on-single-page="value"
                background
                pager-count="7"
                layout="prev, pager, next"
                :current-page.sync="pageInfo.currentPage"
                :page-size="pageInfo.showPage"
                :total="pageInfo.total"
                @current-change="query"
        >
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "UserAuth",
        data(){
            return{
                tableData:{
                    id:'',
                    userName:'',
                    image:'',
                    password:'',
                    email:'',
                    loginState: '',
                    state: '',
                    loginTime: '',
                    createdTime: '',
                    updateUser: '',
                    updateTime: '',
                    roleId:0,
                },
                languageList:[
                    {
                        value:'超级管理员',
                        key:1
                    },
                    {
                        value:'管理员',
                        key:2
                    },
                    {
                        value:'普通用户',
                        key:3
                    },
                    {
                        value:'游客',
                        key:4
                    }
                ],
                pageInfo:{
                    currentPage: 1,
                    showPage: 10,
                    total:''
                },
                form: {
                    id:'',
                    userName:'',
                    email:'',
                    loginState:'',
                    loginStateCopy:'',
                    state:'',
                    stateCopy:'',
                    password:''
                }
            }
        },
        methods: {
            submitForm(value){
                const _that = this;
                this.$axios.post('/account/updateUser', {id:value.id,roleId:value.roleId}).then(function (response) {
                    _that.$message({
                        showClose: true,
                        message: response.data.data,
                        type: "success",
                    });
                    _that.dialogFormVisible = false;
                    _that.reload();
                })
            },
            query(){
                const _that = this;
                this.$axios.post('/account/queryAllInfo', _that.pageInfo).then(function (response) {
                    _that.tableData = response.data.data.records;
                    _that.pageInfo.total = response.data.data.total;
                })
            }
        },
        created() {
            this.query();
        }
    }
</script>

<style scoped>

</style>