<template>
  <div>
    <div id="footer">
      <!--<el-divider content-position="right"></el-divider>-->
      <div class="footer">
        <div class="footer_top">
          <div class="footer_top_left">
            <img src="" alt="" />
            <!--个人技术博客基于阿里云服务器搭建，采用SpringBoot和Vue技术栈，中间通过Nginx反向代理实现彻底的前后端分离，算是本人阶段性学习的总结。建立博客的原因很简单，
            是为了能够清晰的记录下学习过程，激励自己坚持下去，同时可以将一些新技术应用于自己的网站上，
            并且也更方便和大家学习交流。-->
<!--            <p>-->
<!--              Personal technical blog is built based on Ali cloud server, using SpringBoot and Vue technology stack, in the middle through Nginx reverse proxy to achieve a thorough separation of the front and back end, which is a summary of my phased learning.The reason for establishing a blog is very simple, in order to clearly record the learning process, motivate myself to persist, at the same time, I can apply some new technology to my website, and it is more convenient for me to learn and communicate with others.-->
<!--            </p>-->
            <!--<P>
              Personal technology blog is built based on Alibaba cloud server,
              using springboot and Vue technology stack, and using nginx reverse proxy to realize the complete separation of front and back end. It is the summary of my stage learning. The reason for building a blog is very simple, in order to be able to clearly record the learning process, encourage themselves to stick to it, at the same time, we can apply some new technologies to our website,
              and it is more convenient to learn and communicate with you.
            </P>-->
            <p>Welcome to Arien's world!</p>
          </div>
          <div class="footer_top_center">
            <div>
              <h6>ArienTOP</h6>
              <ul>
                <li><a href="">遵守规范发言</a></li>
                <li><a href="">本站非营利性</a></li>
                <li><a href="">禁止发送广告</a></li>
              </ul>
            </div>
            <div>

            </div>
          </div>
          <div class="footer_top_right">
            <div>
            <img src="https://www.arien.top/image/二维码03.jpg" alt="" />
            </div>
            <p>联系博主</p>
          </div>
        </div>
        <div class="footer_buttom">
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备20059328号</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer",
};
</script>

<style scoped>


body {
  text-align: center;
}
#footer {
  width: 100%;
  height: auto;
  /*background: #ffffff;*/
  margin-top: 80px;
  float: left;
  background-image: linear-gradient(to right, #5087c2, #bd9afd);
}
.footer {
  border-top: #dadada 1px solid;
  margin: 0 auto;
  height: 305px;
}
.footer_top {
  height: 275px;
}
.footer_top_left {
  width:  33%;
  min-width: 350px;
  height: 275px;
  margin-left: 50px;
  float: left;
}
/*.footer_top_left > img {
  width: 130px;
  position: absolute;
  top: -35px;
  left: calc(50% - 130px / 2);
}*/
.footer_top_left > p {
  margin-top: 40px;
  padding-top: 10px;
  font-size: 14px;
  text-indent: 2em;
  /*color: #8492a6;*/
  color: #fcfcfc;
  font-weight: bold;
}
.footer_top_center {
  width: 33%;
  height: 275px;
  float: left;
}
.footer_top_center > div {
  width: 200px;
  /*float: right;
  margin-right: 60px;
  margin-top: 5px;*/
  margin: 10px auto;
  text-align: center;
}
.footer_top_center > div > h6 {
  font-size: 18px;
  padding-bottom: 18px;
  border-bottom: 2px solid rgb(105, 63, 220);
  margin-bottom: 15px;
}
ul,li{ padding:0;margin:0;list-style:none}
.footer_top_center > div > ul > li {
  margin-left: -10px;
  padding: 10px 5px;
  width: 100%;
}
.footer_top_center > div > ul > li > a {
  border-radius: 3px;
  padding: 3px;
  text-decoration: none;
  color: white;
  background-image: linear-gradient(to right, #9671de, #5485e0);
}
.footer_top_center > div > ul > li:hover > a {
  background-image: linear-gradient(to right, #b6558f, #7b67c9);
  text-decoration: none;
}
.footer_top_right {
  width: 30%;
  right: 0%;
  min-width: 275px;
  height: 275px;
  float: right;
  position: absolute;
}
.footer_top_right > div > img {
  height: 100%;
  width: 100%;
}
.footer_top_right > div {
  color: #ffffff;
  height: 160px;
  width: 160px;
  margin: 45px auto 10px;
}

.footer_top_right > p {
  color: #f3f3f6;
  text-align: center;
  margin-top: 0px;
}

.footer_finally {
  width: 500px;
  height: 28px;
  background-color: #42b983;
}
.footer_buttom {
  float: right;
  margin: 0 auto;
  width: 100%;
  height: 28px;
  text-align: center;
  line-height: 28px;
  color: #fafbfc;
  font-weight: bold;
}

.footer_buttom > a {
  border-radius: 3px;
  color: #ffffff;
  background-image: linear-gradient(to right, #454faa, #37958d);
}
@media screen and (max-width:867px) {

  .footer_top_left > p {
    margin-top: 0px;
    padding-top: 0px;
    font-size: 14px;
    text-indent: 2em;
    /*color: #8492a6;*/
    color: #fcfcfc;
    font-weight: bold;
  }

  .footer{
      width: 100%;
      height: auto;
      border-top: #8492a6 0px solid;
  }
  .footer_top_left {
    width: 100%;
    margin-left: 0px;
  }
  .footer_top_center {
    margin: 0 auto;
    width: 100%;
    height: 275px;
    float: left;
  }

  .footer_top_center > div {
    width: 200px;
    margin: 0px auto;
    text-align: center;
  }

  .footer_top_right {
    width: 100%;
    height: 275px;
    float: right;
    margin-top: 10px;
    position: revert;
  }
  .footer_top_right > p {
    width: 100%;
    color: #ffffff;
    /*margin-left: 48px;*/
    text-align: center;
  }
  .footer_buttom {
    margin: 0 auto;
    width: 100%;
    height: 28px;
    text-align: center;
    line-height: 38px;
    color: #e8ecf1;
    font-weight: bold;
    float: bottom;
  }

  .footer_buttom > a {
    border-radius: 3px;
    background-image: linear-gradient(to right, #339cb6, #9d40dc);
  }


}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape){
  .footer_top_right {
    width: 225px;
    height: 275px;
    float: right;
  }
}
</style>
