<template>
<div>
    <Header></Header>
    <div class="allWrapper">
        <div class="topMsg">
            <!--存放模块信息-->
            <div class="message" v-for="item in allData" >
                <a @click="getModule(item.id)" class="avatar">
                    <div class="myInfo">{{item.moduleName}}</div>
                    <img :src=item.moduleImage ></a>
            </div>
        </div>

        <div class="content">
            <component :is="currentComponent">
            </component>
        </div>
    </div>
<!--  <Footer></Footer>-->
</div>

</template>

<script>
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    import ModuleInfo from "../components/ModuleInfo";
    export default {
        components: {
            Footer,
            Header,
            moduleInfo: ModuleInfo
        },
        data() {
            return{
                allData:[],
                currentComponent: ''
            }

        },
        methods:{
            getAll(){
                const _this = this;
                this.$axios.get('/module/allInfo').then(function (res) {
                    _this.allData = res.data.data
                    window.scrollTo(0,0)
                })
            },
            getModule(id){
                /*this.currentComponent = 'moduleInfo'*/
                localStorage.setItem("moduleId", id);

                this.$router.push({path:"/moduleInfo"})
            }

        },
        created() {
            this.getAll();
        }
    }
</script>

<style scoped>

    .allWrapper{
        min-height: 1000px;
        width: auto;
        background: #fdfdfd;
        border-radius: 15px;
        overflow: hidden;
        float: revert;
        /*background-image: linear-gradient(to bottom right, #a8ffcb, #5486ee, #e32f79, #e33e2f, #c250fd);*/
    }

    .topMsg{
        margin: 100px auto 0px;
        height: auto;
        max-width: 80%;
        border-radius: 20px;
        /*min-width: 90%;*/
       /* float: left;*/
        background: #fffafa;
        padding: 20px;
        box-sizing:border-box;
        /*background-image: linear-gradient(to bottom right, #a8ffcb, #5486ee, #e32f79, #e33e2f, #c250fd);*/
        background-image: linear-gradient(to right, #9aedfc, #fc9ad0, #9c92f8);
    }

    /*.topMsg:before {
        topMsg:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        -webkit-filter:blur(10px);
        filter:blur(10px);
    }*/

    .message{
        height: 350px;
        width: 80%;
        max-width: 1200px;
        /*float: left;*/
        margin: 50px auto;
        border-radius: 15px;
        overflow: hidden;
        position: relative;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        z-index: 100;
    }

    .myInfo {
        position: absolute;
        width: 100%;
        margin-top: 90px;
        text-align: center;
        font-size: 100px;
        font-weight: bold;
        color: white;
    }

/*
    .message>img{
        height: 100%;
        width: 100%;
    }*/

    .avatar {
        border-radius: 15px !important;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        /*box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);*/
        overflow: hidden;
        transform: scale(1);
        transition: all 0.5s ease 0s;
        display: inline-block;
    }

    .avatar:hover {
        transform: scale(1.3);
        transition: all 1s ease 0s;
    }

    .avatar img {
        border-radius: 15px !important;
        /*display: block;*/
        border: 0;
        width: 100%;
        height: 100%;
       /* transform: scale(1);
        transition: all 1s ease 0s;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
        -webkit-transform: scale(1);
        -webkit-transform: all 1s ease 0s;
        -webkit-user-drag: none;*/
    }

    /*.avatar:hover img {
        border-radius: 15px !important;
        transform: scale(1.3);
        transition: all 1s ease 0s;
        -webkit-transform: scale(1.3);
        !*-webkit-transform: all 1s ease 0s;*!
        -webkit-transition: all 1s ease 0s;
    }*/
    .content{
        float: left;
        width: 100%;
        height: auto;
        background-color: #336699;
    }

    @media screen and (max-width:867px) {
        .message{
            height: 200px;
            width: 95%;
            /*float: left;*/
            margin: 50px auto;
            border-radius: 15px;
            overflow: hidden !important;
        }

        /*.avatar {
            display: block;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            border-radius: 15px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
            overflow: hidden !important;
        }*/
        .topMsg{
            margin: 100px auto 0px;
            height: auto;
            max-width: 100%;
            border-radius: 20px;
            padding: 20px;
            box-sizing:border-box;
            background-image: linear-gradient(to bottom right, #a8ffcb, #5486ee, #e32f79, #e33e2f, #c250fd);

        }

        .myInfo {
            position: absolute;
            width: 100%;
            margin-top: 20%;
            text-align: center;
            font-size: 50px;
            font-weight: bold;
        }

    }

</style>