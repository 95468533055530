import axios from 'axios'
const ui = require('element-ui');

const instance = axios.create({
    headers: {
        'content-type': 'application/json;charset=UTF-8',
        'Account': 'Arien',
        'Authorization': '',
        'Access-Control-Allow-Origin':'*'
    },
    baseURL: 'https://arien.top/graduation',
    // baseURL: 'http://localhost:8787/graduation',
    timeout: 20000,
    changeOrigin:true, //允许跨域
    withCredentials: true
})

// 添加请求拦截器
instance.interceptors.request.use(config => {

    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    if (localStorage.getItem("token") != null && localStorage.getItem("token") != '') {
        config.headers.Authorization = localStorage.getItem("token");
    }
    if (localStorage.getItem("userName") != null && localStorage.getItem("userName") != ''){
        config.headers.Account = localStorage.getItem("userName");
    }
    return config;
}, error => {
    // 请求错误时做些事
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(response => {
    const code = response.data.code;
    // 对响应数据做些事
    if (response.data && response.data.code === 200) {
            // 在请求成功后可以对返回的数据进行处理，再返回到前台
            return response;
        } else if (code == 400) {
        ui.Message({
            message: response.data.msg,
            type: 'warning'
        })
        return Promise.reject(error);
    }/*else if (code == 401 || code == 405){
        ui.Message({
            message: "访问受限，您没有足够权限！",
            type: 'warning'
        })
    }*/
    return response;
}, error => {
    // 对响应错误做点什么
    if (error.response.status === 401 || error.response.status === 405) {

        ui.Message({
            message: "访问受限，您没有足够权限！",
            type: 'warning'
        })
    }

    if (error.response.status === 500){
        ui.Message({
            message: "登录已经失效，请重新登录！",
            type: 'warning'
        })

        localStorage.setItem("token", '')
        this.$store.commit("REMOVE_INFO")
        window.location.reload()
    }

    return Promise.reject(error);

    //return Promise.reject(error.response.data); // 返回接口返回的错误信息
})

export default instance;