import Vue from 'vue'
import VueRouter from 'vue-router'
import Background from '../views/Background.vue'
import Index from "../views/Index";
import AccountInfo from "../components/AccountInfo.vue";
import PushEmail from "../components/PushEmail";
import Authority from "../components/Authority";
import UserAuth from "../components/UserAuth";
import BlogEdition from "../views/BlogEdit.vue"
import BlogDetail from "../views/BlogDetail";
import BlogInfo from "../components/BlogInfo";
import MyLeave from "../views/MyLeave";
import Search from "../views/Search";
import Module from "../views/Module";
import ModuleInfo from "../components/ModuleInfo";
import Picture from "../components/Picture";
import Reply from "../components/Reply";
import UserRequest from "../components/UserRequest"
import FeedBack from "../components/Feedback"
import UserInfo from "../views/UserInfo"
import FriendLink from "../views/FriendLink"
import Friend from "../components/Friend";
import Pigeonhole from "../views/Pigeonhole";
import NewMyLeave from "../views/NewMyLeave";
import LeaveInfo from "../components/LeaveInfo";
import AILeave from "@/views/AILeave.vue";
import header02 from "@/components/Header02.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    meta: {
      title: 'Arien的博客'
    },
    component: Index
  },
  {
    path: '/pushEmil',
    name: 'pushEmail',
    component: PushEmail
  },
  {
    path: '/background',
    name: 'background',
    meta: {
      title: '后台管理'
    },
    component: Background,
  },
  {
    path: '/accountInfo',
    name: 'accountInfo',
    component: AccountInfo,
  },
  {
    path: '/authority',
    name: 'authority',
    component: Authority,
  },
  {
    path: '/userAuth',
    name: 'userAuth',
    component: UserAuth,
  },
  {
    path: '/blogEdit',
    name: 'blogEdit',
    meta: {
      title: '编辑文章-Arien的博客'
    },
    component: BlogEdition,
  },
  {
    path: '/blogDetail',
    name: 'blogDetail',
    component: BlogDetail,
  },
  {
    path: '/blogInfo',
    name: 'blogInfo',
    component: BlogInfo,
  },
  {
    path: '/myLeave',
    name: 'myLeave',
    meta: {
      title: '留言-Arien的博客'
    },
    component: MyLeave,
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '搜索-Arien的博客'
    },
    component: Search,
  },
  {
    path: '/module',
    name: 'module',
    meta: {
      title: '模块-Arien的博客'
    },
    component: Module,
  },
  {
    path: '/moduleInfo',
    name: 'moduleInfo',
    meta: {
      title: '模块信息-Arien的博客'
    },
    component: ModuleInfo,
  },
  {
    path: '/pictureInfo',
    name: 'pictureInfo',
    component: Picture,
  },
  {
    path: '/reply',
    name: 'reply',
    component: Reply,
  },
  {
    path: '/userRequest',
    name: 'userRequest',
    component: UserRequest,
  },
  {
    path: '/feedBack',
    name: 'feedBack',
    component: FeedBack,
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: UserInfo,
  },
  {
    path: '/friendLink',
    name: 'friendLink',
    meta: {
      title: '友链-Arien的博客'
    },
    component: FriendLink,
  },
  {
    path: '/friend',
    name: 'friend',
    meta: {
      title: '友链添加-Arien的博客'
    },
    component: Friend,
  },
  {
    path: '/pigeonhole',
    name: 'pigeonhole',
    meta: {
      title: '归档-Arien的博客'
    },
    component: Pigeonhole,
  },
  {
    path: '/newLeave',
    name: 'newLeave',
    meta: {
      title: '新留言-Arien的博客'
    },
    component: NewMyLeave,
  },
  {
    path: '/leaveInfo',
    name: 'leaveInfo',
    component: LeaveInfo,
  },
  {
    path: '/CaydenAI',
    name: 'AILeave',
    component: AILeave,
  },
  {
    path: '/CaydenAI',
    name: 'Header02',
    component: header02,
  }
]



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
