<template>
    <div>

            <el-tree
                    :data="data"
                    show-checkbox
                    default-expand-all
                    node-key="id"
                    ref="tree"
                    highlight-current
                    :props="defaultProps"
            >
            </el-tree>

    <div class="treeType">
    <div class="buttons">
        <el-button type="primary" @click="getCheckedKeys">更新权限</el-button>
        <el-button type="danger" @click="resetChecked">清空</el-button>
    </div>
    </div>
    </div>
</template>
<script>
    export default {
        methods: {
            /*更新角色的权限信息*/
            getCheckedKeys() {
                const _that = this
                _that.allNode = _that.$refs.tree.getCheckedKeys().toString()
                this.$axios.post("/authority/getInfo", {allNode:_that.allNode}).then(function (response) {
                    _that.$refs.tree.setCheckedKeys(response.data.data);
                    _that.$message({
                        showClose: true,
                        message: '权限更新成功！',
                        type: "success",
                    });
                    _that.reload();
                })

            },
            setCheckedKeys() {
                /*this.$refs.tree.setCheckedKeys(["1-001","2-002"]);*/
            },
            resetChecked() {
                this.$refs.tree.setCheckedKeys([]);
            },
            getAllNode(){
                const _that = this;
                this.$axios.post("/authority/getNode", {}).then(function (response) {
                    _that.data = response.data.data;
                })
            },
            /*填充权限信息*/
            getFillInfo(){
                const _that = this
                this.$axios.post("/authority/fillInfo").then(function (response) {
                    _that.$refs.tree.setCheckedKeys(response.data.data);
                })
            }
        },

        data() {
            return {
                data: [],
                allNode:'',
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            };
        },
        inject: ['reload'], //注入
        created() {
            this.getAllNode()
            this.getFillInfo()
        }
    };
</script>

<style>
    .treeType{
        margin-top: 100px;
    }
</style>