<template>
    <div>
        <Header></Header>
        <div class="format"></div>
        <div class="myleave" >
            <div class="primaryleave" v-for="item in leaves">
                <div class="leave_left" v-for="u in users" v-if="u.id == item.userId" @click="detail(u.id)">
                    <div class="mylogo"><el-avatar :size="50" :src="u.image"></el-avatar></div>
                    <div class="leave_name">{{u.userName}}</div>
                </div>
                <div class="leave_right">
                    <div class="leave_message" v-html="item.leaveMsg"></div>
                    <div class="leave_tiem">{{item.leaveTime}}</div>
                    <!--<div class="replaybutton">
                        <el-button type="success" icon="el-icon-chat-dot-round" size="mini" @click="replyLid(item.lid)">回复</el-button>
                    </div>-->

                    <!--回复模态框-->
                    <!-- <el-dialog class="statu"  title="回复" :visible.sync="dialogFormVisible" width="400px">
                         <el-form :model="form" >
                             <el-form-item  label="内容:" :label-width="formLabelWidth">
                                 <el-input type="textarea" :rows="2" v-model="form.desc"></el-input>
                             </el-form-item>
                             <el-form-item  label="当前身份:" :label-width="formLabelWidth">
                                 <el-input placeholder="" v-model="author" :disabled="true">
                                 </el-input>
                             </el-form-item>
                             <el-form-item label="怎么称呼您:" :label-width="formLabelWidth" v-show="!exsitUser">
                                 <el-input v-model="form.userName" autocomplete="off"></el-input>
                             </el-form-item>
                         </el-form>
                         <div slot="footer" class="dialog-footer">
                             <el-button @click="dialogFormVisible = false">取 消</el-button>
                             <el-button type="primary" @click="submitForm('form')">确定</el-button>
                         </div>
                     </el-dialog>-->
                </div>

                <!--回复内容展示-->
                <!--<div class="replay" v-for="r in replys" v-if="item.lid == r.rid">
                    &lt;!&ndash;展示回复者的信息&ndash;&gt;
                    <div class="replyer" v-for="user in users" v-show="r.ruid == user.id">
                        <div class="replyer_logo"><el-avatar :size="30" :src="user.image"></el-avatar></div>
                        <div class="replyer_name">{{user.userName}}&nbsp@</div>
                    </div>

                    &lt;!&ndash;展示被回答者的信息&ndash;&gt;
                    <div v-for="user in users">
                        <div class="be_replyer"  v-if="item.uid == user.id">
                            <div class="be_replyer_name">{{user.userName}}:</div>
                        </div>
                    </div>

                    <div class="reply_content">
                        {{r.rmessage}}
                    </div>

                    &lt;!&ndash;回复时间&ndash;&gt;
                    <div class="reply_time">
                        <i class="el-icon-watch"></i> {{r.rcreated}}
                    </div>
                </div>-->

            </div>

            <!--分页页脚-->
            <div class="mypage">
                <el-pagination
                        :hide-on-single-page="value"
                        class="mpage"
                        background
                        layout="prev, pager, next"
                        :current-page="currentPage"
                        :page-size="pageSize"
                        :total="total"
                        @current-change="page"
                >
                </el-pagination>
            </div>

            <!--主页面评论区-->
            <div class="primaryreply">
                <h2 align="center">留言</h2>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item v-show = "ruleForm.desc2 != ''" label="评论预览：" prop="desc">
                        <div style="width: 100%;height: auto;padding-top:10px;background-color: yellowgreen;border-radius: 10px;" v-html="ruleForm.desc2"></div>
                    </el-form-item>
                    <el-form-item label="发表评论" prop="desc">
                        <div v-show = "loginSuccess"><el-avatar :size="50" :src="avatar"></el-avatar>
                        </div>
                        <el-popover
                                placement="right"
                                trigger="click">
                            <div @click="pushEmoji()">
                                <Emoji ></Emoji>
                            </div>
                            <el-button size="mini" type="warning" slot="reference"><i class="el-icon-picture-outline-round"></i>表情</el-button>
                        </el-popover>
                        <el-input v-on:input = "changeInfo(ruleForm.desc)" placeholder="说点什么......（可支持css或html标签）" type="textarea" :rows="4" v-model="ruleForm.desc"></el-input>
                    </el-form-item>
                    <el-form-item  label="当前身份:" :label-width="formLabelWidth">
                        <el-input placeholder="" v-model="author" :disabled="true">
                        </el-input>
                    </el-form-item>
                    <el-form-item label="怎么称呼:" :label-width="formLabelWidth" v-show="!exsitUser" prop="userName">
                        <el-input v-model="ruleForm.userName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="邮箱:" :label-width="formLabelWidth" v-show="!exsitUser" prop="email">
                        <el-input v-model="ruleForm.email" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm02('ruleForm')"><i class="el-icon-s-promotion"></i>发表</el-button>
                        <el-button @click="resetForm02('ruleForm')"><i class="el-icon-refresh-left"></i>重置</el-button>
                    </el-form-item>
                </el-form>
            </div>

        </div>

    </div>
</template>

<script>
    import Header from "../components/Header";
    import Footer from "../components/Footer";
    import Emoji from "./Emoji";
    export default {
        inject:['reload'],//注入刷新页面代码块
        name: "Reply",
        components:{Emoji, Header,Footer},
        data() {
            return {
                value:false,
                lid:1,
                replys:{},
                users:{},
                //分页信息
                leaves:[],
                currentPage: 1,
                total: 1,
                pageSize: 5,
                rules: {
                    desc: [
                        {required: true, message: '请输入内容！', trigger: 'blur'},
                        {min: 3, max: 180, message: '长度在 3 到 180 个字符', trigger: 'blur'}
                    ],
                    userName: [
                        {required: true, message: '游客需要输入称呼！', trigger: 'blur'},
                        {min: 3, max: 15, message: '长度在 3 到 10 个字符', trigger: 'blur'}
                    ],
                    email: [
                        {required: true, message: '请输入邮箱地址', trigger: 'blur'},
                        {type:'email', message: '请输入正确的邮箱地址', trigger: 'blur'}
                    ],
                },
                ruleForm: {
                    userName: '',
                    desc: '',
                    email: '',
                    desc2: ''
                },
                exsitUser: false,
                author: "游客",
                dialogFormVisible: false,
                form: {
                    authorize: '',
                    desc: '',
                    userName: '',
                    email: ''
                },
                formLabelWidth: '120px'
            }
        },
        methods:{
            //存储回复的内容
            submitForm(form){
                //alert(this.form.desc)
                //alert(this.lid)
                const _this = this;
                this.$axios.post('/leave/saveReply',{uid:localStorage.getItem("id"),lid:this.lid,name:this.form.name,rmessage:this.form.desc}).then(function (res) {
                    //alert(_this.lid)
                    _this.$message({
                        showClose: true,
                        message: '回复成功！',
                        type: 'success'
                    })
                    //关闭模态框
                    _this.dialogFormVisible = false
                    //清空回复表单
                    _this.$refs[form].resetFields()
                    _this.reload();

                })
            },
            /*获取表情*/
            pushEmoji(){
                const emoji = localStorage.getItem("emoji");
                if (emoji != null || emoji != ''){
                    this.ruleForm.desc = this.ruleForm.desc + emoji
                    this.ruleForm.desc2 = this.ruleForm.desc
                    this.replaceEmoji(this.ruleForm.desc2)

                }

            },
            replaceEmoji(value) {
                this.ruleForm.desc2 = value.replaceAll("$001", "<img src=\"https://www.arien.top/image/emoji/01.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$002", "<img src=\"https://www.arien.top/image/emoji/02.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$003", "<img src=\"https://www.arien.top/image/emoji/03.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$004", "<img src=\"https://www.arien.top/image/emoji/04.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$005", "<img src=\"https://www.arien.top/image/emoji/05.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$006", "<img src=\"https://www.arien.top/image/emoji/06.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$007", "<img src=\"https://www.arien.top/image/emoji/07.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$008", "<img src=\"https://www.arien.top/image/emoji/08.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$009", "<img src=\"https://www.arien.top/image/emoji/09.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$010", "<img src=\"https://www.arien.top/image/emoji/10.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$011", "<img src=\"https://www.arien.top/image/emoji/11.png\" style = \"width:35px;height:35px\"/>")
                    .replaceAll("$012", "<img src=\"https://www.arien.top/image/emoji/12.png\" style = \"width:35px;height:35px\"/>")
            },
            changeInfo(value) {
                this.ruleForm.desc2 = value
                this.replaceEmoji(this.ruleForm.desc2)
            },
            //处理提交的留言
            submitForm02(formName) {
                const _this = this;
                this.$refs[formName].validate((valid) => {
                    if (valid || _this.exsitUser) {
                        this.$axios.post('/blog_leave/save_blog_leave',{
                            userId:localStorage.getItem("id"),
                            blogId:localStorage.getItem("BLOGID"),
                            userName:this.ruleForm.userName,
                            leaveMsg:this.ruleForm.desc2,
                            email:this.ruleForm.email
                        })
                            .then(function (res) {
                                //清楚内容
                                //_this.resetForm02("ruleForm");
                                _this.$message({
                                    message: '留言成功！',
                                    type: 'success'
                                })
                                _this.reload();
                            })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            //清空formName表单
            resetForm02(formName) {
                this.$refs[formName].resetFields();
                this.ruleForm.desc2 = ''
            },
            //获取主留言分页信息
            page(currentPage){
                const _this = this;
                this.$axios.post('/blog_leave/list_blog_leave', {currentPage:currentPage, id:localStorage.getItem("BLOGID")}).then(function (res) {
                    //console.log(res)
                    _this.leaves = res.data.data.records
                    _this.total = res.data.data.total
                    _this.pageSize = res.data.data.size
                    if (_this.total <= 6){
                        _this.value = true;
                    }
                })
            },
            //获取所有用户信息
            user(){
                const _this = this;
                this.$axios.get('/leave/getUser').then(function (res) {
                    //console.log(res)
                    _this.users = res.data.data
                })
            },
            //获取留言的lid
            replyLid(lid){
                this.dialogFormVisible = true
                //alert(lid)
                this.lid = lid
            },
            reply(){
                const _this = this;
                this.$axios.get('/leave/getReply').then(function (res) {
                    _this.replys = res.data.data
                })
            },
            detail(id){
                localStorage.setItem("leaveUserId", id)
                this.$router.push({path:"/userInfo"})
            },
        },
        created() {
            this.page(this.currentPage);
            this.user()
            /*this.reply()*/
            if(localStorage.getItem("userName") !=  null && localStorage.getItem("userName") != ''){
                this.author = "普通用户";
                this.exsitUser = true;
            }else {
                this.exsitUser = false;
            }

        }
    }
</script>

<style scoped>
    .myleave{
        min-height: 600px;
        width: 65%;
        margin: 0 auto;
    }
    .format{
        height: 10px;
    }
    >>>.el-form-item__content {
        margin-left: 120px !important;
    }
    .primaryleave{
        width: 100%;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin: 20px auto 30px;
        border-radius: 10px;
        /*margin-top: 100px;
        margin-bottom: 30px;*/
        height:auto;
        display:inline-block !important;
        display:inline;
        float: revert;
    }
    .leave_left{
        float: left;
        width: 20%;
        height: 120px;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .leave_right{
        width: 75%;
        font-family: 华光综艺_CNKI;
        background-image: linear-gradient(to right, #db7bea, #ea8979);
        min-height: 100px;
        border-radius: 6px;
        padding: 5px;
        float: right;
        font-weight: bold;
        box-shadow: 0 2px 12px 0 rgb(255, 255, 255);
        margin: 3px;
        height: auto;
        word-wrap: break-word;
        word-break: break-all;
        overflow: hidden;
    }

    .primaryreply{
        width: 80%;
        height: auto;
        border-radius: 10px;
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        padding: 10px 18px;
        margin: 50px auto;
    }
    .mypage{
        width: 100%;
        height: 60px;
        text-align: center;
        margin: 30px auto;
    }
    .mylogo{
        width: 50px;
        height: 50px;
        margin: 20px auto;
    }
    .leave_name{
        width: 100%;
        text-align: center;
        height: 20px;
        font-weight: bold;
        font-size: 18px;
        font-family: 方正综艺_GBK;
        color: #fa7c42;
        margin: 0 auto;
    }
    .leave_message{
        float: left;
        max-width: 100%;
        /*min-height: 80px;*/
        height: auto;
        padding: 10px;
        font-family: 方正综艺_GBK;
    }
    .leave_tiem{
        width: 100%;
        height: auto;
        color: #282f5f;
        margin: 2px 2px 0 0;
        float: right;
    }

    @media screen and (max-width:867px) {

        .myleave{
            min-height: 600px;
            width: 96%;
            margin: 0 auto;
        }

        .primaryleave{
            width: 100%;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 20px auto 30px;
            border-radius: 10px;
            /*margin-top: 100px;
            margin-bottom: 30px;*/
            height:auto;
            display:inline-block !important;
            display:inline;
            float: revert;
        }
        .leave_left{
            float: left;
            width: 100%;
            height: 120px;
            border-radius: 10px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }

        .leave_right{
            width: 96%;
            min-height: 80px;
            border-radius: 6px;
            float: left;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            margin: 10px 20px 20px 0px;
            height:auto;
            display:inline-block !important;
            display:inline;
        }

        .leave_message{
            float: left;
            width: 98%;
            min-height: 60px;
            height: auto;
            padding: 10px;
            font-family: 方正综艺_GBK;
        }
    }

</style>